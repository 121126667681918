<template>
  <div
    class="logo-simple"
    v-if="variant === 'simple-with-background'"
    :class="{ customWidth }"
  >
    <div class="with-background"></div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97.178"
      height="105.053"
      viewBox="0 0 97.178 105.053"
      class="z-10"
    >
      <g
        id="Group_1554"
        data-name="Group 1554"
        transform="translate(0.501 0.499)"
      >
        <path
          id="fill"
          data-name="Exclusion 1"
          d="M48.088,104.054a22.669,22.669,0,0,1-11.32-3.033L11.319,86.328A22.641,22.641,0,0,1,0,66.72V37.335a22.639,22.639,0,0,1,11.32-19.607L36.769,3.035a22.639,22.639,0,0,1,22.64,0L84.857,17.727a22.639,22.639,0,0,1,11.32,19.607V66.72a22.641,22.641,0,0,1-11.32,19.608L59.409,101.021A22.673,22.673,0,0,1,48.088,104.054ZM55.529,30.8h0l-1.039.208-5.71,1.039-2.285.623-4.983,2.492-5.193,4.568-2.907,4.673-.623,1.454-1.04,2.906-.416,2.493-.517,7.165.414,3.114.209,2.182.1,2.283,1.869,4.88,1.351,2.492,1.868,2.8,1.765,1.766,1.663.83.83.727,2.492,1.454,2.7,1.142,2.075.623H49.3l2.389.831,5.711.416,5.191-.208,4.882-.623,2.6-1.039,3.84-1.869,4.569-4.361,2.389-3.324,1.246-2.7.831-2.7v-.726l-.934-.624-4.362.1-4.983-.1-.832,2.492L70.067,71.2,68.093,72.65,64.562,74l-4.88.311-3.324-.935L54.49,72.234l-2.7-2.7-1.038-2.182L50.544,65.8l-.727-1.246L49.3,61.228l.312-.83.623-.209,4.05.416,29.075.208.933-.831-.1-.935V58.01l.1-5.4-1.246-6.335L81.9,43.785,80.345,41.5l-1.868-2.906-2.908-2.7-2.492-1.661L69.65,32.674l-4.361-1.454L60.512,30.6l-2.49.311-2.493-.1ZM74.739,53.96h0l-.312-.519.416-.519.623.209v.623l-.726.207ZM50.544,52.507h0l-.518-.831.415-2.492.519-4.567.623-1.247,1.039-1.35,2.908-1.765,1.764-.519h1.87l1.868.1,3.117,1.453.519,1.246L65.7,45.653l.416,5.712-.832.726-6.54-.1-7.166.207-1.038.312Z"
          transform="translate(0 0)"
          stroke="rgba(0,0,0,0)"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>
  </div>
  <div
    class="logo-simple"
    v-else-if="variant === 'simple'"
    :class="{ customWidth }"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="97.178"
      height="105.053"
      viewBox="0 0 97.178 105.053"
    >
      <g
        id="Group_1554"
        data-name="Group 1554"
        transform="translate(0.501 0.499)"
      >
        <path
          id="fill"
          data-name="Exclusion 1"
          d="M48.088,104.054a22.669,22.669,0,0,1-11.32-3.033L11.319,86.328A22.641,22.641,0,0,1,0,66.72V37.335a22.639,22.639,0,0,1,11.32-19.607L36.769,3.035a22.639,22.639,0,0,1,22.64,0L84.857,17.727a22.639,22.639,0,0,1,11.32,19.607V66.72a22.641,22.641,0,0,1-11.32,19.608L59.409,101.021A22.673,22.673,0,0,1,48.088,104.054ZM55.529,30.8h0l-1.039.208-5.71,1.039-2.285.623-4.983,2.492-5.193,4.568-2.907,4.673-.623,1.454-1.04,2.906-.416,2.493-.517,7.165.414,3.114.209,2.182.1,2.283,1.869,4.88,1.351,2.492,1.868,2.8,1.765,1.766,1.663.83.83.727,2.492,1.454,2.7,1.142,2.075.623H49.3l2.389.831,5.711.416,5.191-.208,4.882-.623,2.6-1.039,3.84-1.869,4.569-4.361,2.389-3.324,1.246-2.7.831-2.7v-.726l-.934-.624-4.362.1-4.983-.1-.832,2.492L70.067,71.2,68.093,72.65,64.562,74l-4.88.311-3.324-.935L54.49,72.234l-2.7-2.7-1.038-2.182L50.544,65.8l-.727-1.246L49.3,61.228l.312-.83.623-.209,4.05.416,29.075.208.933-.831-.1-.935V58.01l.1-5.4-1.246-6.335L81.9,43.785,80.345,41.5l-1.868-2.906-2.908-2.7-2.492-1.661L69.65,32.674l-4.361-1.454L60.512,30.6l-2.49.311-2.493-.1ZM74.739,53.96h0l-.312-.519.416-.519.623.209v.623l-.726.207ZM50.544,52.507h0l-.518-.831.415-2.492.519-4.567.623-1.247,1.039-1.35,2.908-1.765,1.764-.519h1.87l1.868.1,3.117,1.453.519,1.246L65.7,45.653l.416,5.712-.832.726-6.54-.1-7.166.207-1.038.312Z"
          transform="translate(0 0)"
          stroke="rgba(0,0,0,0)"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>
  </div>
  <div class="logo" v-else :class="{ animate, customWidth }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232.434 52.402"
      class="form-1"
    >
      <g transform="translate(-38.402 -125.07)" style="isolation: isolate">
        <path
          d="M261.178,171.3l8-13.864a12.335,12.335,0,0,0,0-12.336l-8-13.866A12.337,12.337,0,0,0,250.5,125.07H58.742a12.337,12.337,0,0,0-10.683,6.167L40.054,145.1a12.34,12.34,0,0,0,0,12.336L48.059,171.3a12.336,12.336,0,0,0,10.683,6.168H250.5A12.336,12.336,0,0,0,261.178,171.3Z"
          transform="translate(0 0)"
          id="fill"
          style="mix-blend-mode: multiply; isolation: isolate"
        />
        <g transform="translate(61.269 137.709)">
          <path
            d="M133.751,161.539l-.034-1.284-.07-1.318.382-.347h.867l.174-.034.52-.416.174-1.561V153.7l-.139-.486.069-1.769-.035-1.11-.623-1.18-.174-.242-.728-.624-1.318.034-.971.416-.868,1.144-.242.521v.624l-.139.485-.035,1.562v1.388l-.1,1.214.208.416.034.971-.173.555.069.486.312.381.452.174.867.034.139.069.139.312.035,2.29-.174.174-1.873.034-1.109-.173-6.07.139-.208.069-.52-.208.1-1.492-.173-.936.139-.278.52-.173.833.1.382-.278.173-.555v-6.243l-.069-1.561-.174-.278h-.173l-.416-.868-1.041-.45h-.694l-.8.139-1.11,1.041-.278.555-.278,1.526v.589l-.139.555v2.74l-.034,2.74.416.416.694-.1.659-.208.242.243.035,1.352.034,1.388-.1.208-7.215-.1-2.809.14-.313-.14.1-1.525-.173-.486.555-.763h1.976l.382-.278.034-.381-.485-.624v-1.041l.208-.937-.1-1.041-.035-3.884-.068-2.463-.174-.347H111.24l-.208-.174.035-2.463.346-.1.833-.208.763.1.208-.034,4.891.034.555-.1.589-.034.208.381-.173,1.422.069.937.312.312.278-.278.416-.694,1.075-1.248.624-.416.9-.452.694-.208.833-.173.521-.035h.276l.278-.034.763.208.624.278,1.352.554.347.348.694.97.243.347.416,1.18.208.139.52-.416.173.1h.382l.1-.138-.138-.174-.521.035-.139-.174.278-.45.52-.971.763-.694.521-.1.52-.52.9-.278,1.249-.173,1.769.1.9.208,1.491.833.521.52.589.833.555,1.11.313,1.388.173,1.249-.069,5.758-.035,1.561.348.555.623.278.9-.1.243.1.207.381v.763l.1.8.034.347.069.381-.173.208-9.3.035-.694.1Zm2.29-13.355.589-.486.278-.312h.208l.174-.174V146.9l-.243-.139H136.8l-.382.381-.381.1-.521.45-.381.242v.174l.034.139Zm1.075,9.33.139-.381-.242-.208h-.1l-.278.208.139.381Zm1.838-.278v-.278h-.139l-.1-.034-.174.208.139.173Zm.139-.381.174-.174V156.4l-.243-.1h-.034l-.174.278.174.278Zm.833.242v-.486h-.139l-.1-.034-.174.347.1.242Z"
            transform="translate(-53.921 -135.767)"
            fill="#fff"
          />
          <path
            d="M146.033,161.694l-.763-.208-.8-.208-.867-.45-.66-.382-.347-.659-.52-.833-.069-.971-.243-.624-.069-.867.208-.763.347-.694.452-.624.833-.729.589-.45,1.249-.555,1.144-.278.347-.242.659-.174,1.561-.45h.279l.8-.416.589-.242.971-.452.313-.347.486-.9-.035-1.006L152,147.16l-1.075-.659-.45-.1h-.9l-1.318.139-.868.173-.068.208.276.382.486.381.278.555v.624l-.381.8-.416.416-1.18.624-.971.069-1.318-.139-1.283-.971-.208-.486-.208-.624v-.416l.278-.9.66-.867.278-.486.346-.069.487-.381,1.595-.763.763-.313,1.6-.415,1.665-.208h1.872l.9.208.9.138,1.075.278.936.416.624.555.348.069,1.005,1.007.45.762.313,1.354v5.792l.034.624.035.66-.174.728-.034.1-.347-.208-.313.034v.347l.243.139.45-.174.035.07.1,1.214.208.45.416.208.486-.034.278-.139.242-.589v-.416l-.034-.452v-2.185l.208-.174,2.012-.1.173.174-.069,1.838-.069,2.15-.139.624-.173.45-.66.8-1.144.763-1.388.347-1.18.069-1.283-.139-1.006-.347-.555-.243-1.075-1.005-.521-1.005-.069-.833-.1-.382h-.207l-.208.208-.035.347-.035.313-.312.45-.381.45-.278.313-.486.589h-.313l-.45.521-1.284.52-.97.313-.763-.278-.347.347Zm-1.873-15.3v-.347l-.1-.035h-.1l-.173.174v.242Zm6.314,11.48.8-.694.589-.659.416-.9.069-.833.1-.694.278-1.178-.069-.486.069-.59h-.1l-.243.243-.208.45-.589.313-.763.52-1.11.555-1.075.312-.763.694-.381.521v.763l.242.728.452.763.589.312.347.1Zm1.144-5.619v-.208l-.1-.069-.278.208.139.139Zm2.809-1.318-.139-.659-.1-.034h-.139l-.068.139.1.623.173.1Zm.347,5.238.034-.139v-.1H154.6v.278Zm1.352-.347-.034-.278-.14-.034h-.1l-.069.208.139.208Zm.069,1.144.1-.208-.208-.208-.243.208.069.208Zm.243-6.589.45-.07.208-.346-.035-.243-.52-.312v.347l-.416.346v.174l.034.139Zm.1-1.041V149.1l-.174-.173h-.1l-.069.139.243.278Zm.278,2.844-.1-.1-.069-.1-.243.208.035.243.312.034Zm.069,6.521V158.5l-.034-.242-.139-.035h-.1l-.069.208.242.278Zm.347-2.463v-.242h-.243l-.242.242v.312l.139.1Z"
            transform="translate(-50.803 -135.818)"
            fill="#fff"
          />
          <path
            d="M170.99,161.485l-.207-.069-9.123-.034-1.352.068-.243-.208.1-.97-.034-.624.069-.833.312-.278,1.491.069.416-.208.208-.416v-.243l-.208-.624.243-2.08-.174-7.111-.416-.416-.555-.1-1.249.243-.346-.208.069-.243.173-.833-.139-1.457.174-.278,1.733.069h4.024l2.011-.173.278.243v.97l-.208.868-.138,2.635.068.278h.347l.208-.312.139-.833.694-1.8.45-.8.416-.416,1.11-.763.416-.034.278.242.243-.174.867-.173h.208l.242-.035.764.243.52-.035.52.243.521.45.381.59.45.659.208.728.07.66-.208.728-.208,1.007-.833.867-.66.416-.694.208-.936.1-.937-.173-.831-.486-.278-.59-.139-.728.242-.867-.173-.208h-.313l-.312.208-1.283,1.422-.486,1.041-.347,1.457-.035.8-.034.763-.1,4.092.313.416.623.243.382-.243h.694l.416.139.763-.173.173.208-.1.52-.069,1.007v.589l-.035.624-.416.347Z"
            transform="translate(-48.956 -135.748)"
            fill="#fff"
          />
          <path
            d="M195.429,162.237l-8.047.034-.278-.139.069-2.185-.034-.313-.069-.276.278-.1.381.243.452-.208.52.278h.208l.174-.208-.1-.521-.833-.763-.381-.624-.694-1.075-.313-.381-.728-1.18-.312-.208-.382.242-.694.729-1.144.8-.1.313-.1,1.352v.555l.208.52.589.278,1.492-.1.139.173v1.908l.034.694-.208.174-7.284-.1-3.018.1-.208-.174.035-2.254-.1-.381.139-.208,2.151.069.208-.208.069-.868-.035-8.949.208-2.115L177.67,145l-.174-1.907-.034-1.909-.1-.276-.381-.174-.694-.069-.936.347h-.278l-.208-.312.173-1.11-.1-.9-.14-.624.208-.174.8-.173.728.381h1.562l.555-.278,1.838-.034,1.041-.1h.381l.555.243.659-.1.243.14.173.276-.1.8-.1,2.635.174,5.238.1.9-.208,1.318.035.728.069.728-.1,1.007-.069,1.041.069.381h.347l1.284-1.352,1.456-1.18.416-.45.59-.624.52-.243.243-.208v-.347l-.14-.069-1.005-.173-1.075.034-.208-.242.139-1.526-.069-.833.139-.381.173-.069,3.4.1,5.515-.1.485-.139h.452l.242.278-.069,2.7-.242.278-.624.243-.694-.174h-.659l-.66.208-.867.66-.382.139-.589.694-.659.312-.486.521-.659.485v.278l.45.382.867.867.347.694.867,1.318.937,1.318.867,1.248.486.729.694.694.555.173,1.11.069.312.208v1.838l.174.8-.278.208Zm-6.174-11.863-.069-.346h-.1l-.1-.035-.1.1v.313l.208.069Z"
            transform="translate(-47.45 -136.431)"
            fill="#fff"
          />
          <path
            d="M202.862,161.605l-.8-.278h-.381l-.694-.208-.9-.381-.833-.486-.276-.243-.555-.278-.59-.589-.624-.936-.45-.833-.624-1.631-.034-.762-.07-.729-.138-1.041.173-2.393.139-.833.347-.971.208-.485.971-1.562,1.733-1.525,1.665-.833.763-.208,1.907-.347.347-.069.833.034.833-.1,1.6.208,1.456.486,1.145.52.833.555.97.9.624.971.521.763.381.833.416,2.116-.034,1.8v.347l.034.313-.312.278-9.712-.069-1.352-.139-.208.069-.1.278.174,1.109.242.416.07.521.346.728.9.9.624.382,1.11.312,1.63-.1,1.18-.45.66-.486.589-.971.278-.833,1.664.035,1.457-.035.313.208v.243l-.278.9-.416.9-.8,1.109-1.525,1.457L209,161.12l-.868.347-1.63.208-1.735.069Zm-.035-10.475,2.394-.069,2.185.034.278-.242-.139-1.907L207.2,147.9l-.174-.416L205.983,147l-.624-.035h-.624l-.589.174-.971.589-.347.45-.208.416-.173,1.526-.139.833.173.278Zm7.978.52v-.208l-.208-.069-.139.174.1.173Z"
            transform="translate(-45.312 -135.799)"
            fill="#fff"
          />
          <path
            d="M221.29,162.1l-1.769-.242-1.075-.347-.8-.555-1.076-1.283-.381-1.423-.069-.486.173-3.087-.069-3.121.069-3.747-.278-.173-.555.069-.554.208-1.145-.034-.312-.243-.035-.8v-.8l-.173-.9.208-.208,1.249.07.8-.07.589-.173.624-.486v-.1l.589-.624.313-.624.243-1.215v-1.595l.276-.1.556.069.867.139h2.427l.1.174.034,1.907v2.393l.208.381.243.14.381-.174,1.18.034.486-.034,2.532-.1.347-.1.242.208.035,2.809-.173.139-1.215-.1-1.8.034-.346-.139-1.631-.069-.347.1-.173.347V156.1l-.174,1.283.313.867.278.174h.312l.624.45.728-.173.313-.313.347-1.525.034-1.665-.138-2.081-.07-.486.208-.278h2.879l.14.174-.069,1.144-.07,1.943-.068.971.173,1.422-.242,1.352-.555.936-1.076.971-.9.452-1.11.208-1.04.208Zm-3.156-4.439.14-.173.1-.313-.1-.347h-.313l-.173.174v.45l.242.208Z"
            transform="translate(-43.554 -136.195)"
            fill="#fff"
          />
          <path
            d="M101.63,161.715l-3.087-.554-1.249-.59-1.144-.936L94.8,158.282l-.729-1.492-.45-1.006-.139-1.041-.1-1.18-.034-.45v-.452l.034-.936v-.9l.279-1.179.52-1.006.174-.452.485-.659v-.1l.521-.8.8-.8.868-.521.554-.381,1.249-.555.66-.173,1.11-.139.936-.174.486-.034.624.069,1.733.173,1.318.278,1.041.278,1.7.936,1.6,1.561.416.487.728,1.178.278.729v.486l.416,1.491v2.151l-.242,1.387-.035.486-.381.763-.45,1.423-.59.867v.139l-1.318,1.214-1.283.66-.381.068-.868.382-.867.242-.416.278-.521-.069-.971.243Zm-4.336-6.624v-.278h-.139l-.069.069v.208Zm.208-1.006-.068-.1h-.208l-.07.208.139.174Zm5.619,4.509.868-.208.381-.347v-.173l.59-.486.242-.868.139-.416.035-.416.208-1.769-.035-.971.035-1.422-.208-1.665-.243-1.041-.416-.485-.694-.9-.381-.208-.763-.243h-.416l-.416-.034-.694.242-.52.278-.59.728-.381.694v.937l-.243.867-.068,1.11-.069,1.561.138.763-.034,1.318.139.278.1.867.243.555.9,1.11.589.208.833.139Zm3.3-.278.173-.208.069-.555-.347-.139v-.139H106.1l-.278.452v.208l.278.381Z"
            transform="translate(-55.703 -135.806)"
            fill="#fff"
          />
          <path
            d="M66.137,162.352l-.8-.278h-.381l-.694-.208-.9-.382L62.53,161l-.278-.242-.555-.278-.589-.589-.624-.937-.45-.833-.624-1.63-.035-.762L59.3,155l-.139-1.041.174-2.393.139-.833.347-.971.208-.486L61,147.714l1.733-1.525,1.665-.833.763-.208,1.907-.348.347-.068.833.034.833-.1,1.595.208,1.457.486,1.145.52.831.555.971.9.624.97.521.764.381.832.416,2.117-.034,1.8v.347l.034.313-.312.276L67,154.687l-1.352-.14-.208.07-.1.278L65.513,156l.242.416.069.521.347.728.9.9.624.381,1.11.313,1.63-.1,1.18-.45.659-.486.59-.971.278-.833,1.664.035,1.457-.035.313.208v.243l-.278.9-.416.9-.8,1.109-1.526,1.457-1.283.624-.867.347-1.631.208-1.733.069ZM66.1,151.876l2.393-.068,2.185.034.279-.242-.14-1.909-.346-1.04-.174-.416-1.041-.486-.624-.035H68.01l-.589.173-.971.59-.347.45-.208.416-.173,1.526-.139.833.173.278Zm7.978.521v-.208l-.208-.069-.139.173.1.174Z"
            transform="translate(-59.165 -136.546)"
            fill="#fff"
          />
          <path
            d="M85.082,161.612l-1.457-.139-.589-.278-1.284-.069-1.595-1.318h-.174l-1.007-1.18-.589-.8-.312-.624-.486-1.041-.208-1.318-.139-1.144v-1.11l.173-1.873.279-.833.312-.9.589-1.11.589-.763.972-1.006,1.04-.868.9-.486,1.8-.659,1.457-.174.416-.069,1.872.035,1.318.347.971.382,1.18.589,1.457,1.11.486.831.312.624.139,1.007V150.1l-.347.66-.763.867-.589.382-.729.208-1.109-.035-.729-.208-.486-.312-.659-.381-.278-.348-.347-1.075.173-1.005.313-.486,1.075-.867v-.313l-.45-.208-1.11-.208-1.041-.069-1.007.347-.554.486-.347.381-.66,1.041-.312,1.18-.1,1.561-.07,1.491.208.66v.555l.208.971.1.8.659,1.075.521.59,1.075.416h1.8l.971-.59.208-.242.936-1.214.208-.729.208-.763.242-.173h2.706l.312.208v.486l-.139.278-.208,1.144-.554,1.11-.66,1.283-.936.937-1.7,1.075-1.457.486-1.11.208H85.844Zm3.434-9.227v-.278h.347l.416.208v.347h-.313Z"
            transform="translate(-57.333 -135.806)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232.434 52.402"
      class="form-2"
    >
      <g transform="translate(-38.402 -125.07)" style="isolation: isolate">
        <path
          d="M261.178,171.3l8-13.864a12.335,12.335,0,0,0,0-12.336l-8-13.866A12.337,12.337,0,0,0,250.5,125.07H58.742a12.337,12.337,0,0,0-10.683,6.167L40.054,145.1a12.34,12.34,0,0,0,0,12.336L48.059,171.3a12.336,12.336,0,0,0,10.683,6.168H250.5A12.336,12.336,0,0,0,261.178,171.3Z"
          transform="translate(0 0)"
          fill="#8F6087"
          style="mix-blend-mode: multiply; isolation: isolate"
        />
        <g transform="translate(61.269 137.709)">
          <path
            d="M133.751,161.539l-.034-1.284-.07-1.318.382-.347h.867l.174-.034.52-.416.174-1.561V153.7l-.139-.486.069-1.769-.035-1.11-.623-1.18-.174-.242-.728-.624-1.318.034-.971.416-.868,1.144-.242.521v.624l-.139.485-.035,1.562v1.388l-.1,1.214.208.416.034.971-.173.555.069.486.312.381.452.174.867.034.139.069.139.312.035,2.29-.174.174-1.873.034-1.109-.173-6.07.139-.208.069-.52-.208.1-1.492-.173-.936.139-.278.52-.173.833.1.382-.278.173-.555v-6.243l-.069-1.561-.174-.278h-.173l-.416-.868-1.041-.45h-.694l-.8.139-1.11,1.041-.278.555-.278,1.526v.589l-.139.555v2.74l-.034,2.74.416.416.694-.1.659-.208.242.243.035,1.352.034,1.388-.1.208-7.215-.1-2.809.14-.313-.14.1-1.525-.173-.486.555-.763h1.976l.382-.278.034-.381-.485-.624v-1.041l.208-.937-.1-1.041-.035-3.884-.068-2.463-.174-.347H111.24l-.208-.174.035-2.463.346-.1.833-.208.763.1.208-.034,4.891.034.555-.1.589-.034.208.381-.173,1.422.069.937.312.312.278-.278.416-.694,1.075-1.248.624-.416.9-.452.694-.208.833-.173.521-.035h.276l.278-.034.763.208.624.278,1.352.554.347.348.694.97.243.347.416,1.18.208.139.52-.416.173.1h.382l.1-.138-.138-.174-.521.035-.139-.174.278-.45.52-.971.763-.694.521-.1.52-.52.9-.278,1.249-.173,1.769.1.9.208,1.491.833.521.52.589.833.555,1.11.313,1.388.173,1.249-.069,5.758-.035,1.561.348.555.623.278.9-.1.243.1.207.381v.763l.1.8.034.347.069.381-.173.208-9.3.035-.694.1Zm2.29-13.355.589-.486.278-.312h.208l.174-.174V146.9l-.243-.139H136.8l-.382.381-.381.1-.521.45-.381.242v.174l.034.139Zm1.075,9.33.139-.381-.242-.208h-.1l-.278.208.139.381Zm1.838-.278v-.278h-.139l-.1-.034-.174.208.139.173Zm.139-.381.174-.174V156.4l-.243-.1h-.034l-.174.278.174.278Zm.833.242v-.486h-.139l-.1-.034-.174.347.1.242Z"
            transform="translate(-53.921 -135.767)"
            fill="#fff"
          />
          <path
            d="M146.033,161.694l-.763-.208-.8-.208-.867-.45-.66-.382-.347-.659-.52-.833-.069-.971-.243-.624-.069-.867.208-.763.347-.694.452-.624.833-.729.589-.45,1.249-.555,1.144-.278.347-.242.659-.174,1.561-.45h.279l.8-.416.589-.242.971-.452.313-.347.486-.9-.035-1.006L152,147.16l-1.075-.659-.45-.1h-.9l-1.318.139-.868.173-.068.208.276.382.486.381.278.555v.624l-.381.8-.416.416-1.18.624-.971.069-1.318-.139-1.283-.971-.208-.486-.208-.624v-.416l.278-.9.66-.867.278-.486.346-.069.487-.381,1.595-.763.763-.313,1.6-.415,1.665-.208h1.872l.9.208.9.138,1.075.278.936.416.624.555.348.069,1.005,1.007.45.762.313,1.354v5.792l.034.624.035.66-.174.728-.034.1-.347-.208-.313.034v.347l.243.139.45-.174.035.07.1,1.214.208.45.416.208.486-.034.278-.139.242-.589v-.416l-.034-.452v-2.185l.208-.174,2.012-.1.173.174-.069,1.838-.069,2.15-.139.624-.173.45-.66.8-1.144.763-1.388.347-1.18.069-1.283-.139-1.006-.347-.555-.243-1.075-1.005-.521-1.005-.069-.833-.1-.382h-.207l-.208.208-.035.347-.035.313-.312.45-.381.45-.278.313-.486.589h-.313l-.45.521-1.284.52-.97.313-.763-.278-.347.347Zm-1.873-15.3v-.347l-.1-.035h-.1l-.173.174v.242Zm6.314,11.48.8-.694.589-.659.416-.9.069-.833.1-.694.278-1.178-.069-.486.069-.59h-.1l-.243.243-.208.45-.589.313-.763.52-1.11.555-1.075.312-.763.694-.381.521v.763l.242.728.452.763.589.312.347.1Zm1.144-5.619v-.208l-.1-.069-.278.208.139.139Zm2.809-1.318-.139-.659-.1-.034h-.139l-.068.139.1.623.173.1Zm.347,5.238.034-.139v-.1H154.6v.278Zm1.352-.347-.034-.278-.14-.034h-.1l-.069.208.139.208Zm.069,1.144.1-.208-.208-.208-.243.208.069.208Zm.243-6.589.45-.07.208-.346-.035-.243-.52-.312v.347l-.416.346v.174l.034.139Zm.1-1.041V149.1l-.174-.173h-.1l-.069.139.243.278Zm.278,2.844-.1-.1-.069-.1-.243.208.035.243.312.034Zm.069,6.521V158.5l-.034-.242-.139-.035h-.1l-.069.208.242.278Zm.347-2.463v-.242h-.243l-.242.242v.312l.139.1Z"
            transform="translate(-50.803 -135.818)"
            fill="#fff"
          />
          <path
            d="M170.99,161.485l-.207-.069-9.123-.034-1.352.068-.243-.208.1-.97-.034-.624.069-.833.312-.278,1.491.069.416-.208.208-.416v-.243l-.208-.624.243-2.08-.174-7.111-.416-.416-.555-.1-1.249.243-.346-.208.069-.243.173-.833-.139-1.457.174-.278,1.733.069h4.024l2.011-.173.278.243v.97l-.208.868-.138,2.635.068.278h.347l.208-.312.139-.833.694-1.8.45-.8.416-.416,1.11-.763.416-.034.278.242.243-.174.867-.173h.208l.242-.035.764.243.52-.035.52.243.521.45.381.59.45.659.208.728.07.66-.208.728-.208,1.007-.833.867-.66.416-.694.208-.936.1-.937-.173-.831-.486-.278-.59-.139-.728.242-.867-.173-.208h-.313l-.312.208-1.283,1.422-.486,1.041-.347,1.457-.035.8-.034.763-.1,4.092.313.416.623.243.382-.243h.694l.416.139.763-.173.173.208-.1.52-.069,1.007v.589l-.035.624-.416.347Z"
            transform="translate(-48.956 -135.748)"
            fill="#fff"
          />
          <path
            d="M195.429,162.237l-8.047.034-.278-.139.069-2.185-.034-.313-.069-.276.278-.1.381.243.452-.208.52.278h.208l.174-.208-.1-.521-.833-.763-.381-.624-.694-1.075-.313-.381-.728-1.18-.312-.208-.382.242-.694.729-1.144.8-.1.313-.1,1.352v.555l.208.52.589.278,1.492-.1.139.173v1.908l.034.694-.208.174-7.284-.1-3.018.1-.208-.174.035-2.254-.1-.381.139-.208,2.151.069.208-.208.069-.868-.035-8.949.208-2.115L177.67,145l-.174-1.907-.034-1.909-.1-.276-.381-.174-.694-.069-.936.347h-.278l-.208-.312.173-1.11-.1-.9-.14-.624.208-.174.8-.173.728.381h1.562l.555-.278,1.838-.034,1.041-.1h.381l.555.243.659-.1.243.14.173.276-.1.8-.1,2.635.174,5.238.1.9-.208,1.318.035.728.069.728-.1,1.007-.069,1.041.069.381h.347l1.284-1.352,1.456-1.18.416-.45.59-.624.52-.243.243-.208v-.347l-.14-.069-1.005-.173-1.075.034-.208-.242.139-1.526-.069-.833.139-.381.173-.069,3.4.1,5.515-.1.485-.139h.452l.242.278-.069,2.7-.242.278-.624.243-.694-.174h-.659l-.66.208-.867.66-.382.139-.589.694-.659.312-.486.521-.659.485v.278l.45.382.867.867.347.694.867,1.318.937,1.318.867,1.248.486.729.694.694.555.173,1.11.069.312.208v1.838l.174.8-.278.208Zm-6.174-11.863-.069-.346h-.1l-.1-.035-.1.1v.313l.208.069Z"
            transform="translate(-47.45 -136.431)"
            fill="#fff"
          />
          <path
            d="M202.862,161.605l-.8-.278h-.381l-.694-.208-.9-.381-.833-.486-.276-.243-.555-.278-.59-.589-.624-.936-.45-.833-.624-1.631-.034-.762-.07-.729-.138-1.041.173-2.393.139-.833.347-.971.208-.485.971-1.562,1.733-1.525,1.665-.833.763-.208,1.907-.347.347-.069.833.034.833-.1,1.6.208,1.456.486,1.145.52.833.555.97.9.624.971.521.763.381.833.416,2.116-.034,1.8v.347l.034.313-.312.278-9.712-.069-1.352-.139-.208.069-.1.278.174,1.109.242.416.07.521.346.728.9.9.624.382,1.11.312,1.63-.1,1.18-.45.66-.486.589-.971.278-.833,1.664.035,1.457-.035.313.208v.243l-.278.9-.416.9-.8,1.109-1.525,1.457L209,161.12l-.868.347-1.63.208-1.735.069Zm-.035-10.475,2.394-.069,2.185.034.278-.242-.139-1.907L207.2,147.9l-.174-.416L205.983,147l-.624-.035h-.624l-.589.174-.971.589-.347.45-.208.416-.173,1.526-.139.833.173.278Zm7.978.52v-.208l-.208-.069-.139.174.1.173Z"
            transform="translate(-45.312 -135.799)"
            fill="#fff"
          />
          <path
            d="M221.29,162.1l-1.769-.242-1.075-.347-.8-.555-1.076-1.283-.381-1.423-.069-.486.173-3.087-.069-3.121.069-3.747-.278-.173-.555.069-.554.208-1.145-.034-.312-.243-.035-.8v-.8l-.173-.9.208-.208,1.249.07.8-.07.589-.173.624-.486v-.1l.589-.624.313-.624.243-1.215v-1.595l.276-.1.556.069.867.139h2.427l.1.174.034,1.907v2.393l.208.381.243.14.381-.174,1.18.034.486-.034,2.532-.1.347-.1.242.208.035,2.809-.173.139-1.215-.1-1.8.034-.346-.139-1.631-.069-.347.1-.173.347V156.1l-.174,1.283.313.867.278.174h.312l.624.45.728-.173.313-.313.347-1.525.034-1.665-.138-2.081-.07-.486.208-.278h2.879l.14.174-.069,1.144-.07,1.943-.068.971.173,1.422-.242,1.352-.555.936-1.076.971-.9.452-1.11.208-1.04.208Zm-3.156-4.439.14-.173.1-.313-.1-.347h-.313l-.173.174v.45l.242.208Z"
            transform="translate(-43.554 -136.195)"
            fill="#fff"
          />
          <path
            d="M101.63,161.715l-3.087-.554-1.249-.59-1.144-.936L94.8,158.282l-.729-1.492-.45-1.006-.139-1.041-.1-1.18-.034-.45v-.452l.034-.936v-.9l.279-1.179.52-1.006.174-.452.485-.659v-.1l.521-.8.8-.8.868-.521.554-.381,1.249-.555.66-.173,1.11-.139.936-.174.486-.034.624.069,1.733.173,1.318.278,1.041.278,1.7.936,1.6,1.561.416.487.728,1.178.278.729v.486l.416,1.491v2.151l-.242,1.387-.035.486-.381.763-.45,1.423-.59.867v.139l-1.318,1.214-1.283.66-.381.068-.868.382-.867.242-.416.278-.521-.069-.971.243Zm-4.336-6.624v-.278h-.139l-.069.069v.208Zm.208-1.006-.068-.1h-.208l-.07.208.139.174Zm5.619,4.509.868-.208.381-.347v-.173l.59-.486.242-.868.139-.416.035-.416.208-1.769-.035-.971.035-1.422-.208-1.665-.243-1.041-.416-.485-.694-.9-.381-.208-.763-.243h-.416l-.416-.034-.694.242-.52.278-.59.728-.381.694v.937l-.243.867-.068,1.11-.069,1.561.138.763-.034,1.318.139.278.1.867.243.555.9,1.11.589.208.833.139Zm3.3-.278.173-.208.069-.555-.347-.139v-.139H106.1l-.278.452v.208l.278.381Z"
            transform="translate(-55.703 -135.806)"
            fill="#fff"
          />
          <path
            d="M66.137,162.352l-.8-.278h-.381l-.694-.208-.9-.382L62.53,161l-.278-.242-.555-.278-.589-.589-.624-.937-.45-.833-.624-1.63-.035-.762L59.3,155l-.139-1.041.174-2.393.139-.833.347-.971.208-.486L61,147.714l1.733-1.525,1.665-.833.763-.208,1.907-.348.347-.068.833.034.833-.1,1.595.208,1.457.486,1.145.52.831.555.971.9.624.97.521.764.381.832.416,2.117-.034,1.8v.347l.034.313-.312.276L67,154.687l-1.352-.14-.208.07-.1.278L65.513,156l.242.416.069.521.347.728.9.9.624.381,1.11.313,1.63-.1,1.18-.45.659-.486.59-.971.278-.833,1.664.035,1.457-.035.313.208v.243l-.278.9-.416.9-.8,1.109-1.526,1.457-1.283.624-.867.347-1.631.208-1.733.069ZM66.1,151.876l2.393-.068,2.185.034.279-.242-.14-1.909-.346-1.04-.174-.416-1.041-.486-.624-.035H68.01l-.589.173-.971.59-.347.45-.208.416-.173,1.526-.139.833.173.278Zm7.978.521v-.208l-.208-.069-.139.173.1.174Z"
            transform="translate(-59.165 -136.546)"
            fill="#fff"
          />
          <path
            d="M85.082,161.612l-1.457-.139-.589-.278-1.284-.069-1.595-1.318h-.174l-1.007-1.18-.589-.8-.312-.624-.486-1.041-.208-1.318-.139-1.144v-1.11l.173-1.873.279-.833.312-.9.589-1.11.589-.763.972-1.006,1.04-.868.9-.486,1.8-.659,1.457-.174.416-.069,1.872.035,1.318.347.971.382,1.18.589,1.457,1.11.486.831.312.624.139,1.007V150.1l-.347.66-.763.867-.589.382-.729.208-1.109-.035-.729-.208-.486-.312-.659-.381-.278-.348-.347-1.075.173-1.005.313-.486,1.075-.867v-.313l-.45-.208-1.11-.208-1.041-.069-1.007.347-.554.486-.347.381-.66,1.041-.312,1.18-.1,1.561-.07,1.491.208.66v.555l.208.971.1.8.659,1.075.521.59,1.075.416h1.8l.971-.59.208-.242.936-1.214.208-.729.208-.763.242-.173h2.706l.312.208v.486l-.139.278-.208,1.144-.554,1.11-.66,1.283-.936.937-1.7,1.075-1.457.486-1.11.208H85.844Zm3.434-9.227v-.278h.347l.416.208v.347h-.313Z"
            transform="translate(-57.333 -135.806)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 232.434 52.402"
      class="form-3"
    >
      <g transform="translate(-38.402 -125.07)" style="isolation: isolate">
        <path
          d="M261.178,171.3l8-13.864a12.335,12.335,0,0,0,0-12.336l-8-13.866A12.337,12.337,0,0,0,250.5,125.07H58.742a12.337,12.337,0,0,0-10.683,6.167L40.054,145.1a12.34,12.34,0,0,0,0,12.336L48.059,171.3a12.336,12.336,0,0,0,10.683,6.168H250.5A12.336,12.336,0,0,0,261.178,171.3Z"
          transform="translate(0 0)"
          fill="#F2C175"
          style="mix-blend-mode: multiply; isolation: isolate"
        />
        <g transform="translate(61.269 137.709)">
          <path
            d="M133.751,161.539l-.034-1.284-.07-1.318.382-.347h.867l.174-.034.52-.416.174-1.561V153.7l-.139-.486.069-1.769-.035-1.11-.623-1.18-.174-.242-.728-.624-1.318.034-.971.416-.868,1.144-.242.521v.624l-.139.485-.035,1.562v1.388l-.1,1.214.208.416.034.971-.173.555.069.486.312.381.452.174.867.034.139.069.139.312.035,2.29-.174.174-1.873.034-1.109-.173-6.07.139-.208.069-.52-.208.1-1.492-.173-.936.139-.278.52-.173.833.1.382-.278.173-.555v-6.243l-.069-1.561-.174-.278h-.173l-.416-.868-1.041-.45h-.694l-.8.139-1.11,1.041-.278.555-.278,1.526v.589l-.139.555v2.74l-.034,2.74.416.416.694-.1.659-.208.242.243.035,1.352.034,1.388-.1.208-7.215-.1-2.809.14-.313-.14.1-1.525-.173-.486.555-.763h1.976l.382-.278.034-.381-.485-.624v-1.041l.208-.937-.1-1.041-.035-3.884-.068-2.463-.174-.347H111.24l-.208-.174.035-2.463.346-.1.833-.208.763.1.208-.034,4.891.034.555-.1.589-.034.208.381-.173,1.422.069.937.312.312.278-.278.416-.694,1.075-1.248.624-.416.9-.452.694-.208.833-.173.521-.035h.276l.278-.034.763.208.624.278,1.352.554.347.348.694.97.243.347.416,1.18.208.139.52-.416.173.1h.382l.1-.138-.138-.174-.521.035-.139-.174.278-.45.52-.971.763-.694.521-.1.52-.52.9-.278,1.249-.173,1.769.1.9.208,1.491.833.521.52.589.833.555,1.11.313,1.388.173,1.249-.069,5.758-.035,1.561.348.555.623.278.9-.1.243.1.207.381v.763l.1.8.034.347.069.381-.173.208-9.3.035-.694.1Zm2.29-13.355.589-.486.278-.312h.208l.174-.174V146.9l-.243-.139H136.8l-.382.381-.381.1-.521.45-.381.242v.174l.034.139Zm1.075,9.33.139-.381-.242-.208h-.1l-.278.208.139.381Zm1.838-.278v-.278h-.139l-.1-.034-.174.208.139.173Zm.139-.381.174-.174V156.4l-.243-.1h-.034l-.174.278.174.278Zm.833.242v-.486h-.139l-.1-.034-.174.347.1.242Z"
            transform="translate(-53.921 -135.767)"
            fill="#fff"
          />
          <path
            d="M146.033,161.694l-.763-.208-.8-.208-.867-.45-.66-.382-.347-.659-.52-.833-.069-.971-.243-.624-.069-.867.208-.763.347-.694.452-.624.833-.729.589-.45,1.249-.555,1.144-.278.347-.242.659-.174,1.561-.45h.279l.8-.416.589-.242.971-.452.313-.347.486-.9-.035-1.006L152,147.16l-1.075-.659-.45-.1h-.9l-1.318.139-.868.173-.068.208.276.382.486.381.278.555v.624l-.381.8-.416.416-1.18.624-.971.069-1.318-.139-1.283-.971-.208-.486-.208-.624v-.416l.278-.9.66-.867.278-.486.346-.069.487-.381,1.595-.763.763-.313,1.6-.415,1.665-.208h1.872l.9.208.9.138,1.075.278.936.416.624.555.348.069,1.005,1.007.45.762.313,1.354v5.792l.034.624.035.66-.174.728-.034.1-.347-.208-.313.034v.347l.243.139.45-.174.035.07.1,1.214.208.45.416.208.486-.034.278-.139.242-.589v-.416l-.034-.452v-2.185l.208-.174,2.012-.1.173.174-.069,1.838-.069,2.15-.139.624-.173.45-.66.8-1.144.763-1.388.347-1.18.069-1.283-.139-1.006-.347-.555-.243-1.075-1.005-.521-1.005-.069-.833-.1-.382h-.207l-.208.208-.035.347-.035.313-.312.45-.381.45-.278.313-.486.589h-.313l-.45.521-1.284.52-.97.313-.763-.278-.347.347Zm-1.873-15.3v-.347l-.1-.035h-.1l-.173.174v.242Zm6.314,11.48.8-.694.589-.659.416-.9.069-.833.1-.694.278-1.178-.069-.486.069-.59h-.1l-.243.243-.208.45-.589.313-.763.52-1.11.555-1.075.312-.763.694-.381.521v.763l.242.728.452.763.589.312.347.1Zm1.144-5.619v-.208l-.1-.069-.278.208.139.139Zm2.809-1.318-.139-.659-.1-.034h-.139l-.068.139.1.623.173.1Zm.347,5.238.034-.139v-.1H154.6v.278Zm1.352-.347-.034-.278-.14-.034h-.1l-.069.208.139.208Zm.069,1.144.1-.208-.208-.208-.243.208.069.208Zm.243-6.589.45-.07.208-.346-.035-.243-.52-.312v.347l-.416.346v.174l.034.139Zm.1-1.041V149.1l-.174-.173h-.1l-.069.139.243.278Zm.278,2.844-.1-.1-.069-.1-.243.208.035.243.312.034Zm.069,6.521V158.5l-.034-.242-.139-.035h-.1l-.069.208.242.278Zm.347-2.463v-.242h-.243l-.242.242v.312l.139.1Z"
            transform="translate(-50.803 -135.818)"
            fill="#fff"
          />
          <path
            d="M170.99,161.485l-.207-.069-9.123-.034-1.352.068-.243-.208.1-.97-.034-.624.069-.833.312-.278,1.491.069.416-.208.208-.416v-.243l-.208-.624.243-2.08-.174-7.111-.416-.416-.555-.1-1.249.243-.346-.208.069-.243.173-.833-.139-1.457.174-.278,1.733.069h4.024l2.011-.173.278.243v.97l-.208.868-.138,2.635.068.278h.347l.208-.312.139-.833.694-1.8.45-.8.416-.416,1.11-.763.416-.034.278.242.243-.174.867-.173h.208l.242-.035.764.243.52-.035.52.243.521.45.381.59.45.659.208.728.07.66-.208.728-.208,1.007-.833.867-.66.416-.694.208-.936.1-.937-.173-.831-.486-.278-.59-.139-.728.242-.867-.173-.208h-.313l-.312.208-1.283,1.422-.486,1.041-.347,1.457-.035.8-.034.763-.1,4.092.313.416.623.243.382-.243h.694l.416.139.763-.173.173.208-.1.52-.069,1.007v.589l-.035.624-.416.347Z"
            transform="translate(-48.956 -135.748)"
            fill="#fff"
          />
          <path
            d="M195.429,162.237l-8.047.034-.278-.139.069-2.185-.034-.313-.069-.276.278-.1.381.243.452-.208.52.278h.208l.174-.208-.1-.521-.833-.763-.381-.624-.694-1.075-.313-.381-.728-1.18-.312-.208-.382.242-.694.729-1.144.8-.1.313-.1,1.352v.555l.208.52.589.278,1.492-.1.139.173v1.908l.034.694-.208.174-7.284-.1-3.018.1-.208-.174.035-2.254-.1-.381.139-.208,2.151.069.208-.208.069-.868-.035-8.949.208-2.115L177.67,145l-.174-1.907-.034-1.909-.1-.276-.381-.174-.694-.069-.936.347h-.278l-.208-.312.173-1.11-.1-.9-.14-.624.208-.174.8-.173.728.381h1.562l.555-.278,1.838-.034,1.041-.1h.381l.555.243.659-.1.243.14.173.276-.1.8-.1,2.635.174,5.238.1.9-.208,1.318.035.728.069.728-.1,1.007-.069,1.041.069.381h.347l1.284-1.352,1.456-1.18.416-.45.59-.624.52-.243.243-.208v-.347l-.14-.069-1.005-.173-1.075.034-.208-.242.139-1.526-.069-.833.139-.381.173-.069,3.4.1,5.515-.1.485-.139h.452l.242.278-.069,2.7-.242.278-.624.243-.694-.174h-.659l-.66.208-.867.66-.382.139-.589.694-.659.312-.486.521-.659.485v.278l.45.382.867.867.347.694.867,1.318.937,1.318.867,1.248.486.729.694.694.555.173,1.11.069.312.208v1.838l.174.8-.278.208Zm-6.174-11.863-.069-.346h-.1l-.1-.035-.1.1v.313l.208.069Z"
            transform="translate(-47.45 -136.431)"
            fill="#fff"
          />
          <path
            d="M202.862,161.605l-.8-.278h-.381l-.694-.208-.9-.381-.833-.486-.276-.243-.555-.278-.59-.589-.624-.936-.45-.833-.624-1.631-.034-.762-.07-.729-.138-1.041.173-2.393.139-.833.347-.971.208-.485.971-1.562,1.733-1.525,1.665-.833.763-.208,1.907-.347.347-.069.833.034.833-.1,1.6.208,1.456.486,1.145.52.833.555.97.9.624.971.521.763.381.833.416,2.116-.034,1.8v.347l.034.313-.312.278-9.712-.069-1.352-.139-.208.069-.1.278.174,1.109.242.416.07.521.346.728.9.9.624.382,1.11.312,1.63-.1,1.18-.45.66-.486.589-.971.278-.833,1.664.035,1.457-.035.313.208v.243l-.278.9-.416.9-.8,1.109-1.525,1.457L209,161.12l-.868.347-1.63.208-1.735.069Zm-.035-10.475,2.394-.069,2.185.034.278-.242-.139-1.907L207.2,147.9l-.174-.416L205.983,147l-.624-.035h-.624l-.589.174-.971.589-.347.45-.208.416-.173,1.526-.139.833.173.278Zm7.978.52v-.208l-.208-.069-.139.174.1.173Z"
            transform="translate(-45.312 -135.799)"
            fill="#fff"
          />
          <path
            d="M221.29,162.1l-1.769-.242-1.075-.347-.8-.555-1.076-1.283-.381-1.423-.069-.486.173-3.087-.069-3.121.069-3.747-.278-.173-.555.069-.554.208-1.145-.034-.312-.243-.035-.8v-.8l-.173-.9.208-.208,1.249.07.8-.07.589-.173.624-.486v-.1l.589-.624.313-.624.243-1.215v-1.595l.276-.1.556.069.867.139h2.427l.1.174.034,1.907v2.393l.208.381.243.14.381-.174,1.18.034.486-.034,2.532-.1.347-.1.242.208.035,2.809-.173.139-1.215-.1-1.8.034-.346-.139-1.631-.069-.347.1-.173.347V156.1l-.174,1.283.313.867.278.174h.312l.624.45.728-.173.313-.313.347-1.525.034-1.665-.138-2.081-.07-.486.208-.278h2.879l.14.174-.069,1.144-.07,1.943-.068.971.173,1.422-.242,1.352-.555.936-1.076.971-.9.452-1.11.208-1.04.208Zm-3.156-4.439.14-.173.1-.313-.1-.347h-.313l-.173.174v.45l.242.208Z"
            transform="translate(-43.554 -136.195)"
            fill="#fff"
          />
          <path
            d="M101.63,161.715l-3.087-.554-1.249-.59-1.144-.936L94.8,158.282l-.729-1.492-.45-1.006-.139-1.041-.1-1.18-.034-.45v-.452l.034-.936v-.9l.279-1.179.52-1.006.174-.452.485-.659v-.1l.521-.8.8-.8.868-.521.554-.381,1.249-.555.66-.173,1.11-.139.936-.174.486-.034.624.069,1.733.173,1.318.278,1.041.278,1.7.936,1.6,1.561.416.487.728,1.178.278.729v.486l.416,1.491v2.151l-.242,1.387-.035.486-.381.763-.45,1.423-.59.867v.139l-1.318,1.214-1.283.66-.381.068-.868.382-.867.242-.416.278-.521-.069-.971.243Zm-4.336-6.624v-.278h-.139l-.069.069v.208Zm.208-1.006-.068-.1h-.208l-.07.208.139.174Zm5.619,4.509.868-.208.381-.347v-.173l.59-.486.242-.868.139-.416.035-.416.208-1.769-.035-.971.035-1.422-.208-1.665-.243-1.041-.416-.485-.694-.9-.381-.208-.763-.243h-.416l-.416-.034-.694.242-.52.278-.59.728-.381.694v.937l-.243.867-.068,1.11-.069,1.561.138.763-.034,1.318.139.278.1.867.243.555.9,1.11.589.208.833.139Zm3.3-.278.173-.208.069-.555-.347-.139v-.139H106.1l-.278.452v.208l.278.381Z"
            transform="translate(-55.703 -135.806)"
            fill="#fff"
          />
          <path
            d="M66.137,162.352l-.8-.278h-.381l-.694-.208-.9-.382L62.53,161l-.278-.242-.555-.278-.589-.589-.624-.937-.45-.833-.624-1.63-.035-.762L59.3,155l-.139-1.041.174-2.393.139-.833.347-.971.208-.486L61,147.714l1.733-1.525,1.665-.833.763-.208,1.907-.348.347-.068.833.034.833-.1,1.595.208,1.457.486,1.145.52.831.555.971.9.624.97.521.764.381.832.416,2.117-.034,1.8v.347l.034.313-.312.276L67,154.687l-1.352-.14-.208.07-.1.278L65.513,156l.242.416.069.521.347.728.9.9.624.381,1.11.313,1.63-.1,1.18-.45.659-.486.59-.971.278-.833,1.664.035,1.457-.035.313.208v.243l-.278.9-.416.9-.8,1.109-1.526,1.457-1.283.624-.867.347-1.631.208-1.733.069ZM66.1,151.876l2.393-.068,2.185.034.279-.242-.14-1.909-.346-1.04-.174-.416-1.041-.486-.624-.035H68.01l-.589.173-.971.59-.347.45-.208.416-.173,1.526-.139.833.173.278Zm7.978.521v-.208l-.208-.069-.139.173.1.174Z"
            transform="translate(-59.165 -136.546)"
            fill="#fff"
          />
          <path
            d="M85.082,161.612l-1.457-.139-.589-.278-1.284-.069-1.595-1.318h-.174l-1.007-1.18-.589-.8-.312-.624-.486-1.041-.208-1.318-.139-1.144v-1.11l.173-1.873.279-.833.312-.9.589-1.11.589-.763.972-1.006,1.04-.868.9-.486,1.8-.659,1.457-.174.416-.069,1.872.035,1.318.347.971.382,1.18.589,1.457,1.11.486.831.312.624.139,1.007V150.1l-.347.66-.763.867-.589.382-.729.208-1.109-.035-.729-.208-.486-.312-.659-.381-.278-.348-.347-1.075.173-1.005.313-.486,1.075-.867v-.313l-.45-.208-1.11-.208-1.041-.069-1.007.347-.554.486-.347.381-.66,1.041-.312,1.18-.1,1.561-.07,1.491.208.66v.555l.208.971.1.8.659,1.075.521.59,1.075.416h1.8l.971-.59.208-.242.936-1.214.208-.729.208-.763.242-.173h2.706l.312.208v.486l-.139.278-.208,1.144-.554,1.11-.66,1.283-.936.937-1.7,1.075-1.457.486-1.11.208H85.844Zm3.434-9.227v-.278h.347l.416.208v.347h-.313Z"
            transform="translate(-57.333 -135.806)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    variant: String,
    animate: {
      type: Boolean,
      default: false
    },
    customWidth: Boolean
  }
}
</script>
<style lang="postcss" scoped>
.logo {
  width: 200px;
  @apply relative;
}

.customWidth {
  @apply w-full;
}

#fill {
  fill: var(--color);
}

.logo svg {
  @apply w-full;
}

.logo-simple {
  @apply relative;

  svg {
    @apply w-full h-full relative;
  }
}

.with-background {
  @apply z-0 absolute block w-20 h-20 rounded-full transform -translate-y-1/2 -translate-x-1/2;
  top: 50%;
  left: 50%;
  @apply bg-ecomarket-grey-100;
  background-size: 150px;
  content: ' ';
}

.form-1 {
  @apply relative z-20;
}

.form-2 {
  @apply relative z-10;
}

.form-2,
.form-3 {
  @apply transform absolute top-0 left-0 opacity-0 transition duration-100 ease-in-out;
  transform-origin: right center;
}

.animate {
  &:hover {
    .form-2,
    .form-3 {
      @apply opacity-100;
    }

    .form-2 {
      transform: rotate(-10deg);
    }

    .form-3 {
      transform: rotate(-20deg);
    }
  }
}
</style>
