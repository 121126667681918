<template>
  <component
    :is="link ? 'a' : 'button'"
    :type="type ? type : null"
    :href="link ? link : null"
    :class="
      `button 
      ${variant ? variant : ''}
      ${noSlot() || hideText ? 'icon-only' : ''}
      ${disabled ? 'disabled' : ''},
      ${noSlot() || hideText ? 'icon-only' : ''}
      ${disabled ? 'disabled' : ''}
      ${outline ? 'outline' : ''}
      ${secondary ? 'secondary' : ''}
      ${site === 'ecoshop' ? 'ecoshop' : 'ecomarket'}`
    "
    @click="click"
    :disabled="disabled"
    :style="color ? { 'background-color': `#${color}` } : ''"
  >
    <div class="loader z-20" v-if="loading" style="ccsVariables">
      <loader white></loader>
    </div>
    <div
      class="flex z-10 w-full"
      :class="`${reverseIcon ? 'reverse flex-row-reverse' : ''}`"
    >
      <i
        :class="
          `my-1 icon uil uil-${icon} ${
            noSlot() || hideText ? '' : 'icon--space'
          }`
        "
        v-if="icon"
      ></i>
      <div class="w-full text-center content">
        <div
          :class="{
            hide: hideText,
            'ml-2': icon && !reverseIcon,
            'mr-2': icon && reverseIcon,
            'flex justify-center items-center': imageIcon,
          }"
        >
            <img
              class="mx-2 image-icon"
            :src="imageIcon"
            v-if="imageIcon"
          />
          <slot name="text"></slot>
        </div>
        <slot></slot>
        <div v-if="content" :class="{ hide: hideText }">
          {{ $t(content) }}
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Loader from '@/components/Loader'
import { mapState } from 'vuex'

export default {
  props: {
    icon: String,
    link: String,
    type: String,
    variant: String,
    hideText: Boolean,
    loading: Boolean,
    prevent: Boolean,
    stop: Boolean,
    reverseIcon: Boolean,
    disabled: Boolean,
    color: String,
    content: {
      type: String,
      default: null
    },
    outline: Boolean,
    secondary: Boolean,
    imageIcon: String
  },
  computed: {
    ...mapState(['site'])
  },
  components: {
    Loader
  },
  methods: {
    noSlot() {
      return typeof this.$slots.default !== 'object'
    },
    click(e) {
      if (this.prevent) {
        e.preventDefault()
      }
      if (this.stop) {
        e.stopPropagation()
      }
      this.$emit('click')
    }
  }
}
</script>

<style lang="postcss" scoped>
.button {
  @apply bg-ecomarket-blue-300 inline-block px-5 h-12 text-white outline-none inline-flex items-center relative;
  @apply transition duration-100 ease-in-out;

  &.icon-only {
    @apply p-5;
  }

  &.no-padding {
    @apply p-0;
  }

  &.no-height {
    @apply h-auto;
  }
}

.content {
  white-space: nowrap;
}

.ecomarket.button {
  @apply bg-ecomarket-blue-300;
  &:hover {
    @apply bg-ecomarket-blue-400;
  }
}

.ecoshop.button {
  @apply inline-block px-5 h-12 text-white rounded-3xl outline-none inline-flex items-center relative;
  @apply transition duration-100 ease-in-out;
  @apply bg-red-ecoshop-400;

  &:hover {
    @apply transform scale-105;
  }
}

.ecoshop.button.outline {
  @apply bg-white border-2 border-solid border-red-ecoshop-400 text-red-ecoshop-400;
}

.disabled {
  @apply cursor-not-allowed opacity-60;
}

.small {
  @apply py-2 h-10;

  &.icon-only {
    @apply p-4;
  }
}

.button.no-background {
  @apply bg-transparent;

  .loader {
    @apply bg-transparent;
    path {
      @apply fill-current;
    }
  }

  &:hover {
    @apply bg-transparent;
  }
}

.button.ecomarket.no-background {
  @apply text-ecomarket-blue-300;

  &:hover {
    @apply text-ecomarket-blue-400;
  }
}

.button.ecoshop.no-background {
  @apply text-green-ecoshop-400;

  &:hover {
    @apply text-gray-700;
  }
}

.loader {
  @apply absolute inset-0 flex items-center justify-center bg-white bg-opacity-50;
}

.icon {
  @apply flex items-center;
}

.icon--space {
  @apply mr-2;
}

.reverse .icon--space {
  @apply ml-2;
}

.hide {
  @apply text-opacity-0 pointer-events-none absolute;
  top: -999em;
}

.button.ecomarket.transparent {
  @apply bg-transparent text-gray-400;

  &:hover {
    @apply bg-transparent text-ecomarket-blue-300;
  }
}

.button.ecoshop.transparent {
  @apply bg-transparent text-gray-400;

  &:hover {
    @apply bg-transparent text-red-ecoshop-400;
  }
}

.button.dark {
  @apply text-grey-700;

  &:hover {
    @apply text-ecomarket-blue-300;
  }
}

.grey-300 {
  @apply bg-grey-300 text-white;
}

.grey-200 {
  @apply bg-grey-200 text-white;
}

.grey-100 {
  @apply bg-grey-100 text-grey-500;

  &:hover {
    @apply bg-grey-200;
  }
}

.button.ecomarket.secondary {
  @apply text-ecomarket-blue-300 border-ecomarket-blue-300 border-2 border-solid;
  background-color: transparent;
}

.button.ecoshop.secondary {
  @apply text-red-ecoshop-400 border-red-ecoshop-400 border-2 border-solid;
  background-color: transparent;
}

.button.grey {
  @apply text-black bg-grey-200;
  &:hover {
    @apply bg-grey-300 text-black;
  }
}

.image-icon{
  height: 27px;
  width: auto;
}

.ecomarket.button.edenred{
  @apply bg-red-ecoshop-400;;
}
</style>
