<template>
  <transition name="fade" appear>
    <div class="popup" v-if="opened">
      <div class="popup__inner">
        <custom-button
          class="control__close"
          @click="close"
          variant="transparent dark"
          icon="multiply"
          hideText
          prevent
        >
          <template #text>
            {{ $t('btn-close') }}
          </template>
        </custom-button>
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import CustomButton from '@/components/Button'
import Escapable from '@/mixins/Escapable'

export default {
  components: {
    CustomButton
  },
  props: {
    rememberedUsername: String,
    redirect: String
  },
  data() {
    return {
      opened: false
    }
  },
  mixins: [Escapable],
  methods: {
    close() {
      this.opened = false
    },
    open() {
      this.opened = true
    },
    escapeHandler() {
      this.close()
    }
  }
}
</script>

<style lang="postcss" scoped>
.popup {
  @apply fixed inset-0 bg-grey-700 bg-opacity-75 z-50 flex justify-center items-center;
}

.popup__inner {
  @apply bg-white w-full p-10 rounded-md relative text-center;

  @screen md {
    @apply w-1/2;
  }

  @screen xl {
    @apply w-1/3;
  }
}

.popup .control__close {
  @apply absolute top-0 right-0 text-2xl m-3;
}

.title {
  @apply mb-12;
}

.bottom-actions {
  @apply text-grey-300;

  a {
    @apply text-ecomarket-blue-300;
  }
}
</style>
