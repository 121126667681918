<template>
  <validation-observer tag="form" ref="observer">
    <form
      @submit.prevent="handleSubmit"
      method="post"
      action=""
      ref="form"
      class="flex flex-col"
    >
      <span v-html="tokenInput"></span>
      <input type="hidden" name="action" value="contact-form/send" />
      <input type="hidden" name="subject" :value="subject" v-if="subject" />
      <custominput
        class="mb-8"
        type="text"
        vid="fromName"
        ref="fromName"
        placeholder="Votre nom"
        rules="required"
      ></custominput>
      <custominput
        class="mb-8"
        type="email"
        vid="fromEmail"
        ref="fromEmail"
        placeholder="email@site.com"
        rules="required"
      ></custominput>
      <custom-select
        id="subject"
        name="Sujet"
        vid="subject"
        ref="subject"
        class="mb-8"
        rules="required"
        value="question"
        v-if="general"
      >
        <option selected="selected" value="question">{{
          $t('p-contact-form-subject-question')
        }}</option>
        <option value="commande">{{
          $t('p-contact-form-subject-order')
        }}</option>
        <option value="autre">{{ $t('p-contact-form-subject-other') }}</option>
      </custom-select>
      <custom-textarea
        class="mb-8"
        vid="message"
        ref="message"
        placeholder="Votre message"
        rules="required"
      ></custom-textarea>
      <div class="self-end relative">
        <input
          type="text"
          name="surname"
          placeholder="surname"
          required
          ref="honeypot"
          class="absolute top-0 left-0 w-full"
        />
        <custombutton :loading="submitting">
          {{ $t('btn-contact-form-submit') }}
        </custombutton>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import axios from 'axios'
import { mapState } from 'vuex'
import Custombutton from '@/components/Button'
import Custominput from '@/components/Input'
import CustomTextarea from '@/components/Textarea'
import CustomSelect from '@/components/Select'

export default {
  components: {
    Custombutton,
    Custominput,
    ValidationObserver,
    CustomTextarea,
    CustomSelect
  },
  data() {
    return {
      submitting: false
    }
  },
  props: {
    general: Boolean,
    subject: {
      type: String,
      default: null
    }
  },
  computed: mapState(['tokenInput', 'url']),
  mounted() {
    setTimeout(() => {
      this.$refs.honeypot.required = false
    }, 3000)
  },
  methods: {
    async handleSubmit() {
      const valid = await this.$refs.observer.validate()

      if (valid && this.$refs.honeypot.value.length <= 0) {
        this.submitting = true
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(() => {
            this.$refs.fromName.reset()
            this.$refs.fromEmail.reset()
            if (this.general) {
              this.$refs.subject.reset()
            }
            this.$refs.message.reset()
            this.$toast.success(this.$t('message-sent'))
          })
          .catch(() => {
            this.$toast.error(this.$t('api-error'))
          })
          .finally(() => {
            this.submitting = false
          })
      }
    }
  }
}
</script>
