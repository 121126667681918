<template>
  <validation-provider
    :rules="rules"
    tag="div"
    class="input"
    ref="provider"
    :vid="vid"
    v-slot="{ errors }"
    :mode="mode"
  >
    <div>
      <div class="input__head flex justify-between" v-if="!hideName">
        <span class="mr-3">
          {{ $t(name) }}
        </span>
        <span v-if="errors" class="error">
          {{ errors[0] }}
        </span>
      </div>
      <div
        class="input__body"
        :class="{
          focus,
          'hide-border': hideBorder,
          error: errors[0],
          large
        }"
      >
        <span v-if="disabled">{{ value }}</span>
        <input
          class="placeholder-grey-300 outline-none"
          :type="computedType"
          :name="vid"
          :placeholder="$t(placeholder)"
          @focus="toggleFocus"
          @blur="onBlur"
          @input="change"
          v-model="content"
          v-else
        />
        <div v-if="icon">
          <i :class="`uil uil-${icon}`"></i>
        </div>
        <button v-if="search">
          <i :class="`uil uil-search`"></i>
        </button>
        <button
          v-if="type === 'password'"
          @click="passwordClick"
          class="focus:outline-none"
          @click.prevent=""
        >
          <i :class="`uil uil-eye-slash`" v-if="forcePasswordDisplay"></i>
          <i :class="`uil uil-eye`" v-else></i>
        </button>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'

export default {
  components: { ValidationProvider },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    name: {
      name: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Boolean],
      default: ''
    },
    hideName: {
      type: Boolean,
      default: false
    },
    hideBorder: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String
    },
    large: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    mode: {
      type: String,
      default: 'eager'
    }
  },
  data() {
    return {
      content: this.value,
      focus: false,
      forcePasswordDisplay: false
    }
  },
  computed: {
    computedType() {
      switch (this.type) {
        case 'password':
          if (this.forcePasswordDisplay) {
            return 'text'
          } else {
            return this.type
          }
        default:
          return this.type
      }
    },
    ...mapState(['site'])
  },
  methods: {
    onBlur(e) {
      this.$emit('blur', e)
      this.toggleFocus()
    },
    toggleFocus() {
      this.focus = !this.focus
    },
    reset() {
      this.content = ''
      this.$nextTick(() => {
        this.$refs.provider.reset()
      })
    },
    change() {
      this.$emit('change', this.content)
      this.$emit('input', this.content)
    },
    passwordClick() {
      this.forcePasswordDisplay = !this.forcePasswordDisplay
    }
  }
}
</script>

<style lang="postcss" scoped>
.input__head {
  @apply text-sm mb-1;
}

.ecomarket .error {
  @apply text-red-base;
}

.ecoshop .error {
  @apply text-red-ecoshop-400;
}

.input__body {
  @apply flex items-end border-solid border-b-2 border-grey-300 items-center text-lg;

  &.large {
    @apply h-12;
  }

  &.hide-border {
    @apply border-0;
  }

  &.focus {
    @apply border-grey-700;
  }

  &.error {
    @apply border-red-base;
  }
}

.ecoshop .input__body {
  &.error {
    @apply border-red-ecoshop-400;
  }
}

input {
  @apply w-full bg-transparent;
}
</style>
