<template>
  <div class="customer-new-address-button">
    <custombutton
      variant="small no-padding no-background"
      icon="plus"
      @click="openPopup"
      prevent
    >
      {{ $t('btn-address-new') }}
    </custombutton>
    <customer-address-popup
      v-if="displayPopup"
      @close="closePopup"
      @saved="addressChanged"
    ></customer-address-popup>
  </div>
</template>

<script>
import CustomerAddressPopup from '@/Customer/CustomerAddressPopup'
import Custombutton from '@/components/Button'
export default {
  props: {
    address: Object
  },
  components: {
    CustomerAddressPopup,
    Custombutton
  },
  data() {
    return {
      displayPopup: false
    }
  },
  methods: {
    openPopup() {
      this.displayPopup = true
    },
    closePopup() {
      this.displayPopup = false
    },
    addressChanged(address) {
      this.$emit('added', address)
    }
  }
}
</script>
