<template>
  <div class="grid grid-cols-3 gap-12">
    <div class="col-span-1">
      <custom-title :level="2" :style-of="3" class="mb-6">
        {{ $t('ttl-themes') }}
      </custom-title>
      <themes-list></themes-list>
    </div>
    <div class="col-span-1">
      <custom-title :level="2" :style-of="3" class="mb-6">
        {{ $t('ttl-categories') }}
      </custom-title>
      <categories-list></categories-list>
    </div>
    <div class="col-span-1">
      <custom-title :level="2" :style-of="3" class="mb-6">
        {{ $t('ttl-worths') }}
      </custom-title>
      <worths-list></worths-list>
    </div>
  </div>
</template>

<script>
import CustomTitle from '@/components/Title'
import CategoriesList from '@/components/CategoriesList'
import ThemesList from '@/components/ThemesList'
import WorthsList from '@/components/WorthsList'

export default {
  components: {
    CustomTitle,
    WorthsList,
    CategoriesList,
    ThemesList
  }
}
</script>
