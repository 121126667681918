<template>
  <a
    :href="url"
    class="supplier-link"
    :style="color ? { color: `#${color}` } : ''"
    :class="site"
    ><slot></slot
  ></a>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    url: String,
    color: String
  },
  computed: mapState(['site'])
}
</script>

<style lang="postcss" scoped>
.supplier-link {
  @apply transition duration-100 flex items-center;

  &:hover {
    @apply transform translate-x-1;
  }
}

.ecomarket.supplier-link {
  @apply text-ecomarket-orange-300;
}

.ecoshop.supplier-link {
  color: #040404;
}
</style>
