<template>
  <div
    class="payment-card"
    :class="`${selected ? 'selected' : ''} ${size ? size : ''}`"
    @click="$emit('click')"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    selected: Boolean,
    size: {
      type: String,
      default: null
    }
  },
  computed: {
    sizeClass() {
      return this.size
    }
  }
}
</script>

<style lang="postcss" scoped>
.payment-card {
  @apply w-48 box-border p-6 flex items-center transition duration-100 ease-in-out;

  &.selected {
    @apply bg-grey-100 rounded-md;
  }

  &.mini {
    @apply w-24 p-3;
  }

  img {
    @apply w-full;
  }
}
</style>
