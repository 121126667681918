<template>
  <div class="tab">
    <section v-show="$parent.activeTab === name" class="panel">
      <slot></slot>
    </section>
  </div>
</template>

<script>
export default {
  props: ['name'],
  data() {
    return {
      isActive: false
    }
  }
}
</script>

<style lang="postcss" scoped>
.tab {
  @apply flex-grow;
}

.panel {
  @apply bg-white rounded-b-md;
}
</style>
