<template>
  <validation-observer
    tag="div"
    ref="observer"
    accept-charset="UTF-8"
    class="login-popup"
    @submit.prevent="submit"
    @keydown.enter.prevent="submit"
  >
    <form method="post" accept-charset="UTF-8" ref="form">
      <span v-html="tokenInput"></span>
      <input type="hidden" name="action" value="users/save-user" />
      <input type="hidden" name="userId" :value="user.id" />

      <custominput
        vid="currentPassword"
        name="Mot de passe actuel*"
        class="md:w-2/5"
        rules="required"
        type="password"
        ref="password"
        required
      />

      <custominput
        vid="newPassword"
        name="Nouveau mot de passe"
        class="md:w-2/5 mt-6"
        type="password"
        ref="newPassword"
      />

      <custominput
        vid="email"
        name="Votre e-mail"
        class="md:w-2/5 mt-6"
        :value="user.email"
        type="email"
        ref="email"
      />

      <custombutton :loading="submitting" type="submit" class="mt-6">
        {{ $t('btn-apply') }}
      </custombutton>
    </form>
  </validation-observer>
</template>

<script>
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import { mapState } from 'vuex'
import { fetchUser, fetchTokenInput, fetchToken } from '@/api'
import Custominput from '@/components/Input'
import Custombutton from '@/components/Button'

export default {
  data() {
    return {
      submitting: false
    }
  },
  components: {
    Custominput,
    ValidationObserver,
    Custombutton
  },
  computed: mapState(['tokenInput', 'user', 'url']),
  methods: {
    async submit() {
      this.submitting = true
      const valid = await this.$refs.observer.validate()
      if (valid) {
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(async res => {
            if (res.data.errors) {
              this.$refs.observer.setErrors(res.data.errors)
            } else {
              await fetchUser()
              await fetchTokenInput()
              await fetchToken()
              this.$refs.password.reset()
              this.$refs.newPassword.reset()
              this.$toast.success('Information correctement changées !')
            }
          })
          .catch(() => {})
          .finally(() => {
            this.submitting = false
          })
      }
    },
    reset() {
      this.message = ''
      this.$nextTick(() => {
        this.fields.reset()
        this.errors.clear()
      })
    }
  }
}
</script>
