<template>
  <div class="customer-address-form">
    <div class="mb-6 flex">
      <custominput
        :vid="vid('lastName')"
        :value="address ? address.lastName : ''"
        id="lastName"
        :name="$t('name-custom-address-lastname')"
        :placeholder="$t('name-custom-address-lastname')"
        class="w-1/2 mr-6 obligatory"
        :rules="{required: true}"
        @blur="onChange"
      />
      <custominput
        :vid="vid('firstName')"
        :value="address ? address.firstName : ''"
        id="firstName"
        :name="$t('name-custom-address-firstname')"
        :placeholder="$t('name-custom-address-firstname')"
        class="w-1/2 obligatory"
        :rules="{required: true}"
        @blur="onChange"
      />
    </div>
    <div class="mb-6 flex">
      <custominput
        :vid="vid('addressLine1')"
        :value="address ? address.addressLine1 : ''"
        id="address1"
        :name="$t('name-custom-address-street')"
        :placeholder="$t('name-custom-address-street')"
        class="w-4/5 mr-6 obligatory"
        :rules="{required: true, regex: [/^[^\d]*$/, $t('error-message-address-line-1-no-number')]}"
        @blur="onChange"
      />
      <custominput
        :vid="vid('addressLine2')"
        :value="address ? address.addressLine2 : ''"
        id="address2"
        :name="$t('name-custom-address-number')"
        :placeholder="$t('name-custom-address-number')"
        class="w-1/5 obligatory"
        :rules="{required: true, regex: [/^[a-zA-Z\d\s]*$/, $t('error-message-address-line-2-no-special-chars')]}"
        @blur="onChange"
      />
    </div>
    <div class="mb-6 flex items-end">
      <custominput
        :vid="customFieldVid('addressAddress3')"
        :value="address ? address.addressAddress3 : ''"
        id="address3"
        :name="$t('name-custom-address-additionnal-infos')"
        :placeholder="$t('placeholder-custom-address-additionnal-infos')"
        class="w-2/5 mr-6"
        @blur="onChange"
      />
      <custominput
        :vid="customFieldVid('addressPhone')"
        :value="address ? address.addressPhone : ''"
        :name="$t('name-custom-address-phone')"
        :placeholder="$t('name-custom-address-phone-placeholder')"
        class="w-3/5 obligatory"
        rules="required"
        @blur="onChange"
      />
    </div>
    <div class="mb-6 flex items-end">
      <custominput
        :vid="vid('postalCode')"
        :value="address ? address.postalCode : ''"
        :name="$t('name-custom-address-postcode')"
        :placeholder="$t('name-custom-address-postcode')"
        class="w-1/5 mr-6 obligatory"
        rules="required"
        @blur="onChange"
      />
      <custominput
        :vid="vid('locality')"
        :value="address ? address.locality : ''"
        :name="$t('name-custom-address-city')"
        :placeholder="$t('name-custom-address-city')"
        class="w-3/5 obligatory"
        rules="required"
        @blur="onChange"
      />
      <custom-select
        id="countries"
        :name="$t('name-custom-address-country')"
        :vid="vid('countryCode')"
        class="w-2/5 ml-3 obligatory"
        rules="required"
        v-model="selectedCountryCode"
        @change="onChange"
      >
        <option :value="null" disabled>
          {{ $t('name-custom-address-country') }}
        </option>
        <option
          v-for="(country, index) in countries"
          :key="index"
          :value="index"
        >
          {{ country }}
        </option>
      </custom-select>
    </div>
    <div class="mb-6 flex items-end">
      <custominput
        :vid="vid('organization')"
        :value="address ? address.organization : ''"
        id="boite"
        :name="$t('name-custom-enterprise-name')"
        :placeholder="$t('name-custom-enterprise-name')"
        class="w-1/2 mr-6"
      />
      <custominput
        :vid="vid('organizationTaxId')"
        :value="address ? address.organizationTaxId : ''"
        id="boite"
        :name="$t('name-custom-address-vat')"
        placeholder="BE0123456789"
        class="w-1/2"
      />
    </div>
    <div class="mt-6">
      <checkbox
        vid="isPrimaryShipping"
        class="mt-3"
        :value="isPrimaryShipping"
        v-if="!billing"
      >
        {{ $t('lbl-customer-address-form-primary-shipping') }}
      </checkbox>
      <checkbox
        vid="isPrimaryBilling"
        class="mt-3"
        :value="isPrimaryBilling"
        v-if="!shipping"
      >
        {{ $t('lbl-customer-address-form-primary-billing') }}
      </checkbox>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Input from '@/components/Input'
import Checkbox from '@/components/Checkbox'
import CustomSelect from '@/components/Select'
export default {
  props: {
    address: {
      type: Object,
      default: null
    },
    model: {
      type: String,
      default: ''
    },
    primaries: {
      type: Boolean,
      default: false
    },
    shipping: {
      type: Boolean,
      default: false
    },
    billing: {
      type: Boolean,
      default: false
    }
  },
  components: {
    custominput: Input,
    Checkbox,
    CustomSelect
  },
  watch: {
    countries: function(val) {
      this.selectedCountryCode = val
    }
  },
  computed: {
    isPrimaryShipping() {
      if (this.address) {
        return (
          this.address.id ===
          (this.customer ? this.customer.primaryShippingAddress.id : null)
        )
      } else {
        return true
      }
    },
    isPrimaryBilling() {
      if (this.address) {
        return (
          this.address.id ===
          (this.customer ? this.customer.primaryBillingAddress.id : null)
        )
      } else {
        return false
      }
    },
    selectedCountryCode: {
      get: function() {
        return this.address ? this.address.countryCode : null
      },
      set: function(value) {
        this.$emit('countryChange', value)
      }
    },
    ...mapState(['countries', 'customer', 'tokenInput'])
  },
  methods: {
    vid(field) {
      const modelExists = this.model.length > 0
      if (modelExists) {
        return `${this.model}[${field}]`
      } else {
        return field
      }
    },
    customFieldVid(field) {
      const modelExists = this.model.length > 0
      if (modelExists) {
        return `${this.model}[fields][${field}]`
      } else {
        return `fields[${field}]`
      }
    },
    onChange(e) {
      this.$emit('change', e)
    }
  }
}
</script>
