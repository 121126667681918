<template>
  <transition name="fade" appear>
    <div class="side-basket" v-if="isSideBasketActive" @click="closeSideBasket">
      <div class="side-basket__inner" @click.stop="">
        <div class="side-basket__controls">
          <custombutton
            class="side-basket__controls__close"
            @click="closeSideBasket"
            variant="transparent dark"
            icon="multiply"
            hideText
          >
            <template #text>
              {{ $t('btn-basket-close') }}
            </template>
          </custombutton>
        </div>
        <div class="side-basket__content">
          <div v-if="cart.totalQty < 1">{{ $t('p-empty-basket') }}</div>
          <cart v-else @initial-cart-data-ready="onInitialCartDataReady">
            <template v-slot:header>
              <customtitle :level="2" :style-of="4">
                {{ $t('ttl-basket') }}
              </customtitle>
            </template>
            <template v-slot:footer>
              <custombutton icon="arrow-right" :link="`${url}checkout`">
                {{ $t('ttl-basket-order') }}
              </custombutton>
            </template>
          </cart>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import { fetchCart } from '@/api'
import Button from '@/components/Button'
import Title from '@/components/Title'
import Escapable from '@/mixins/Escapable'
import Cart from '@/components/Cart'
import { addToDataLayer, formatGAPriceWithoutCurrency } from '@/utils'

export default {
  components: {
    custombutton: Button,
    customtitle: Title,
    Cart
  },
  computed: mapState([
    'cart',
    'tokenName',
    'token',
    'isSideBasketActive',
    'url'
  ]),
  mixins: [Escapable],
  watch: {
    isSideBasketActive: function(val) {
      this.escapableVisible = val
    }
  },
  mounted() {
    fetchCart()
  },
  methods: {
    closeSideBasket: function() {
      return store.commit('closeSideBasket')
    },
    escapeHandler() {
      this.closeSideBasket()
    },
    onInitialCartDataReady(cartProducts) {
      if(this.cart) {
        const items = [];
        this.cart.lineItems.forEach((lineItem) => {
          const product = cartProducts.find(item => item.id === lineItem.snapshot.id)
          items.push({
            item_id: lineItem.purchasableId,
            item_name: lineItem.description,
            item_category: product.categoryTitle,
            item_brand: product.supplierTitle,
            price: formatGAPriceWithoutCurrency(lineItem.salePriceAsCurrency),
            quantity: lineItem.qty
          })
        })
        addToDataLayer({
          event: 'view_cart',
          currency: this.cart.currency,
          value: formatGAPriceWithoutCurrency(this.cart.totalPriceAsCurrency),
          items
        })
      }
      
    }
  }
}
</script>

<style lang="postcss" scoped>
.side-basket {
  @apply bg-grey-700 fixed inset-0 bg-opacity-75 flex;
  z-index: 2147483002;
  height: 100%;
}

.side-basket__inner {
  @apply bg-white ml-auto w-full flex flex-col h-full;

  @screen lg {
    @apply w-1/2;
  }

  @screen xl {
    @apply w-1/3;
  }
}

.side-basket__controls {
  @apply flex relative;
}

.side-basket .side-basket__controls__close {
  @apply absolute top-0 right-0 text-2xl mt-2;
}

.side-basket__content {
  @apply p-6 pt-4 flex flex-col h-full;
}

.side-basket__redirects {
  @apply m-6 mt-auto flex justify-end;
  flex-shrink: 0;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition duration-200 ease-in-out;

  .side-basket__inner {
    @apply transition duration-200 ease-in-out;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;

  .side-basket__inner {
    transform: translateX(100%);
  }
}
</style>
