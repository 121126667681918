import Vue from 'vue'
import store from './store'

import 'swiper/swiper-bundle.css'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import '@iconscout/unicons/css/line.css'
import '@/assets/styles/index.postcss'

import "vanilla-cookieconsent/dist/cookieconsent.css";
import '@/assets/styles/cookie-consent.postcss'

import VueI18n from 'vue-i18n'
import fr from '@/locales/fr'
import nl from '@/locales/nl'

import '@/validation'

import VueLazyload from 'vue-lazyload'

import CraftData from '@/components/CraftData'
import Wrapper from '@/components/Wrapper'
import Slider from '@/components/Slider'
import Title from '@/components/Title'
import Input from '@/components/Input'
import Custombutton from '@/components/Button'
import SideBasket from '@/components/SideBasket'
import ProductThumbnail from '@/components/ProductThumbnail'
import GridProducts from '@/components/GridProducts'
import ContentBlock from '@/components/ContentBlock'
import MainFooter from '@/components/MainFooter'
import SearchCategories from '@/components/SearchCategories'
import Slideshow from '@/components/Slideshow'
import Checkout from '@/components/Checkout'
import CheckoutInformations from '@/components/CheckoutInformations'
import Checkbox from '@/components/Checkbox'
import CustomerLoginPopup from '@/Customer/CustomerLoginPopup'
import MainHeaderMobile from '@/components/MainHeaderMobile'
import CustomerInnerPage from '@/Customer/CustomerInnerPage'
import CustomerSideMenu from '@/Customer/CustomerSideMenu'
import OrderCard from '@/Customer/OrderCard'
import OrdersContainer from '@/Customer/OrdersContainer'
import Tabs from '@/components/Tabs'
import Tab from '@/components/Tab'
import AddToCartButton from '@/components/AddToCartButton'
import CustomerAddresses from '@/Customer/CustomerAddresses'
import ChangeInfos from '@/Customer/ChangeInfos'
import Search from '@/Search/Search'
import Price from '@/components/Price'
import SupplierLink from '@/components/SupplierLink'
import ProductVariants from '@/Product/ProductVariants'
import ContactForm from '@/components/ContactForm'
import Logo from '@/components/Logo'
import CategoriesAndWorths from '@/components/CategoriesAndWorths'
import ThemesList from '@/components/ThemesList'
import WorthsList from '@/components/WorthsList'
import WorthPill from '@/components/WorthPill'
import CategoriesList from '@/components/CategoriesList'
import Breadcrumbs from '@/components/Breadcrumbs'
import BreadcrumbsItem from '@/components/BreadcrumbsItem'
import StockNotice from '@/components/StockNotice'
import CornerPopup from '@/components/CornerPopup'
import NewsletterSubscribe from '@/components/NewsletterSubscribe'
import UserAccount from '@/components/UserAccount'
import HeaderBasket from '@/components/HeaderBasket'
import MinimarketsMenu from '@/components/MinimarketsMenu'
import EnterpriseGiftsMenu from '@/components/EnterpriseGiftsMenu'
import CategoryDot from '@/components/CategoryDot'
import FixedHeader from 'vue-fixed-header'
import PaymentCard from '@/components/PaymentCard'
import ImageLazyload from '@/components/ImageLazyload'
import DataLayer from '@/components/DataLayer'

// Ecoshop
import EcoshopBackgroundSlider from '@/ecoshop/components/EcoshopBackgroundSlider'
import EcoshopLogo from '@/ecoshop/components/EcoshopLogo'

Vue.use(VueI18n)

Vue.use(VueToast, {
  position: 'top'
})

Vue.use(VueLazyload, {
  lazyComponent: true
})

new Vue({
  store,
  i18n: new VueI18n({
    locale: 'fr',
    messages: {
      fr,
      nl
    }
  }),
  components: {
    CraftData,
    MainHeaderMobile,
    Wrapper,
    Slider,
    customtitle: Title,
    custominput: Input,
    Custombutton,
    SideBasket,
    ProductThumbnail,
    GridProducts,
    ContentBlock,
    MainFooter,
    SearchCategories,
    Slideshow,
    Checkout,
    CheckoutInformations,
    Checkbox,
    CustomerLoginPopup,
    CustomerInnerPage,
    CustomerSideMenu,
    OrderCard,
    OrdersContainer,
    Tabs,
    Tab,
    AddToCartButton,
    CustomerAddresses,
    ChangeInfos,
    Search,
    Price,
    SupplierLink,
    ProductVariants,
    ContactForm,
    Logo,
    CategoriesAndWorths,
    ThemesList,
    WorthsList,
    WorthPill,
    CategoriesList,
    Breadcrumbs,
    BreadcrumbsItem,
    StockNotice,
    CornerPopup,
    NewsletterSubscribe,
    UserAccount,
    HeaderBasket,
    MinimarketsMenu,
    EnterpriseGiftsMenu,
    CategoryDot,
    FixedHeader,
    EcoshopBackgroundSlider,
    EcoshopLogo,
    PaymentCard,
    ImageLazyload,
    DataLayer
  }
}).$mount('#app')
