<template>
  <section>
    <custom-title :level="2" :style-of="3">
      {{ $t('ttl-enterprise-gifts') }}
    </custom-title>
    <div class="grid grid-cols-4 gap-6 mt-12">
      <div v-for="(image, index) in entry.thumbnailImages" :key="index">
        <div class="w-full h-36 rounded-md overflow-hidden mr-6 flex-shrink-0">
          <img
            :src="image"
            alt="image's title"
            class="transition duration-500 ease-in-out w-full"
          />
        </div>
      </div>
    </div>
    <div class="mt-6" v-html="entry.intro"></div>
    <div class="mt-6">
      <custom-button :link="entry.url">{{ $t('btn-know-more') }}</custom-button>
    </div>
  </section>
</template>

<script>
import { fetchEnterpriseGifts } from '@/api'
import CustomTitle from '@/components/Title'
import CustomButton from '@/components/Button'

export default {
  components: {
    CustomTitle,
    CustomButton
  },
  data() {
    return {
      entry: {
        intro: ''
      }
    }
  },
  async created() {
    const result = await fetchEnterpriseGifts()
    this.entry = await result.data
  }
}
</script>
