<template>
  <div v-click-outside="hide" class="dropdown">
    <div @click="onClick" class="dropdown-trigger">
      <slot></slot>
    </div>
    <div class="dropdown-menu">
      <ul class="dropdown-menu__inner" :class="{ opened }">
        <li v-for="(item, index) in items" :key="index">
          <component
            :is="item.link ? 'a' : 'span'"
            :href="item.link"
            @click.stop="callAction(item.action)"
            class="dropdown-item"
          >
            {{ item.name }}
          </component>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
export default {
  directives: {
    ClickOutside
  },
  props: {
    items: Array
  },
  data() {
    return {
      opened: false
    }
  },
  methods: {
    hide() {
      this.opened = false
    },
    callAction(action) {
      if (action) {
        action()
      }
    },
    onClick() {
      this.opened = !this.opened
    }
  }
}
</script>

<style lang="postcss">
.dropdown-trigger,
.dropdown-item {
  @apply cursor-pointer;
}

.dropdown-trigger {
  @apply flex items-center;
}

.dropdown-menu {
  @apply relative;
}

.dropdown-menu__inner {
  @apply absolute top-0 mt-4 right-0 bg-white w-64 normal-case rounded-md text-base py-3 opacity-0 pointer-events-none shadow-thumbnail;
  @apply transition duration-200 ease-in-out transform -translate-y-2;
  @apply text-gray-800;

  &.opened {
    @apply opacity-100 pointer-events-auto translate-y-0;
  }
}

.dropdown-item {
  @apply px-6 py-3 inline-block;

  &:hover {
    @apply text-gray-600;
  }
}

.ecomarket {
  .dropdown-item {
    &:hover {
      @apply text-ecomarket-blue-300;
    }
  }
}
</style>
