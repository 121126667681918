<template>
  <section class="content-block" :class="[site, inverted ? 'inverted' : '']">
    <!-- two columns -->
    <div
      class="content-block__items content-block"
      v-if="twoColumns"
      :class="{
        simplebackground
      }"
    >
      <div class="content-block__items-firest-column">
        <div class="w-full h-full">
          <slot name="first-column"></slot>
        </div>
      </div>
      <div class="content-block__items-secound-column">
        <div class="w-full h-full">
          <slot name="second-column"></slot>
        </div>
      </div>
    </div>
    <div v-else :class="{ 'text-two-columns': textTwoColumns }">
      <slot name="title"></slot>
      <slot name="body"></slot>
    </div>
    <EcoshopBackgroundSlider
      class="ecoshop-background"
      v-if="site === 'ecoshop' && this.simplebackground"
    />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import EcoshopBackgroundSlider from '@/ecoshop/components/EcoshopBackgroundSlider'

export default {
  props: {
    twoColumns: {
      type: Boolean,
      default: false
    },
    simplebackground: {
      type: Boolean,
      default: false
    },
    textTwoColumns: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    }
  },
  computed: mapState(['site']),
  components: {
    EcoshopBackgroundSlider
  }
}
</script>

<style lang="postcss">
.content-block__items-firest-column {
  @apply flex items-center;
}
content-block__items-secound-column {
  @apply flex items-center;
}
.content-block__items {
  @screen md {
    @apply grid grid-cols-2 gap-12;
  }
}
.text-two-columns {
  @screen xl {
    @apply w-full mx-auto;
    column-count: 2;
  }
}
.ecomarket .content-block__items-firest-column {
  @apply bg-white;
}
.ecomarket .content-block__items-secound-column {
  @apply bg-white;
}
.ecomarket .content-block {
  @apply p-1;
  @screen md {
    @apply p-0;
  }
}
.ecomarket .content-block__items {
  @apply p-0;
}
.ecomarket .simplebackground {
  @apply bg-white rounded-xl;

  @screen md {
    @apply px-10 py-10;
  }
}

.ecoshop .content-block img {
  clip-path: inset(5% 0 5% 0 round 99999px);
}

.ecoshop .content-block {
  @apply p-0;
  position: relative;
}
.ecoshop .content-block__items {
  @apply py-8;
}
.ecoshop .ecoshop-background {
  position: absolute;
  top: 0;
  margin-left: -100px;
  z-index: -10;
  @screen md {
    margin-left: 50%;
  }
  @screen lg {
    top: -100px;
    margin-left: 351px;
    z-index: -1;
  }
}

.ecoshop.inverted .ecoshop-background {
  margin-right: -100px;
  margin-left: 0;
  right: 0;

  @screen md {
    margin-left: 0;
    margin-right: 50%;
  }
  @screen lg {
    margin-right: 500px;
    margin-left: 0;
  }
}
</style>
