import { render, staticRenderFns } from "./SearchCategories.vue?vue&type=template&id=5cb9df49&scoped=true&"
var script = {}
import style0 from "./SearchCategories.vue?vue&type=style&index=0&id=5cb9df49&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb9df49",
  null
  
)

export default component.exports