<template>
  <div
    class="header-basket flex items-center cursor-pointer"
    :class="site"
    @click="activateSideBasket"
  >
    <button class="basket">
      <i class="text-xl lg:text-md uil uil-shopping-bag"></i>
      <span class="hidden lg:contents">{{ $t('ttl-header-basket') }}</span>
    </button>
    <div class="amount">
      <price :amount="cart.totalPriceAsCurrency"></price>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
import Price from '@/components/Price'

export default {
  components: {
    Price
  },
  computed: {
    ...mapState(['cart', 'site'])
  },
  methods: {
    activateSideBasket() {
      store.commit('openSideBasket')
    }
  }
}
</script>

<style lang="postcss" scoped>
.header-basket.ecomarket {
  @apply rounded-full;
  @apply px-6;
  @apply bg-ecomarket-orange-300;
  @apply text-white;
}

.header-basket.ecoshop {
  @apply rounded-full;
  @apply bg-red-ecoshop-400;
  @apply px-6;
  @apply text-white;
}

.basket {
  @apply flex justify-center;
  @apply text-ecomarket-blue-300 flex items-center;
  @apply transition duration-100 ease-in-out;
  height: 50px;

  & i {
    @apply mr-2;
  }

  &:focus {
    @apply outline-none;
  }
}

.ecomarket .basket {
  @apply text-white;
}
.ecoshop .basket {
  @apply text-white;
}

.amount {
  @screen lg {
    @apply ml-3;
  }
}
</style>
