<template>
  <div :class="site" class="relative inline-block">
    <select name="variants" class="select" @change="change">
      <option
        :value="variant.url"
        :selected="currentVariantId === variant.id"
        v-for="(variant, i) in variants"
        :key="i"
      >
        {{ variant.title }}
      </option>
    </select>
    <div class="container-select__arrow">
      <i class="my-1 icon uil uil-angle-down"></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    variants: Array,
    currentVariantId: Number
  },
  computed: {
    ...mapState(['site'])
  },
  methods: {
    change(e) {
      window.location = e.target.value
    }
  }
}
</script>

<style lang="postcss" scoped>
.select {
  @apply appearance-none pl-5 h-12 bg-ecomarket-blue-300 pr-10 cursor-pointer w-full;
  color: #727272;
  background-color: transparent;

  &:focus {
    @apply outline-none;
  }
}

.container-select__arrow {
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-2xl;
  color: #727272;
}

.ecoshop .select {
  border-radius: 60px 60px;
  border: 1px solid #d5d7dd;
}
.ecomarket .select {
  border: 1px solid var(--color);
}
</style>
