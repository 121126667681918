<template>
  <transition name="fade" appear>
    <div class="customer-address-popup">
      <div class="customer-address-popup__inner">
        <custom-button
          class="control__close text-2xl"
          @click="$emit('close')"
          variant="transparent dark"
          icon="multiply"
          hideText
          prevent
          stop
        >
          <template #text>
            {{ $t('btn-close') }}
          </template>
        </custom-button>
        <validation-observer tag="div" ref="observer">
          <form ref="form" method="post" @submit.prevent="submit">
            <span v-html="tokenInput"></span>
            <input type="hidden" name="action" value="users/save-address" />
            <span v-if="address">
              <input type="hidden" name="addressId" :value="address.id" />
            </span>
            <input type="hidden" name="userId" :value="this.user.id" />
            <input type="hidden" name="title" value="test" />
            <customer-address-form
              :address="address"
              class="mt-3"
              primaries
            ></customer-address-form>

            <div class="mt-6">
              <custom-button :loading="submitting">
                {{ $t('btn-save') }}
              </custom-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { fetchAddresses, fetchCustomer } from '@/api'
import CustomerAddressForm from '@/Customer/CustomerAddressForm'
import CustomButton from '@/components/Button'
import Escapable from '@/mixins/Escapable'
export default {
  props: {
    address: Object
  },
  components: {
    ValidationObserver,
    CustomerAddressForm,
    CustomButton
  },
  data() {
    return {
      submitting: false,
      escapableVisible: true
    }
  },
  computed: mapState(['tokenInput', 'url', 'user']),
  mixins: [Escapable],
  methods: {
    escapeHandler() {
      this.$emit('close')
    },
    async submit() {
      const valid = await this.$refs.observer.validate()

      if (valid) {
        this.submitting = true
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(({ data }) => {
            fetchCustomer()
            fetchAddresses()
            this.$emit('close')
            this.$emit('saved', data.model)
          })
          .catch(() => {})
          .finally(() => {
            this.submitting = false
          })
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.customer-address-popup {
  @apply fixed inset-0 bg-grey-700 bg-opacity-75 z-50 flex justify-center items-center;
}

.customer-address-popup__inner {
  @apply bg-white w-full p-10 rounded-sm relative;

  @screen md {
    @apply w-1/2;
  }

  @screen xxl {
    @apply w-1/3;
  }
}

.customer-address-popup .control__close {
  @apply absolute top-0 right-0;
}
</style>
