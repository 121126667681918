<template>
  <div class="slideshow" :class="site">
    <div class="slider swiper-container swiper-container-images">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(slide, index) in slides" :key="index">
          <img class="swiper-slide__image" :src="slide.url" />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swiper, { Pagination, EffectFade } from 'swiper'

Swiper.use([Pagination, EffectFade])

export default {
  props: {
    slides: {
      type: Array,
      default: () => {
        return {}
      }
    }
  },
  computed: mapState(['site']),
  mounted() {
    new Swiper('.swiper-container', {
      loop: true,
      effect: 'fade',
      touchEventsTarget: 'wrapper',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, classname) => {
          return `<div class='${classname}'><img src="${this.slides[index].url}" /></div>`
        }
      }
    })
  }
}
</script>

<style lang="postcss">
.slideshow {
  .slider {
    background: none;
    &.swiper-container-images {
      @apply w-full relative pb-20;
      padding-bottom: 0px;
      height: initial;
      @screen md {
        height: initial;
      }
    }

    &.swiper-container-images .swiper-slide {
      @apply bg-transparent rounded-md items-center m-0 block justify-evenly flex overflow-hidden;
      border-radius: 4%;
      @screen md {
        @apply flex;
      }
    }

    &.swiper-container-images .swiper-slide__image {
      @apply w-full h-auto m-0;
    }

    &.swiper-container-images .swiper-pagination.swiper-pagination-bullets {
      @apply text-center items-center p-0 m-0 grid grid-cols-4 gap-6 place-items-center mt-3;
      position: initial;
      @screen sm {
        @apply grid-cols-4;
      }
    }

    &.swiper-container-images.swiper-container .swiper-pagination-bullet {
      @apply bg-transparent m-0 p-0 rounded-md text-center flex items-center h-32 w-full overflow-hidden justify-center;
    }

    &.swiper-container-images.swiper-container .swiper-pagination-bullet img {
      height: auto;
      width: 130px;
      @apply bg-transparent transform m-0 p-0 block opacity-100  rounded-none !important;
    }

    &.slider.swiper-container::before {
      background: none;
    }
  }
}

.ecoshop .slideshow {
  .slider {
    &.swiper-container-images .swiper-slide {
      border: 1px solid #d5d7dd;
    }
    &.swiper-container-images.swiper-container .swiper-pagination-bullet img {
      border-radius: 5% !important;
      border: 1px solid #d5d7dd;
    }
  }
}
</style>
