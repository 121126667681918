<template>
  <lazy-component class="container-lazyload" @show="handler">
    <img
      v-if="(loading = true)"
      class="w-full h-auto mini-cover"
      :src="urlLazyload"
      :alt="altLazyload"
      :style="absoluteFullwidth"
    />
    <loader v-else></loader>
  </lazy-component>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  data() {
    return {
      loading: true
    }
  },
  props: {
    urlLazyload: String,
    altLazyload: String,
    absoluteFullwidth: String
  },
  components: {
    Loader
  },
  methods: {
    handler() {
      this.loading = false
    }
  }
}
</script>
<style scoped>
.container-lazyload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
