<template>
  <div class="cart">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <ul class="cart-list">
      <li
        v-for="(item, index) in cart.lineItems"
        :key="index"
        class="cart-item"
      >
        <a :href="getProduct(item.snapshot.id).url" class="thumbnail">
          <img
            :src="
              getProduct(item.snapshot.id).thumbnail ||
                '/static-assets/images/image-placeholder.jpg'
            "
            alt=""
            class="rounded-sm"
          />
        </a>
        <div class="description">
          <a :href="getProduct(item.snapshot.id).url" class="title">
            <custom-title :level="3" :style-of="6">
              {{ getProduct(item.snapshot.id).title }}
              <span
                v-if="
                  getProduct(item.snapshot.id).variantTitle &&
                    getProduct(item.snapshot.id).variantTitle !==
                      getProduct(item.snapshot.id).title
                "
                class="text-grey-300"
              >
                - {{ getProduct(item.snapshot.id).variantTitle }}
              </span>
            </custom-title>
          </a>
          <div class="flex items-end mt-3">
            <div>
              <div class="flex items-center">
                <div v-if="item.snapshot.hasUnlimitedStock == 1">
                  <cart-item-quantity
                    class="mr-2"
                    :id="item.id"
                    :original-quantity="item.qty"
                    :title="getProduct(item.snapshot.id).title"
                    :price="getProduct(item.snapshot.id).salePrice"
                    :supplier-title="getProduct(item.snapshot.id).supplierTitle"
                    :category-title="getProduct(item.snapshot.id).categoryTitle"
                    :purchasable-id="item.purchasableId"
                  ></cart-item-quantity>
                </div>
                <div v-else>
                  <cart-item-quantity
                    class="mr-3"
                    :id="item.id"
                    :max="getProduct(item.snapshot.id).stock"
                    :original-quantity="item.qty"
                    :title="getProduct(item.snapshot.id).title"
                    :price="getProduct(item.snapshot.id).salePrice"
                    :supplier-title="getProduct(item.snapshot.id).supplierTitle"
                    :category-title="getProduct(item.snapshot.id).categoryTitle"
                    :purchasable-id="item.purchasableId"
                  ></cart-item-quantity>
                </div>
                <delete-cart-item 
                  :id="item.id" 
                  :title="getProduct(item.snapshot.id).title"
                  :price="getProduct(item.snapshot.id).salePrice"
                  :supplier-title="getProduct(item.snapshot.id).supplierTitle"
                  :category-title="getProduct(item.snapshot.id).categoryTitle"
                  :quantity="item.qty"
                  :purchasable-id="item.purchasableId"
                ></delete-cart-item>
              </div>
              <div>
                <stock-notice
                  class="mt-1"
                  :stock="getProduct(item.snapshot.id).stock"
                  v-if="
                    getProduct(item.snapshot.id).stock <= 5 &&
                      item.snapshot.hasUnlimitedStock != 1
                  "
                />
                <span
                  class="text-sm mt-1 w-full text-grey-300"
                  v-if="
                    getProduct(item.snapshot.id).shippingCategory ===
                      'exclusiveShippingBelgium'
                  "
                >
                  <i class="icon uil uil-exclamation-triangle mr-1"></i>
                  {{ $t('product-shipping-in-belgium') }}
                </span>
              </div>
            </div>
            <div class="flex flex-col items-between mt-3 items-end ml-auto">
              <div
                class="inline-flex ml-2 items-center"
                v-for="(adjustment, i) in item.adjustments.filter(
                  item => item.type !== 'tax'
                )"
                :key="i"
              >
                {{ adjustment.amountAsCurrency }}
                <span class="ml-1">({{ adjustment.name }})</span>
              </div>
              <div class="ml-2">
                <span class="text-grey-200 mr-2">{{ $t('ttl-total') }}</span>
                {{ item.totalAsCurrency }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="upsales">
      <div class="upsale">
        <div class="upsale-title">
          <custom-title :level="3" :style-of="6" class="title">
            {{ $t('ttl-you-may-also-like') }}
          </custom-title>
        </div>
        <div class="upsale-products">
          <div
            class="flex bg-grey-50 p-3 rounded-sm mt-3"
            v-for="(product, i) in upsales"
            :key="i"
          >
            <a :href="product.url" class="w-2/12 pr-2">
              <img
                :src="
                  product.image || '/static-assets/images/image-placeholder.jpg'
                "
                alt="image representing the product"
                class="rounded-sm"
              />
            </a>
            <div class="7/12 pr-2 flex flex-col space-between">
              <div class="upsale-product-title">
                <a :href="product.url">{{ product.title }}</a>
              </div>
              <div class="upsale-product-price mt-1">
                <price :amount="product.defaultPriceAsCurrency"></price>
              </div>
            </div>
            <div class="3/12 ml-auto">
              <add-to-cart-button
                :age-notice="product.ageNotice"
                :id="product.id"
                compact
                :title="product.title"
                :supplier-title="product.supplierTitle"
                :category-title="product.categoryTitle"
                :price="product.defaultPriceAsCurrency"
              ></add-to-cart-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="totals">
      <table>
        <tbody>
          <tr class="text-grey-300" v-for="(discount, i) in discounts" :key="i">
            <td>{{ $t('ttl-gift-card') }}</td>
            <td>
              <price
                :amount="discount.amountAsCurrency"
                variant="small"
              ></price>
              <form method="POST" @submit.prevent="deleteField" id="field">
                <input
                  type="hidden"
                  name="action"
                  value="gift-voucher/cart/remove-code"
                />
                <input
                  type="hidden"
                  name="voucherCode"
                  width="11"
                  :value="discount.sourceSnapshot.codeKey"
                />
                <span v-html="tokenInput"></span>

                <custombutton
                  type="submit"
                  variant="no-background no-padding"
                  icon="trash-alt"
                  :hideText="hideText"
                  style="margin-left: 5px"
                >
                </custombutton>
              </form>
            </td>
          </tr>
          <tr class="text-grey-300">
            <td>{{ $t('ttl-delivery') }}</td>
            <td>
              <price
                :amount="cart.totalShippingCostAsCurrency"
                variant="small"
              ></price>
            </td>
          </tr>
          <tr class="font-bold">
            <td class="text-md font-bold leading-tight">
              {{ $t('ttl-total') }}
            </td>
            <td>
              <price :amount="cart.totalPriceAsCurrency"></price>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DeleteCartItem from '@/components/DeleteCartItem'
import CartItemQuantity from '@/components/CartItemQuantity'
import axios from 'axios'
import Price from '@/components/Price'
import StockNotice from '@/components/StockNotice'
import store from '@/store'
import Custombutton from '@/components/Button'
import AddToCartButton from '@/components/AddToCartButton'
import CustomTitle from '@/components/Title'

export default {
  components: {
    DeleteCartItem,
    CartItemQuantity,
    Price,
    StockNotice,
    Custombutton,
    AddToCartButton,
    CustomTitle
  },
  props: {
    id: Number
  },
  computed: {
    discounts() {
      return this.cart.orderAdjustments.filter(item => item.type === 'voucher')
    },
    ...mapState(['cart', 'tokenInput', 'site'])
  },
  watch: {
    cart(newValue) {
      this.fetchCartProducts(newValue.lineItems)
    }
  },
  data() {
    return {
      cartProducts: [],
      upsales: []
    }
  },
  created() {
    this.fetchCartProducts(this.cart.lineItems, true)
  },
  methods: {
    fetchCartProducts(items, init=false) {
      axios
        .get('/api/products', {
          params: {
            id: items.map(item => item.snapshot.id),
            orderSiteId: this.cart.orderSiteId
          }
        })
        .then(res => {
          this.cartProducts = res.data.data
          this.$emit('cart-products-ready', this.cartProducts)
          if(init) this.$emit('initial-cart-data-ready', this.cartProducts)
          this.getUpsales()
        })
        .catch(err => console.log('error', err))
    },
    getProduct(id) {
      const product = this.cartProducts.find(item => item.id === id)
      if (product) {
        return product
      } else {
        return ''
      }
    },
    getUpsales() {
      // get 3 productSelection contained in this.cartProducts
      this.upsales = []
      this.cartProducts.forEach(product => {
        if (this.upsales.length >= 2) {
          return
        }
        // loop over productSelection, stop when there is finally a different product
        for (let i = 0; i < product.productSelection.length; i++) {
          const upsale = product.productSelection[i]
          if (upsale.id !== product.id) {
            // if id is the same, don't push, same if it has no stock (upsales.hasStock)
            if (
              this.cartProducts.find(item => item.id === upsale.id) ||
              this.upsales.find(item => item.id === upsale.id) ||
              !upsale.hasStock
            ) {
              return
            }
            this.upsales.push(upsale)
            break
          }
        }
      })
    },
    deleteField() {
      var data = new FormData(document.getElementById('field'))
      axios
        .post(`${this.url}/`, data, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
        .then(res => {
          store.commit('updateCart', res.data.cart)
        })
        .catch(() => {})
        .finally(() => {})
    }
  }
}
</script>

<style lang="postcss" scoped>
.cart {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cart-list {
  @apply border-b border-solid border-grey-200;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
}

.cart-item {
  @apply mb-3;
  @apply flex;
}

.thumbnail {
  @apply w-1/6 bg-white rounded-md mr-3;

  img {
    @apply w-full;
  }
}

.description {
  @apply w-5/6 flex flex-col;
}

.title {
  @apply transition duration-100 ease-in-out;
}

ul .title {
  &:hover {
    @apply text-grey-300;
  }
}

.totals {
  @apply mt-auto w-full pt-3;
  flex-shrink: 0;

  table {
    @apply table-auto w-full;
  }

  tbody {
    @apply w-full;
  }

  tr {
    @apply pb-1 flex w-full justify-between pb-2;
  }

  td {
    @apply flex items-center;
  }
}

.upsales {
  @apply mt-3;
}

.header {
  @apply mb-6;
  flex-shrink: 0;
}

.footer {
  @apply mt-3;
  flex-shrink: 0;
}

.totals tr:last-child {
  padding-bottom: 0;
}
</style>
