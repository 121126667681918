var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"provider",staticClass:"input",attrs:{"rules":_vm.rules,"tag":"div","vid":_vm.vid,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[(!_vm.hideName)?_c('div',{staticClass:"input__head flex justify-between"},[_c('span',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(errors)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input__body",class:{
        focus: _vm.focus,
        'hide-border': _vm.hideBorder,
        error: errors[0],
        large: _vm.large
      }},[(_vm.disabled)?_c('span',[_vm._v(_vm._s(_vm.value))]):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"placeholder-grey-300 outline-none bg-transparent block w-full",attrs:{"name":_vm.vid,"placeholder":_vm.placeholder},domProps:{"value":(_vm.content)},on:{"focus":_vm.toggleFocus,"blur":_vm.toggleFocus,"input":[function($event){if($event.target.composing){ return; }_vm.content=$event.target.value},_vm.change]}})])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }