<template>
  <span :class="site" class="worth-pill">
    {{ name }}
  </span>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['name'],
  computed: {
    ...mapState(['site'])
  }
}
</script>

<style lang="postcss" scoped>
.worth-pill {
  @apply inline-block py-2 px-4 rounded-full mr-3 mb-3;
  @apply transition duration-75 ease-in-out;

  &:hover {
    @apply transform scale-105;
  }
}
.ecoshop .worth-pill {
  @apply bg-green-ecoshop-400 text-white;
}

.ecomarket .worth-pill {
  @apply bg-grey-100;
}
</style>
