<template>
  <validation-provider
    :rules="rules"
    tag="div"
    class="input"
    ref="provider"
    :vid="vid"
    :mode="mode"
    v-slot="{ errors }"
  >
    <div class="input__head justify-between mb-8 block">
      <label class="mr-3" :for="id">
        {{ name }}
      </label>
      <span v-if="errors" class="error">
        {{ errors[0] }}
      </span>
      <div
        class="input__body"
        :class="{
          focus,
          'hide-border': hideBorder,
          error: errors[0],
          large
        }"
      >
        <div class="relative w-full">
          <select
            class="
              block
              appearance-none
              w-full
              bg-transparent
              h-8
              text-lg
              focus:outline-none
              focus:bg-transparent
            "
            :id="id"
            @change="change"
            :name="vid"
            v-model="content"
          >
            <slot> </slot>
          </select>
          <div
            class="
              pointer-events-none
              absolute
              inset-y-0
              right-0
              flex
              items-center
              px-2
            "
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
  components: { ValidationProvider },
  props: {
    name: {
      name: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    value: {
      type: String,
      default: null
    },
    hideName: {
      type: Boolean,
      default: false
    },
    hideBorder: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String
    },
    large: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    mode: {
      type: String,
      default: 'eager'
    }
  },
  data() {
    return {
      content: this.value,
      focus: false,
      forcePasswordDisplay: false
    }
  },
  methods: {
    toggleFocus() {
      this.focus = !this.focus
    },
    reset() {
      this.content = ''
      this.$nextTick(() => {
        this.$refs.provider.reset()
      })
    },
    async change() {
      await this.$emit('input', this.content)
      await this.$emit('change', this.content)
      this.$emit('change', this.content)
    }
  }
}
</script>

<style lang="postcss" scoped>
.input__head {
  @apply text-sm mb-1;
  margin-bottom: 0;
}

.error {
  @apply text-red-base;
}

.input__body {
  @apply flex items-end border-solid border-b-2 border-grey-300 items-center text-lg;

  &.large {
    @apply h-12;
  }

  &.hide-border {
    @apply border-0;
  }

  &.focus {
    @apply border-grey-700;
  }

  &.error {
    @apply border-red-base;
  }
}

input {
  @apply w-full bg-transparent;
}
</style>
