<template>
  <div>
    <div v-for="(level1, i) of categories" :key="i" class="category">
      <span
        class="category-color"
        :style="{ 'background-color': `#${level1.color}` }"
      ></span>
      <div class="category-item" @click="open([level1.id])">
        <a :href="level1.url" @click.stop>{{ level1.title }}</a>
        <i
          class="uil text-2xl mt-1 ml-auto"
          v-if="level1.children.length > 0"
          :class="
            `${opened.includes(level1.id) ? 'uil-angle-down' : 'uil-angle-up'}`
          "
        ></i>
      </div>
      <div v-show="opened.includes(level1.id)">
        <div v-for="(level2, i2) in level1.children" :key="i2" class="pl-6">
          <div class="category-item" @click="open([level1.id, level2.id])">
            <a :href="level2.url" @click.stop>{{ level2.title }}</a>
            <i
              class="uil text-2xl mt-1 ml-auto"
              v-if="level2.children.length > 0"
              :class="
                `${
                  opened.includes(level2.id) ? 'uil-angle-down' : 'uil-angle-up'
                }`
              "
            ></i>
          </div>
          <div v-show="opened.includes(level2.id)">
            <div
              v-for="(level3, i3) in level2.children"
              :key="i3"
              class="pl-6 border-l-2 border-solid border-grey-100"
            >
              <div class="category-item">
                <a :href="level3.url" @click.stop>{{ level3.title }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchCategories } from '@/api'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      opened: []
    }
  },
  computed: mapState(['categories']),
  beforeCreate() {
    if (!this.categories) {
      fetchCategories()
    }
  },
  methods: {
    open(ids) {
      if (ids.length === 1) {
        if (this.opened[0] !== ids[0]) {
          this.opened = ids
        } else {
          this.opened = []
        }
      } else {
        if (JSON.stringify(this.opened) !== JSON.stringify(ids)) {
          this.opened = ids
        } else {
          this.opened = [ids[0]]
        }
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.category {
  @apply relative pl-6 mb-4 select-none;
}

.category-color {
  @apply absolute inline-block left-0 inset-y-0 bg-red-base h-full rounded-full w-2;
  content: ' ';
}

.category-item {
  @apply h-10 rounded-full inline-flex items-center w-full cursor-pointer;
}

a {
  @apply underline;
}
</style>
