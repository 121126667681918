import { extend, localize } from 'vee-validate'
import { required, email, length, confirmed, regex } from 'vee-validate/dist/rules'
import fr from 'vee-validate/dist/locale/fr.json'
import nl from 'vee-validate/dist/locale/nl.json'

// change some messages

fr.messages.required = 'Ce champ est obligatoire'
fr.messages.email = "L'email n'est pas valide"
fr.messages.length = 'Ce champ doit faire {length} caractères'

nl.messages.required = 'Dit veld is verplicht'
nl.messages.email = 'De e-mail is ongeldig'
nl.messages.length = 'Dit veld moet {length} tekens lang zijn'

// load language
localize({
  fr,
  nl
})

extend('email', email)
extend('required', required)
extend('length', length)
extend('confirmed', confirmed)
extend('regex', {
  ...regex,
  message: (field, {message}) => message ?? field + ' is invalid',
  params: ['regex', 'message']
})

