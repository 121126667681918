<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import Custombutton from '@/components/Button'
import Custominput from '@/components/Input'

export default {
  data() {
    return {
      submitting: false
    }
  },
  components: {
    ValidationObserver,
    Custombutton,
    Custominput
  },
  props: {
    seoId: String
  },
  computed: mapState(['tokenInput', 'url', 'locale']),
  methods: {
    async newsletterSubscribe() {
      const valid = await this.$refs.observer.validate()

      if (valid) {
        this.submitting = true
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(res => {
            const success = res.data.success
            if (success) {
              this.$toast.success(this.$t('lbl-email-subscribe-success'))
              this.$emit('success')
            } else {
              this.$toast.error(res.data.message)
            }
          })
          .catch(() => {})
          .finally(() => {
            this.submitting = false
          })
      }
    }
  }
}
</script>

<template>
  <validation-observer tag="div" ref="observer">
    <form
      method="post"
      action=""
      @submit.prevent="newsletterSubscribe"
      ref="form"
      :id="seoId"
    >
      <span v-html="tokenInput"></span>
      <input
        type="hidden"
        name="action"
        value="klaviyo/contact/subscribe"
      />
      <input type="hidden" name="language" :value="locale" />
      <div class="flex">
        <custominput
          vid="lastName"
          :name="$t('name-custom-address-lastname')"
          type="text"
          class="w-1/2 mr-6"
          rules="required"
        />
        <custominput
          vid="firstName"
          :name="$t('name-custom-address-firstname')"
          type="text"
          class="w-1/2"
          rules="required"
        />
      </div>
      <div class="flex mt-6">
        <custominput
          vid="email"
          name="Email"
          type="text"
          class="w-full"
          rules="required|email"
        />
        
      </div>
      <div class="flex mt-6">
        <custombutton
          :loading="submitting"
          type="submit"
        >
          {{ $t('btn-newsletter-subscribe-button') }}
        </custombutton>
      </div>
    </form>
  </validation-observer>
</template>