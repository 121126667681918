<template>
  <section>
    <customtitle :level="2" v-if="title" class="mb-12">
      {{ $t(title) }}
    </customtitle>
    <ul class="products-grid">
      <slot />
    </ul>
  </section>
</template>

<script>
import Title from '@/components/Title'

export default {
  components: {
    customtitle: Title
  },
  props: {
    title: String
  }
}
</script>

<style lang="postcss" scoped>
.products-grid {
  @apply grid grid-cols-1 gap-10;

  @screen xs {
    @apply grid-cols-2;
  }

  @media (min-width: 850px) {
    @apply grid-cols-3;
  }

  @media (min-width: 1150px) {
    @apply grid-cols-4;
  }
}
</style>
