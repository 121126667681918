<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    tag="div"
    ref="observer"
    class="w-full"
  >
    <form
      method="post"
      action=""
      @submit.prevent="handleSubmit(submit)"
      ref="form"
    >
      <span v-html="tokenInput"></span>
      <input type="hidden" name="action" value="gift-voucher/cart/add-code" />
      <div class="flex items-end w-full">
        <custom-input
          :placeholder="$t('placeholder-voucher')"
          class="mr-6 w-full"
          vid="voucherCode"
        ></custom-input>
        <custom-button :loading="submitting" secondary>
          {{ $t('btn-promo-apply') }}
        </custom-button>
        
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import store from '@/store'
import CustomInput from '@/components/Input.vue'
import CustomButton from '@/components/Button'

export default {
  computed: mapState(['tokenInput', 'url', 'site']),
  data() {
    return {
      submitting: false,
      error: ''
    }
  },
  components: { CustomInput, ValidationObserver, CustomButton },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate()

      if (valid) {
        this.submitting = true
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(res => {
            store.commit('updateCart', res.data.cart)
            if(res.data.errors?.voucherCode?.length??0 > 0){
              const errorData = res.data.errorData?.voucherCode??{};
              this.$refs.observer.setErrors({voucherCode: this.$t(res.data.errors.voucherCode[0], errorData)});
            } else {
              this.$refs.observer.setErrors({});
            }
          })
          .catch(() => {})
          .finally(() => {
            this.submitting = false
          })
      }
    }
  }
}
</script>
