var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-provider',{ref:"provider",staticClass:"input",attrs:{"rules":_vm.rules,"tag":"div","vid":_vm.vid,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input__head justify-between mb-8 block"},[_c('label',{staticClass:"mr-3",attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.name)+" ")]),(errors)?_c('span',{staticClass:"error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e(),_c('div',{staticClass:"input__body",class:{
        focus: _vm.focus,
        'hide-border': _vm.hideBorder,
        error: errors[0],
        large: _vm.large
      }},[_c('div',{staticClass:"relative w-full"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"\n            block\n            appearance-none\n            w-full\n            bg-transparent\n            h-8\n            text-lg\n            focus:outline-none\n            focus:bg-transparent\n          ",attrs:{"id":_vm.id,"name":_vm.vid},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.content=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.change]}},[_vm._t("default")],2),_c('div',{staticClass:"\n            pointer-events-none\n            absolute\n            inset-y-0\n            right-0\n            flex\n            items-center\n            px-2\n          "},[_c('svg',{staticClass:"fill-current h-4 w-4",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])])])])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }