<template>
  <div class="container wrapper" :class="site">
    <slot />
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  computed: mapState(['site'])
}
</script>
<style lang="postcss" scoped>
.wrapper {
  @apply mx-auto gap-x-6 px-6;

  @screen xxl {
    @apply p-0;
  }
}
</style>
