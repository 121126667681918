<template>
  <div class="checkout-card" :class="site">
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: mapState(['site'])
}
</script>

<style lang="postcss" scoped>
.checkout-card {
  @apply bg-white rounded-md  w-full;

  @screen lg {
    @apply py-10;
  }
}
.ecomarket .checkout-card {
  @apply p-6;

  @screen lg {
    @apply px-12;
  }
}
.ecoshop .checkout-card {
  @apply border border-solid border-grey-200 rounded-xxl p-12;
}
</style>
