<template>
  <div class="search" ref="search">
    <custominput
      v-model="mainInput"
      :placeholder="$t('placeholder-search-input')"
      icon="search"
      v-if="input"
      class="mb-12"
      large
    ></custominput>
    <div>
      <div v-if="loading" class="w-full flex items-center justify-center py-20">
        <loader big></loader>
      </div>
      <grid-products v-else-if="results.length > 0">
        <div v-for="(product, i) in results" :key="i">
          <product-thumbnail
            :title="product.title"
            :price="product.variant.salePrice"
            :imageurl="product.image"
            :url="product.url"
            :description="product.description"
            :id="product.variant.id"
            :supplierTitle="product.supplierTitle"
            :supplierUrl="product.supplierUrl"
            :color="product.color"
            :has-stock="product.variant.hasStock"
            :age-notice="product.ageNotice"
            :category-title="product.categoryTitle"
          ></product-thumbnail>
        </div>
      </grid-products>
      <div v-else>
        {{ $t('p-no-results') }}
      </div>
      <div
        v-if="info.totalPages"
        class="flex justify-center items-center w-full mt-12"
      >
        <div class="mr-3">
          <custombutton
            href="#"
            @click="page > 1 ? page-- : null"
            v-if="page > 1"
          >
            {{ $t('lbl-previous') }}
          </custombutton>
        </div>
        <div class="mx-3 flex">
          <div class="hidden md:flex">
            <custombutton
              variant="no-background"
              @click="page = page - 5"
              v-if="page > 5"
            >
              <span>...</span>
            </custombutton>
          </div>
          <div class="hidden md:flex flex-row-reverse">
            <custombutton
              v-for="(item, i) in page <= 5 ? page - 1 : 5"
              :key="i"
              variant="no-background"
              @click="page = page - item"
            >
              <span>{{ page - item }}</span>
            </custombutton>
          </div>
          <custombutton variant="no-background">
            <span class="underline">{{ page }}</span>
          </custombutton>
          <div v-if="!isMobile" class="flex">
            <div>
              <custombutton
                v-for="(item, i) in page + 5 > info.totalPages
                  ? info.totalPages - page
                  : 5"
                :key="i"
                variant="no-background"
                @click="page = page + item"
              >
                <span>{{ page + item }}</span>
              </custombutton>
            </div>
            <div>
              <custombutton
                variant="no-background"
                @click="page = page + 5"
                v-if="info.totalPages > page + 5"
              >
                <span>...</span>
              </custombutton>
            </div>
          </div>
          <div v-else class="flex">
            <div>
              <custombutton
                v-for="(item, i) in page + 1 > info.totalPages
                  ? info.totalPages - page
                  : 1"
                :key="i"
                variant="no-background"
                @click="page = page + item"
              >
                <span>{{ page + item }}</span>
              </custombutton>
            </div>
            <div>
              <custombutton
                variant="no-background"
                @click="page = page + 1"
                v-if="page < 2"
              >
                <span>...</span>
              </custombutton>
            </div>
          </div>
        </div>
        <div class="ml-3">
          <custombutton
            href="#"
            @click="page < info.totalPages ? page++ : null"
            v-if="page < info.totalPages"
          >
            {{ $t('lbl-next') }}
          </custombutton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Custominput from '@/components/Input'
import Custombutton from '@/components/Button'
import axios from 'axios'
import debounce from 'debounce'
import ProductThumbnail from '@/components/ProductThumbnail'
import VueScrollTo from 'vue-scrollto'
import GridProducts from '@/components/GridProducts'
import Loader from '@/components/Loader'
import { addToDataLayer } from '@/utils'

export default {
  data() {
    return {
      results: {},
      info: {},
      mainInput: '',
      page: 1,
      loading: false,
      didSearchAtLoad: false,
      isMobile: false
    }
  },
  props: {
    suppliers: {
      type: Array,
      default: () => {
        return null
      }
    },
    categories: {
      type: Array,
      default: () => {
        return null
      }
    },
    worths: {
      type: Array,
      default: () => {
        return null
      }
    },
    themes: {
      type: Array,
      default: () => {
        return null
      }
    },
    input: {
      type: Boolean,
      default: false
    },
    noSearchAtLoad: {
      type: Boolean,
      default: false
    },
    isCategoryProducts: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!this.noSearchAtLoad) {
      this.submit()
    } else {
      this.didSearchAtLoad = true
    }
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    mainInput() {
      this.submit()
    },
    page() {
      this.submit()
    }
  },
  components: {
    Custominput,
    ProductThumbnail,
    Custombutton,
    GridProducts,
    Loader
  },
  computed: {
    searchParams() {
      let params = '?'

      if (this.mainInput.length > 0) {
        params = `${params}q=${this.mainInput}`
      }

      if (this.suppliers) {
        params = `${params}${this.makeParam('suppliers', this.suppliers)}`
      }

      if (this.categories) {
        params = `${params}${this.makeParam('categories', this.categories)}`
      }

      if (this.worths) {
        params = `${params}${this.makeParam('worths', this.worths)}`
      }

      if (this.themes) {
        params = `${params}${this.makeParam('themes', this.themes)}`
      }

      if(this.isCategoryProducts){
        params = `${params}&isCategoryProducts=1`
      }

      return params
    },
    ...mapState(['url'])
  },
  methods: {
    makeParam(slug, array) {
      let param = `&${slug}=`

      array.forEach((elem, i) => {
        param = `${param}${elem}`
        if (i < array.length - 1) {
          param = `${param},`
        }
      })

      return param
    },
    submit() {
      this.loading = true
      this.search()
    },
    search: debounce(function() {
      axios
        .get(`${this.url}/json/search/p${this.page}${this.searchParams}`)

        .then(res => {
          this.results = res.data.results
          this.info = res.data.info
          this.loading = false
          if (this.didSearchAtLoad) {
            VueScrollTo.scrollTo(this.$refs.search, 300, {
              easing: 'ease-in-out',
              offset: -120
            })
          }
          this.didSearchAtLoad = true
          addToDataLayer({
            event: 'view_search_results',
            search_term: this.searchParams,
          });
        })
    }, 500),
    handleResize() {
      this.isMobile = window.innerWidth < 600
    }
  }
}
</script>

<style lang="postcss" scoped></style>
