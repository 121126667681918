<template>
  <validation-provider
    :rules="rules"
    tag="div"
    ref="provider"
    :vid="vid"
    v-slot="{ errors }"
    :mode="mode"
  >
    <label class="label-checkbox">
      <slot></slot>
      <input
        class="checkbox"
        type="checkbox"
        :id="id"
        :name="vid"
        :vid="vid"
        v-model="content"
        label
      />
      <span class="label-checkbox__span">
        <i class="uil uil-check"></i>
      </span>
    </label>
    <span v-if="errors" class="error">
      {{ errors[0] }}
    </span>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  components: { ValidationProvider },
  props: {
    id: String,
    value: Boolean,
    name: String,
    vid: String,
    rules: {
      type: [String, Object]
    },
    mode: {
      type: String,
      default: 'eager'
    }
  },
  data() {
    return {
      content: this.value,
      focus: false
    }
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.content = val
      }
    },
    content: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
.checkbox {
  @apply absolute opacity-0 left-0 top-0 cursor-pointer;
}
.label-checkbox {
  @apply select-none block relative cursor-pointer text-base pl-8 text-left;
}
.label-checkbox__span {
  @apply h-6 w-6 absolute top-0 border border-grey-300 left-0 bg-white rounded-md;
}
.label-checkbox:hover input ~ .label-checkbox__span {
  @apply border border-ecomarket-blue-300;
}
.label-checkbox input:checked ~ .label-checkbox__span {
  @apply bg-white text-ecomarket-blue-300;
}
.label-checkbox__span i {
  @apply absolute text-xl hidden;
  top: 1px;
  left: 1px;
}
.label-checkbox input:checked ~ .label-checkbox__span i {
  @apply block;
}
[class^='uil-']:before,
[class*=' uil-']:before {
  display: block;
}

.error {
  @apply text-sm;
}

.ecomarket .error {
  @apply text-red-base;
}

.ecoshop .error {
  @apply text-red-ecoshop-400;
}
</style>
