<template>
  <validation-observer
    v-slot="{ handleSubmit }"
    tag="div"
    ref="observer"
    class="w-full"
  >
    <form
      method="post"
      action=""
      @submit.prevent="handleSubmit(submit)"
      ref="form"
    >
      <span v-html="tokenInput"></span>
      <input type="hidden" name="action" value="commerce/cart/update-cart" />
      <div class="flex items-end w-full">
        <custom-input
          :placeholder="$t('placeholder-code-promo')"
          class="mr-6 w-full"
          v-model="code"
          vid="couponCode"
        ></custom-input>
        <custom-button :loading="submitting" secondary>
          {{ $t('btn-promo-apply') }}
        </custom-button>
      </div>
      <p v-if="error" class="text-red-300">
        {{ error }}
      </p>
    </form>
  </validation-observer>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { ValidationObserver } from 'vee-validate'
import store from '@/store'
import CustomInput from '@/components/Input.vue'
import CustomButton from '@/components/Button'

export default {
  computed: mapState(['tokenInput', 'url', 'site']),
  data() {
    return {
      submitting: false,
      code: '',
      error: ''
    }
  },
  components: { CustomInput, ValidationObserver, CustomButton },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate()

      if (valid) {
        this.submitting = true
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(res => {
            if (res.data.cart.couponCode != this.code) {
              this.error = this.$t('p-promocode-error')
            } else {
              this.error = ''
            }
            store.commit('updateCart', res.data.cart)
          })
          .catch(() => {})
          .finally(() => {
            this.submitting = false
          })
      }
    }
  }
}
</script>
