<template>
  <div class="text-sm text-grey-300">
    <i class="icon uil uil-exclamation-triangle mr-1"></i>
    {{ $t('p-cart-stock', { stock }) }}
  </div>
</template>

<script>
export default {
  props: {
    stock: Number
  }
}
</script>
