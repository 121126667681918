import store from '@/store'
import axios from 'axios'

export function fetchCart() {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  })

  const myRequest = new Request('?action=commerce/cart/get-cart', {
    responseType: 'json',
    headers: headers
  })

  fetch(myRequest)
    .then(res => res.json())
    .then(res => {
      axios.get(`${store.state.url}/json/hasMatchingAddresses`).then(res2 => {
        res.cart.billingSameAsShipping = res2.data.hasMatchingAddresses
        store.commit('updateCart', res.cart)
      })
    })
    .catch(err => console.log('error', err))
}

export function fetchCustomer() {
  return axios.get(`${store.state.url}/json/customer`).then(res => {
    store.commit('setCustomer', res.data)
  })
}

export function fetchAddresses() {
  return axios.get(`${store.state.url}/json/addresses`).then(res => {
    store.commit('setAddresses', res.data)
    //store.commit('setPrimaryShippingAddress', res.data.primaryShippingAddress)
    //store.commit('setPrimaryBillingAddress', res.data.primaryBillingAddress)
  })
}

export function fetchUser() {
  return axios.get(`${store.state.url}/json/user`).then(res => {
    store.commit('setUser', res.data)
  })
}

export function fetchSuppliers() {
  return axios.get(`${store.state.url}/json/suppliers`).then(res => {
    store.commit('setSuppliers', res.data)
  })
}

export function fetchCategories() {
  return axios.get(`${store.state.url}/json/categories`).then(res => {
    store.commit('setCategories', res.data)
  })
}

export function fetchWorths() {
  return axios.get(`${store.state.url}/json/worths`).then(res => {
    store.commit('setWorths', res.data)
  })
}

export function fetchTokenInput() {
  return axios.get(`${store.state.url}/json/token-input`).then(res => {
    store.commit('setTokenInput', res.data)
  })
}

export function fetchToken() {
  return axios.get(`${store.state.url}/json/token`).then(res => {
    store.commit('setToken', res.data)
  })
}

export function fetchMinimarkets() {
  return axios.get(`${store.state.url}/json/minimarkets`)
}

export function fetchEnterpriseGifts() {
  return axios.get(`${store.state.url}/json/enterprise-gifts`)
}

export function fetchThemes() {
  return axios.get(`${store.state.url}/json/themes`).then(res => {
    store.commit('setThemes', res.data)
  })
}

export function fetchOrder(orderId) {
  return axios.get('?action=commerce-config/orders/get-order', {
    params: {orderId}
  })
  .then(res => {
    return res.data.order;
  })
}