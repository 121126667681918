<template>
  <div
    class="customer-address-card "
    :class="`${selectable ? 'selectable' : ''} ${site}`"
    @click="select"
  >
    <span v-if="primary" class="primary">{{ $t('i-address-primary') }}</span>
    <span v-if="selectable" class="selectable-item" :class="{ selected }">
      <i class="icon uil uil-check"></i>
    </span>
    <div class="w-2/3">
      <div>
        <div>{{ address.fullName }}</div>
      </div>
      <div>
        <div>{{ address.addressLine1 }}, {{ address.addressLine2 }}</div>
      </div>
      <div>
        <div>
          {{ address.postalCode }} {{ address.locality }},
          {{ country }}
        </div>
      </div>
      <div v-if="address.organizationTaxId">
        <div>
          <span class="text-grey-300">TVA:</span>
          {{ address.organizationTaxId }}
        </div>
      </div>
    </div>
    <div class="text-sm w-1/3 flex flex-col items-end">
      <custombutton
        variant="no-background no-padding"
        icon="edit-alt"
        class="mt-auto mb-2"
        @click="openPopup"
        prevent
        stop
      >
        {{ $t('btn-edit') }}
      </custombutton>
      <form method="post" action="" @submit.prevent ref="deleteForm">
        <input type="hidden" name="action" value="users/delete-address" />
        <span v-html="tokenInput"></span>
        <input type="hidden" name="addressId" :value="address.id" />
        <custombutton
          variant="no-background no-padding"
          icon="trash-alt"
          :loading="deleteSubmitting"
          @click="deleteAddress"
          prevent
          stop
        >
          {{ $t('btn-delete') }}
        </custombutton>
      </form>
    </div>
    <customer-address-popup
      :address="address"
      v-if="displayPopup"
      @close="closePopup"
    ></customer-address-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { fetchCustomer, fetchAddresses } from '@/api'
import axios from 'axios'
import Custombutton from '@/components/Button'
import CustomerAddressPopup from '@/Customer/CustomerAddressPopup'
export default {
  props: {
    address: Object,
    selectable: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Custombutton,
    CustomerAddressPopup
  },
  data() {
    return {
      displayPopup: false,
      deleteSubmitting: false
    }
  },
  computed: {
    country() {
      if (this.address.countryCode) {
        return this.countries[this.address.countryCode]
      } else {
        return ''
      }
    },
    ...mapState(['countries', 'tokenInput', 'url', 'site'])
  },
  methods: {
    openPopup() {
      this.displayPopup = true
    },
    closePopup() {
      this.displayPopup = false
    },
    deleteAddress() {
      this.deleteSubmitting = true
      var data = new FormData(this.$refs.deleteForm)
      axios
        .post(`/${this.url}/`, data, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
        .then(() => {
          fetchAddresses()
          fetchCustomer()
        })
        .catch(() => {})
        .finally(() => {
          this.deleteSubmitting = false
        })
    },
    select() {
      this.$emit('select', this.address.id)
    }
  }
}
</script>

<style lang="postcss" scoped>
.customer-address-card {
  @apply bg-grey-20 flex px-6 py-4 rounded-md w-full relative;
  @apply transition ease-in-out duration-100;
  &.selectable {
    @apply cursor-pointer;
    &:hover {
      @apply bg-grey-50;
    }
  }
}

.primary {
  @apply absolute top-0 right-0 text-white px-3 py-1 text-sm rounded-full;
  transform: translate(10px, -50%);
}

.ecomarket .primary {
  @apply bg-ecomarket-orange-300 text-white;
}

.ecoshop .primary {
  @apply bg-grey-500;
}

.selectable-item {
  @apply absolute w-4 h-4;
  top: 5px;
  left: -5px;
  i {
    @apply absolute text-3xl text-ecomarket-blue-300 hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.selected i {
    @apply block;
  }
}
</style>
