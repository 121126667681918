<template>
  <div class="custom-tooltip">
    <div :class="{ faded }">
      <slot name="trigger"></slot>
    </div>
    <div
      v-if="!disabled"
      class="custom-tooltip__content"
      :class="{ 'whitespace-no-wrap': noWrap, top, bottom, topLeft }"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    noWrap: {
      type: Boolean,
      default: false
    },
    faded: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    topLeft: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss" scoped>
.custom-tooltip {
  @apply z-10 relative inline-block cursor-default;
}

.faded {
  @apply opacity-75 transition duration-200 ease-in-out;
}

.custom-tooltip__content {
  @apply bg-grey-800 text-white rounded-md px-3 py-2 text-sm inline-block absolute opacity-0 pointer-events-none transition duration-150 ease-in-out w-64;

  &.bottom {
    @apply top-full left-1/2 transform -translate-x-1/2 mt-3;
  }

  &.top {
    @apply bottom-full left-1/2 transform -translate-x-1/2 mb-3;
  }

  &.topLeft {
    @apply bottom-full right-0 transform -translate-y-1 mb-3 mr-2;
  }
}

.custom-tooltip {
  &:hover {
    .custom-tooltip__content {
      @apply opacity-100 pointer-events-auto;

      &.topLeft {
        @apply transform -translate-y-0;
      }
    }
    .faded {
      @apply opacity-100;
    }
  }
}
</style>
