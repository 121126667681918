<template>
  <dropdown :items="accountDropdownItems" class="flex items-center">
    <i class="text-2xl lg:text-md uil uil-user pr-2"></i>
    <div class="hidden lg:contents">
      <span v-if="user != null">{{ user.email }}</span>
      <span v-else>{{ $t('ttl-header-account') }}</span>
    </div>
  </dropdown>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
import Dropdown from '@/components/Dropdown'
export default {
  components: {
    Dropdown
  },
  props: {
    logoutUrl: String
  },
  computed: {
    accountDropdownItems() {
      if (this.user) {
        return [
          {
            name: this.$t('menu-customer-orders'),
            link: this.$t('route-customer-orders')
          },
          {
            name: this.$t('menu-customer-addresses'),
            link: this.$t('route-customer-addresses')
          },
          {
            name: this.$t('menu-customer-infos'),
            link: this.$t('route-customer-infos')
          },
          {
            name: this.$t('menu-customer-logout'),
            link: this.logoutUrl
          }
        ]
      } else {
        return [
          {
            action: () => {
              store.commit('showLoginPopup')
            },
            name: this.$t('menu-customer-login')
          },
          {
            action: () => {
              store.commit('showLoginPopup', 'register')
            },
            name: this.$t('menu-customer-register')
          }
        ]
      }
    },
    ...mapState(['user'])
  }
}
</script>
