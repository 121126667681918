export function getCookieConsentConfig(){
    return {
        revision: 1,
        categories: {
            necessary: {
                enabled: true, // this category is enabled by default
                readOnly: true // this category cannot be disabled
            },
            analytics: {}
        },
        guiOptions: {
          consentModal: {
              layout: 'bar inline',
              position: 'bottom',
              flipButtons: false,
              equalWeightButtons: true
            },
          preferencesModal: {
              layout: 'box',
              flipButtons: false,
              equalWeightButtons: true
            }
        },
        language: {
            default: this.locale,
            translations: {
                [this.locale]: {
                    consentModal: {
                        description: this.$t('cookie-consent-banner-description'),
                        acceptAllBtn: this.$t('cookie-consent-banner-accept-all'),
                        acceptNecessaryBtn: this.$t('cookie-consent-banner-accept-necessary'),
                        showPreferencesBtn: this.$t('cookie-consent-banner-show-preferences')
                    },
                    preferencesModal: {
                        title: this.$t('cookie-preferences-modal-title'),
                        acceptAllBtn: this.$t('cookie-preferences-modal-accept-all'),
                        acceptNecessaryBtn: this.$t('cookie-preferences-modal-accept-necessary'),
                        savePreferencesBtn: this.$t('cookie-preferences-modal-save'),
                        closeIconLabel: this.$t('cookie-preferences-modal-close'),
                        sections: [
                            {
                                title: this.$t('cookie-preferences-modal-main-section-title'),
                                description: this.$t('cookie-preferences-modal-main-section-description', {routeCookie: this.$t('route-cookies')})
                            },
                            {
                                title: this.$t('cookie-preferences-modal-necessary-title'),
                                description: this.$t('cookie-preferences-modal-necessary-description'),
                                linkedCategory: 'necessary'
                            }, 
                            {
                                title: this.$t('cookie-preferences-modal-analytics-title'),
                                description: this.$t('cookie-preferences-modal-analytics-description'),
                                linkedCategory: 'analytics'
                            },        
                        ]
                    },
                }
            }
        },
        onFirstConsent: ({cookie}) => {
            const allCategories = Object.keys(this.cookieConsent.getConfig('categories'));
            let has_accepted = 'no';
            if(cookie.categories.length === allCategories.length) {
                has_accepted = 'yes';
            } else if(cookie.categories.length > 1) { // necessary is not counted because it's mandatory
                has_accepted = 'partially';
            }

            this.addToDataLayer({
                event: 'accept_cmp',
                has_accepted,
            })
            
        },
    };
  }