<template>
  <component
    :is="`h${level}`"
    :class="`title h${styleOf ? styleOf : level} ${variant} ${site}`"
  >
    <slot></slot>
    <span v-if="content">{{ $t(content) }}</span>
  </component>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Title',
  props: {
    level: {
      type: [Number, String],
      default: 1
    },
    variant: {
      type: String,
      default: ''
    },
    styleOf: {
      type: [Number, String]
    },
    content: {
      type: String,
      default: null
    }
  },
  computed: mapState(['site'])
}
</script>

<style lang="postcss" scoped>
.title {
  @apply font-heading font-bold;
}

.title.ecomarket {
  @apply text-grey-800;
}

.title.ecoshop {
  @apply font-ecoshop-main text-black font-normal;
}

.h1 {
  @apply text-4xl leading-tight;
  @screen md {
    @apply text-5xl;
  }
}

.h1.ecomarket {
  @apply text-5xl leading-tight;
  @screen md {
    @apply text-6xl;
  }
  @apply font-light;
}

.h1.ecoshop {
  @apply font-normal;
}

.h2 {
  @apply text-3xl leading-normal;
  @screen md {
    @apply text-4xl;
  }
}

.h2.ecomarket {
  @apply text-4xl;
  @screen md {
    @apply text-5xl;
  }
  @apply font-light;
}

.h2.ecoshop {
  @apply font-normal;
}

.h3 {
  @apply text-2xl leading-normal;
  @screen md {
    @apply text-3xl;
  }
}

.h3.ecomarket {
  @apply font-bold;
}

.h3.ecoshop {
  @apply font-normal;
}

.h4 {
  @apply text-2xl font-bold leading-tight;
}

.h4.ecomarket {
  @apply font-bold;
}

.h4.ecoshop {
  @apply font-normal;
}

.h5 {
  @apply text-2xl font-bold leading-tight;
}

.h5.ecomarket {
  @apply font-bold;
}

.h5.ecoshop {
  @apply font-normal;
}

.h6 {
  @apply text-base font-bold font-heading;
}

.h6.ecomarket {
  @apply font-bold;
}

.h6.ecoshop {
  @apply font-normal;
}

.bold {
  @apply font-bold;
}

.big {
  font-size: 3em;

  @screen xl {
    font-size: 5em;
  }
}

.secondaryFont {
  @apply font-heading-variant;
}

.white {
  @apply text-white;
}
</style>
