import { render, staticRenderFns } from "./CustomerAddressPopup.vue?vue&type=template&id=37b1f8ea&scoped=true&"
import script from "./CustomerAddressPopup.vue?vue&type=script&lang=js&"
export * from "./CustomerAddressPopup.vue?vue&type=script&lang=js&"
import style0 from "./CustomerAddressPopup.vue?vue&type=style&index=0&id=37b1f8ea&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37b1f8ea",
  null
  
)

export default component.exports