<template>
  <div class="item" :class="{ end }">
    <component :is="link ? 'a' : 'div'" :href="link ? link : null">
      <slot />
    </component>
    <div v-if="!end" class="mx-3">
      <i class="uil uil-minus"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    end: Boolean,
    link: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="postcss" scoped>
.item {
  @apply flex;

  a {
    @apply text-grey-800;

    &:hover {
      @apply text-grey-500;
    }
  }
}

.end {
  @apply text-grey-500;
}
</style>
