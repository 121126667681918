<template>
  <div class="ecoshop-backgroud">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1378"
      height="834"
      viewBox="0 0 1378 834"
    >
      <path
        id="Tracé_1240"
        data-name="Tracé 1240"
        d="M417,0H961c230.3,0,417,186.7,417,417S1191.3,834,961,834H417C186.7,834,0,647.3,0,417S186.7,0,417,0Z"
        fill="#eff7e6"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    site: String
  }
}
</script>

<style lang="postcss" scoped>
.ecoshop-backgroud {
  width: 1300px;
}
</style>
