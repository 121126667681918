<template>
  <div class="order-card">
    <header class="order-card__header">
      <div class="col w-2/12">
        {{ order.reference }}
      </div>
      <div class="col w-2/12">
        {{ order.datePaid }}
      </div>
      <div class="col w-3/12">
        {{ order.orderStatus }}
      </div>
      <div class="col w-2/12">
        <price :amount="parseFloat(order.totalPrice).toFixed(2)"></price>
      </div>
      <div class="col w-3/12">
        <a class="link" v-if="order.returnUrl" :href="order.returnUrl" >
          <i class="text-2xl lg:text-md uil uil-external-link-alt mr-1"></i><span>{{ $t('col-orders-return-url-value') }}</span>
        </a>
        <span v-else-if="order.orderStatusHandle != 'new' && order.orderStatusHandle != 'inPreparation'" >{{ $t('order-return-url-not-available-message') }}</span>
      </div>
    </header>
  </div>
</template>

<script>
import Price from '@/components/Price'

export default {
  props: ['order'],
  components: {
    Price
  }
}
</script>

<style lang="postcss" scoped>
.order-card {
  @apply bg-grey-20 px-6 pb-4 rounded-md w-full relative h-24 overflow-hidden;
}

.order-card__header {
  @apply flex h-24;
}

.col {
  @apply flex items-center;
}

.link{
  color: #4B85BA;
}
</style>
