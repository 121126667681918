<template>
  <transition name="fade" appear>
    <validation-observer
      tag="div"
      ref="observer"
      accept-charset="UTF-8"
      class="login-popup"
      v-if="showLoginPopup"
    >
      <form
        method="post"
        class="w-full flex justify-center items-center"
        ref="form"
        @submit.prevent="submit"
        @keydown.enter.prevent="submit"
      >
        <span v-html="tokenInput"></span>
        <input type="hidden" name="rememberMe" value="1" />
        <div class="login-popup__inner" :class="site">
          <custom-button
            v-if="loginPopupType === 'login' && loginStep === 'loginByEmail'"
            class="control__return"
            @click="switchToLoginChoose"
            variant="transparent dark"
            icon="arrow-left"
            hideText
            prevent
          >
            <template #text>
              Retourner
            </template>
          </custom-button>

          <custom-button
            class="control__close"
            @click="close"
            variant="transparent dark"
            icon="multiply"
            hideText
            prevent
          >
            <template #text>
              {{ $t('btn-close') }}
            </template>
          </custom-button>
          <div v-if="loginPopupType === 'login'">
            <input type="hidden" name="action" value="users/login" />
            <customtitle :level="2" :style-of="4" class="login-popup-title">
              {{ $t('btn-login') }}
            </customtitle>
            <div v-if="loginStep === 'choose'">
              <div v-if="site === 'ecoshop'">
                <div class="flex w-full justify-center mt-12" >
                  <custom-button class="w-full" :link="edenredSsoLoginUrl" imageIcon="/static-assets/images/edenred-white-logo.png">
                    <template #text>
                      <span>{{ $t('btn-edenred-account') }}</span>
                    </template>
                  </custom-button>
                </div>

                <div class="flex w-full justify-center mt-6 mb-12"  >
                  <custom-button class="w-full" variant="grey" @click="switchToLoginByEmail" prevent > 
                    <template #text>
                      {{ $t('lbl-form-email') }}
                    </template>
                  </custom-button>
                </div>
              </div>
              <div v-if="site === 'ecomarket'">
                <div class="flex w-full justify-center mt-12"  >
                  <custom-button class="w-full" variant="grey" @click="switchToLoginByEmail" prevent > 
                    <template #text>
                      {{ $t('lbl-form-email') }}
                    </template>
                  </custom-button>
                </div>
                <div class="flex w-full justify-center mt-6 mb-12" >
                <custom-button class="w-full" variant="edenred" :link="edenredSsoLoginUrl" imageIcon="/static-assets/images/edenred-white-logo.png">
                  <template #text>
                    <span>{{ $t('btn-edenred-account') }}</span>
                  </template>
                </custom-button>
              </div>
              </div>
              

              
          </div>
          <div v-if="loginStep === 'loginByEmail'">
            <field
              placeholder="lbl-form-email"
              class="mb-6"
              :value="rememberedUsername"
              vid="loginName"
              rules="email|required"
              mode="passive"
            ></field>
            <field
              placeholder="lbl-form-password"
              type="password"
              vid="password"
              rules="required"
              mode="passive"
            ></field>

            <div class="flex w-full justify-center my-12">
              <custom-button class="w-full" :loading="submitting">
                <template #text>
                  {{ $t('btn-login') }}
                </template>
              </custom-button>
            </div>
          </div>

            <div class="bottom-actions">
              <div class="mb-3"  v-if="loginStep === 'loginByEmail'">
                <a href="/" @click.prevent="switchToResetPassword">
                  {{ $t('btn-forgot-password') }}
                </a>
              </div>
              <div>
                {{ $t('p-login-register') }}
                <a href="/" @click.prevent="switchToRegister">
                  {{ $t('btn-login-register') }}
                </a>
              </div>
            </div>
          </div>
          <div v-if="loginPopupType === 'register' && !registerSuccess">
            <input type="hidden" name="action" value="users/save-user" />
            <input
              type="hidden"
              name="preferredLanguage"
              :value="$i18n.locale"
            />
            <customtitle :level="2" :style-of="4" class="login-popup-title">
              {{ $t('ttl-login-register') }}
            </customtitle>
            <div class="flex mb-6">
              <field
              placeholder="lbl-form-lastname"
              class="w-1/2 mr-2"
              vid="firstName"
              rules="required"
              mode="passive"
            ></field>
            <field
              placeholder="lbl-form-firstname"
              class="w-1/2"
              vid="lastName"
              rules="required"
              mode="passive"
            ></field>
            </div>
            <field
              placeholder="lbl-form-email"
              class="mb-6"
              vid="email"
              rules="email|required"
              mode="passive"
            ></field>
            <field
              placeholder="lbl-form-password"
              type="password"
              vid="password"
              rules="required"
              mode="passive"
              class="mb-6"
            ></field>
            <checkbox
              v-model="subscribeToNewsletter"
              vid="subscribeToNewsletter"
            >
              {{ $t('p-checkout-subscribe-to-newsletter') }}
            </checkbox>
            <input type="hidden" name="subscribeToNewsletterSubmitted" value="1" />

            <div class="flex w-full justify-center my-12">
              <custom-button class="w-full" :loading="submitting">
                {{ $t('btn-register') }}
              </custom-button>
            </div>

            <div class="bottom-actions">
              <div>
                {{ $t('p-register-login') }}
                <a href="/" @click.prevent="switchToLogin">
                  {{ $t('btn-register-login') }}
                </a>
              </div>
            </div>
          </div>
          <div v-if="loginPopupType === 'register' && registerSuccess">
            <p class="leading-8 pt-12 pb-6">
              {{ $t('p-register-success-a') }}
            </p>
            <p class="leading-8 pb-12 text-grey-300">
              {{ $t('p-register-success-b') }}
            </p>
          </div>
          <div v-if="loginPopupType === 'resetPassword'">
            <div v-if="passwordResetSentSuccess">
              <p class="leading-8 pt-12 pb-6">
                {{ $t('p-password-reset-success-a') }}
              </p>
              <p class="leading-8 pb-12 text-grey-300">
                {{ $t('p-password-reset-success-b') }}
              </p>
            </div>
            <div v-else>
              <input
                type="hidden"
                name="action"
                value="users/send-password-reset-email"
              />
              <customtitle :level="2" :style-of="4" class="login-popup-title">
                {{ $t('p-password-reset') }}
              </customtitle>
              <field
                placeholder="Email"
                class="mb-6"
                :value="rememberedUsername"
                vid="loginName"
                rules="email|required"
                mode="passive"
              ></field>

              <div class="flex w-full justify-center my-12">
                <custom-button class="w-full" :loading="submitting">
                  {{ $t('btn-password-reset') }}
                </custom-button>
              </div>

              <div class="bottom-actions">
                <div class="mb-3">
                  {{ $t('p-register-login') }}
                  <a href="/" @click.prevent="switchToLogin">
                    {{ $t('btn-register-login') }}
                  </a>
                </div>
                <div>
                  {{ $t('p-login-register') }}
                  <a href="/" @click.prevent="switchToRegister">
                    {{ $t('btn-login-register') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import { fetchUser, fetchTokenInput, fetchToken } from '@/api'
import axios from 'axios'
import store from '@/store'
import Title from '@/components/Title'
import Field from '@/components/Input'
import CustomButton from '@/components/Button'
import Escapable from '@/mixins/Escapable'
import { ValidationObserver } from 'vee-validate'
import Checkbox from '@/components/Checkbox.vue'
import { addToDataLayer } from '@/utils'

export default {
  components: {
    customtitle: Title,
    Field,
    CustomButton,
    ValidationObserver,
    Checkbox
  },
  props: {
    rememberedUsername: String,
    redirect: String
  },
  data() {
    return {
      submitting: false,
      registerSuccess: false,
      passwordResetSentSuccess: false,
      subscribeToNewsletter: true,
    }
  },
  mixins: [Escapable],
  computed: mapState([
    'showLoginPopup',
    'loginPopupType',
    'tokenInput',
    'url',
    'site',
    'loginStep',
    'changeLoginStep',
    'edenredSsoLoginUrl'
  ]),
  watch: {
    showLoginPopup: function(val) {
      this.escapableVisible = val
    }
  },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.submitting = true
        const data = new FormData(this.$refs.form)
        axios
          .post(`${this.url}/`, data, {
            headers: { 'X-Requested-With': 'XMLHttpRequest' }
          })
          .then(async () => {
            if (this.loginPopupType === 'login') {
              await fetchUser()
              await fetchTokenInput()
              await fetchToken()
              this.close()
              this.$toast.success(this.$t('succ-connected'))
            } else if (this.loginPopupType === 'register') {
              this.registerSuccess = true
            } else if (this.loginPopupType === 'resetPassword') {
              this.passwordResetSentSuccess = true
            }
          })
          .catch(({ response }) => {
            console.dir(response)
            this.$toast.error(response.data.message)
          })
          .finally(() => {
            if(this.loginPopupType === 'register'){
              addToDataLayer({
                event: 'sign_up_process',
                registration_success: this.registerSuccess
              })
            }
            this.submitting = false
          })
      }
    },
    close() {
      store.commit('hideLoginPopup')
    },
    switchToRegister() {
      store.commit('showLoginPopup', 'register')
    },
    switchToLogin() {
      store.commit('showLoginPopup', 'login')
    },
    switchToResetPassword() {
      store.commit('showLoginPopup', 'resetPassword')
    },
    switchToLoginByEmail() {
      store.commit('changeLoginStep', 'loginByEmail')
    },
    switchToLoginChoose() {
      store.commit('changeLoginStep', 'choose')
    },
    escapeHandler() {
      this.close()
    }
  }
}
</script>

<style lang="postcss" scoped>
.login-popup {
  @apply fixed inset-0 bg-grey-700 bg-opacity-75 z-50 flex justify-center items-center;
}

.login-popup__inner {
  @apply bg-white w-full p-10 rounded-md relative text-center;

  @screen md {
    @apply w-1/2;
  }

  @screen xl {
    @apply w-1/3;
  }
}

.login-popup .control__close {
  @apply absolute top-0 right-0 text-2xl m-3;
}

.login-popup .control__return {
  @apply absolute top-0 left-0 text-2xl m-3;
}

.login-popup-title {
  @apply mb-12;
}

.bottom-actions {
  @apply text-grey-300;
}

.ecomarket .bottom-actions {
  a {
    @apply text-ecomarket-blue-300;
  }
}
.ecoshop .bottom-actions {
  a {
    @apply text-green-ecoshop-400;
  }
}
</style>
