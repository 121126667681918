<template>
  <div class="price" :class="`${variant} ${site}`">
    <span class="currency">€</span>
    <span class="amount">{{ computedPrice }}</span>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    amount: {
      type: [String, Number],
      default: '0'
    },
    variant: {
      type: String,
      default: ''
    }
  },
  computed: {
    computedPrice() {
      return this.amount.replace('€', '').trim()
    },
    ...mapState(['site'])
  }
}
</script>

<style lang="postcss" scoped>
.price {
  @apply flex items-end;
}

.currency,
.amount {
  @apply inline-block leading-none;
}

.currency {
  @apply text-base leading-none mr-1;
  margin-bottom: 0.1em;
  font-size: 0.8em;
}

.big {
  @apply text-4xl;
  .currency {
    font-size: 0.5em;
    margin-bottom: 0.15em;
  }
}

.medium {
  @apply text-2xl;
  .currency {
    @apply text-base leading-none;
    margin-bottom: 0.12rem;
  }
}

.small {
  @apply text-sm;
  .currency {
    font-size: 1em;
    margin-bottom: 0;
  }
}

.styled {
  @apply font-light font-heading font-bold;
}

.styled.ecoshop {
  @apply font-ecoshop-main;
}
</style>
