<template>
  <validation-provider
    :rules="rules"
    tag="div"
    class="input"
    ref="provider"
    :vid="vid"
    v-slot="{ errors }"
    :mode="mode"
  >
    <div>
      <div class="input__head flex justify-between" v-if="!hideName">
        <span class="mr-3">
          {{ name }}
        </span>
        <span v-if="errors" class="error">
          {{ errors[0] }}
        </span>
      </div>
      <div
        class="input__body"
        :class="{
          focus,
          'hide-border': hideBorder,
          error: errors[0],
          large
        }"
      >
        <span v-if="disabled">{{ value }}</span>
        <textarea
          class="placeholder-grey-300 outline-none bg-transparent block w-full"
          :name="vid"
          :placeholder="placeholder"
          @focus="toggleFocus"
          @blur="toggleFocus"
          @input="change"
          v-model="content"
        />
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
export default {
  components: { ValidationProvider },
  props: {
    name: {
      name: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    vid: {
      type: String,
      default: ''
    },
    icon: {
      type: String
    },
    search: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Boolean],
      default: ''
    },
    hideName: {
      type: Boolean,
      default: false
    },
    hideBorder: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String
    },
    large: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    mode: {
      type: String,
      default: 'eager'
    }
  },
  data() {
    return {
      content: this.value,
      focus: false
    }
  },
  methods: {
    toggleFocus() {
      this.focus = !this.focus
    },
    displayPassword() {
      this.forcePasswordDisplay = true
    },
    hidePassword() {
      this.forcePasswordDisplay = false
    },
    reset() {
      this.content = ''
      this.$nextTick(() => {
        this.$refs.provider.reset()
      })
    },
    change() {
      this.$emit('change', this.content)
      this.$emit('input', this.content)
    }
  }
}
</script>

<style lang="postcss" scoped>
.input__head {
  @apply text-sm mb-1;
}

.error {
  @apply text-red-base;
}

.input__body {
  @apply flex items-end border-solid border-b-2 border-grey-300 items-center text-lg;

  &.large {
    @apply h-12;
  }

  &.hide-border {
    @apply border-0;
  }

  &.focus {
    @apply border-grey-700;
  }

  &.error {
    @apply border-red-base;
  }
}

input {
  @apply w-full bg-transparent;
}
</style>
