<template>
  <span class="dot" :style="{ 'background-color': `#${color}` }"></span>
</template>

<script>
export default {
  props: {
    color: String
  }
}
</script>

<style lang="postcss" scoped>
.dot {
  width: 10px;
  height: 10px;
  @apply rounded-full block top-0 bottom-0;
}
</style>
