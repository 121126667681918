<template>
  <div class="grid gap-8" :class="columnsClass">
    <a
      :href="theme.url"
      v-for="(theme, index) of themes"
      :key="index"
      class="theme"
    >
      <div class="w-full h-44 rounded-md overflow-hidden mr-6 flex-shrink-0">
        <div class="head">
          <img :src="theme.image" alt="" class="image w-full" />
        </div>
      </div>
      <custom-title :level="2" :style-of="4" class="mt-3 ml-4">
        {{ theme.title }}
      </custom-title>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { fetchThemes } from '@/api'
import CustomTitle from '@/components/Title'

export default {
  components: {
    CustomTitle
  },
  props: {
    columns: {
      type: Number,
      default: 1
    }
  },
  computed: {
    columnsClass() {
      return `grid-cols-${this.columns}`
    },
    ...mapState(['themes'])
  },
  beforeCreate() {
    if (!this.themes) {
      fetchThemes()
    }
  }
}
</script>

<style lang="postcss" scoped>
.theme {
  @apply relative rounded-md overflow-hidden;

  &:hover {
    .head {
      @apply transform scale-105 shadow-thumbnail;
    }
  }
}

.head {
  @apply transition duration-500 ease-in-out;
}
</style>
