<template>
  <main class="checkout">
    <header class="mb-10">
      <div class="lg:flex items-center">
        <customtitle>
          {{ $t('ttl-order') }}
        </customtitle>
        <div class="steps">
          <div
            v-for="(title, index) in steps"
            :key="index"
            class="steps__step"
            :class="`${checkoutStep > index ? 'active' : ''}`"
          >
            <span class="lg:hidden mr-2">{{ index + 1 }}</span>
            <a
              href="#"
              class="steps__step__inner"
              v-if="checkoutStep > index"
              @click.prevent="setCheckoutStep(index + 1)"
            >
              {{ $t(title) }}
            </a>
            <div class="steps__step__inner" v-if="checkoutStep <= index">
              {{ $t(title) }}
            </div>
            <i
              :class="`uil uil-arrow-right`"
              class="hidden lg:inline-block"
              v-if="index + 1 < steps.length"
            ></i>
          </div>
        </div>
      </div>
    </header>
    <div class="checkout__inner">
      <div v-if="cart.totalQty > 0 || checkoutStep === 3">
        <checkout-informations
          v-if="checkoutStep === 1"
        ></checkout-informations>
        <checkout-payment v-if="checkoutStep === 2">
          <div v-if="cancelled" class="text-red-base mb-6">
            {{ $t('p-payment-failure') }}
          </div>
          <slot name="payment"></slot>
        </checkout-payment>
        <div v-if="checkoutStep === 3" class="flex flex-col items-center">
          <div class="text-center">
            <customtitle :level="2">
              {{ $t('p-checkout-end-1') }}
            </customtitle>
            <p class="mt-6 text-lg">
              {{ $t('p-checkout-end-2') }}
            </p>
            <p class="mt-3 text-lg">
              {{ $t('p-checkout-end-3') }}
            </p>
          </div>
          <div class="mt-10 w-4/5" v-if="site === 'ecomarket'">
            <img
              src="@/assets/images/success.jpg"
              alt="Success"
              class="rounded-md"
            />
          </div>
        </div>
      </div>
      <div v-else>
        {{ $t('p-empty-cart') }}
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import Title from '@/components/Title'
import CheckoutInformations from '@/components/CheckoutInformations'
import CheckoutPayment from '@/components/CheckoutPayment'
import { fetchOrder } from '@/api'
import { addToDataLayer, formatGAPriceWithoutCurrency } from '@/utils'

export default {
  components: {
    customtitle: Title,
    CheckoutInformations,
    CheckoutPayment
  },
  props: {
    success: {
      type: Boolean,
      default: false
    },
    cancelled: {
      type: Boolean,
      default: false
    },
    orderId: Number
  },
  computed: mapState(['checkoutStep', 'cart', 'user', 'site']),
  created() {
    if (this.success) {
      store.commit('setCheckoutStep', 3)
      if(this.orderId) {
        fetchOrder(this.orderId).then(order => {
          const lastTransaction = order.transactions.length > 0 ? order.transactions[order.transactions.length-1] : null;
          const lastTransactionResponse = lastTransaction?.response ? JSON.parse(lastTransaction?.response) : null;
          addToDataLayer({
            event: 'purchase',
            transaction_id: lastTransaction?.id,
            value: formatGAPriceWithoutCurrency(order.totalAsCurrency),
            number_of_items: order.lineItems.length,
            tax: formatGAPriceWithoutCurrency(order.taxWithIncludedAsCurrency),
            payment_type: lastTransactionResponse?.method ?? lastTransactionResponse?.payment_details?.type,
            order_revenue: formatGAPriceWithoutCurrency(order.orderRevenueValueAsCurrency),
            promo_code: order.couponCode,
            promo_code_discount: formatGAPriceWithoutCurrency(order.promoCodeValueAsCurrency),
            promo_code_value: formatGAPriceWithoutCurrency(order.promoCodeValueAsCurrency),
            gift_card_name: order.giftCardName,
            gift_card_value: formatGAPriceWithoutCurrency(order.giftCardValueAsCurrency),
            currency: order.currency,
            shipping: formatGAPriceWithoutCurrency(order.totalShippingCostAsCurrency),
            client_auth: order.customerId,
            items: order.lineItems.map((lineItem) => {
              return {
                item_id: lineItem.purchasableId,
                item_name: lineItem.description,
                item_category: lineItem.itemCategory,
                item_brand: lineItem.itemBrand,
                price: formatGAPriceWithoutCurrency(lineItem.salePriceAsCurrency),
                quantity: lineItem.qty
              }
            })
          })
        })
        .catch(err => {
          console.log('error', err); 
        })
      }
    }
    if (this.cancelled) {
      store.commit('setCheckoutStep', 2)
    }
  },
  data() {
    return {
      steps: [
        'ttl-checkout-step-informations-shipping',
        'ttl-checkout-step-payment',
        'ttl-checkout-step-confirm'
      ]
    }
  },
  methods: {
    setCheckoutStep(index) {
      store.commit('setCheckoutStep', index)
    }
  }
}
</script>

<style lang="postcss" scoped>
.checkout {
}

.steps {
  @apply uppercase text-base;

  @screen lg {
    @apply flex items-center ml-auto uppercase text-base;
  }
}

.steps__step {
  @apply flex-shrink-0 text-grey-300 flex items-center mt-3;

  @screen lg {
    @apply mt-0;
  }

  &.active {
    @apply text-grey-700;
  }

  .uil {
    @apply mx-4 w-4;
  }

  &:after {
    @apply bg-grey-300 mx-8 w-8 hidden;
    content: ' ';
    height: 1px;
  }

  &:last-child:after {
    @apply hidden;
  }
}

.steps__step__inner {
  @apply flex-shrink-0;
}
</style>
