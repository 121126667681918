<template>
  <svg
    class="animate-spin fill-current"
    :class="`${big ? 'h-8 w-8' : 'h-5 w-5'}`"
    viewBox="0 0 26 26"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M13,0 L13,2 C6.92486775,2 2,6.92486775 2,13 C2,19.0751322 6.92486775,24 13,24 C18.9787016,24 23.8433652,19.2302364 23.9962926,13.2884075 L24,13 L26,13 C26,20.1797017 20.1797017,26 13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.92286542 5.65519183,0.166571665 12.6931438,0.00355044239 L13,0 Z"
        id="Path"
        class="path"
        :class="{ white }"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    white: Boolean,
    big: Boolean
  }
}
</script>

<style lang="postcss" scoped>
path.white {
  fill: white;
}
path {
  fill: #707478;
}
</style>
