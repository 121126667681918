export function addToDataLayer(data) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
}

export function formatGAPrice(price){
    //replace colon and remove spaces
    return price?.replaceAll(',', '.')?.replaceAll(/\s+/g, '');
}

export function formatGAPriceWithoutCurrency(price){
    return formatGAPrice(price)?.replaceAll('€', '');
}