<template>
  <div>
    <customer-address-card
      :address="address"
      v-for="(address, index) in addressesOrderedByDate"
      class="mb-6"
      :key="index"
      selectable
      :primary="
        address.id === (customer ? customer.primaryShippingAddressId : null)
      "
    ></customer-address-card>
    <customer-new-address-button></customer-new-address-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CustomerAddressCard from '@/Customer/CustomerAddressCard'
import CustomerNewAddressButton from '@/Customer/CustomerNewAddressButton'

export default {
  computed: {
    addressesOrderedByDate() {
      return [...this.addresses].sort(
        (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
      )
    },
    ...mapState(['addresses', 'customer'])
  },
  components: {
    CustomerAddressCard,
    CustomerNewAddressButton
  }
}
</script>
