<template>
  <div class="container-swiper-slide" :class="site">
    <div class="slider swiper-container" :class="`swiper-container-${_uid}`">
      <div class="swiper-wrapper">
        <a
          :href="slide.url"
          class="swiper-slide"
          v-for="(slide, index) in slides"
          :key="index"
        >
          <div class="swiper-slide__image">
            <img :src="slide.image" alt="product image" class="slide__image" />
          </div>
          <div class="swiper-slide__content">
            <customtitle :level="2" :style-of="3">{{
              slide.title
            }}</customtitle>
            <div class="mt-2">
              <supplier-link :url="slide.supplierUrl">
                <i class="uil uil-corner-down-right-alt text-xl mr-2"></i>
                {{ slide.supplierTitle }}
              </supplier-link>
            </div>
            <p class="py-6 swiper-slide__p" v-html="slide.description"></p>
            <div class="flex swiper-slide__buttons">
              <price
                :amount="slide.variant.salePrice"
                variant="medium styled"
                class="justify-end mr-6 mb-3 lg:mb-0"
              ></price>
              <addToCartButton
                class="slider__button"
                :id="slide.variant.id"
                :disabled="!slide.variant.hasStock"
                :has-stock="slide.variant.hasStock"
                :age-notice="slide.ageNotice"
                :title="slide.title"
                :price="slide.variant.salePrice"
                :supplier-title="slide.supplierTitle"
                :category-title="slide.category"
              ></addToCartButton>
            </div>
          </div>
        </a>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <EcoshopBackgroundSlider
      class="swiper-slide-edenred-background"
      v-if="site === 'ecoshop'"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swiper, { Pagination, Autoplay, EffectFade } from 'swiper'
import Title from '@/components/Title'
import AddToCartButton from '@/components/AddToCartButton'
import Price from '@/components/Price'
import SupplierLink from '@/components/SupplierLink'
import EcoshopBackgroundSlider from '@/ecoshop/components/EcoshopBackgroundSlider'

Swiper.use([Pagination, Autoplay, EffectFade])

export default {
  components: {
    customtitle: Title,
    AddToCartButton,
    Price,
    SupplierLink,
    EcoshopBackgroundSlider
  },
  props: {
    slides: {
      type: Array,
      default: () => {
        return {}
      }
    },
    background: {
      type: Boolean,
      default: false
    }
  },
  computed: mapState(['cart', 'auth', 'currentColor', 'site']),
  mounted() {
    const swiper = new Swiper(`.swiper-container-${this._uid}`, {
      loop: true,
      speed: 400,
      effect: 'fade',
      init: false,
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 7000
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })

    swiper.on('init', e => {
      if (this.site === 'ecomarket') {
        this.changeBulletsColor(e)
      }
    })

    swiper.init()
  }
}
</script>

<style lang="postcss">
.container-swiper-slide {
  display: flex;
}
.slider {
  &.swiper-container {
    overflow: visible;
    @apply w-full rounded-md;
    @screen lg {
      @apply relative;
      height: 27em;
    }
  }

  .swiper-slide {
    @appy inline-block;
    @screen md {
      @apply flex items-center;
    }
  }

  .swiper-slide__image {
    @apply w-full;
  }
  .slide__image {
    img {
      @apply w-full;
    }
    @screen lg {
      @apply h-52 w-full h-auto mr-6;
      img {
        @apply hidden;
      }
    }
  }
  .swiper-slide__content {
    @apply flex flex-col pt-6;
    @screen lg {
      @apply w-1/2 items-start justify-center;
      padding-top: 0;
      margin-left: 60px;
    }
  }
  .swiper-slide__p {
    @screen lg {
      @apply block;
    }
  }
  .swiper-slide__buttons {
    @apply flex flex-col mt-3;

    @screen lg {
      @apply flex-row items-end w-full;
    }
  }
  .slider__button .custom-tooltip {
    @apply text-center;
    width: 100%;
  }
  .swiper-pagination {
    @apply text-left mx-6 relative;
    margin-bottom: -15px;
    z-index: 1;
    @screen lg {
      @apply absolute;
    }
  }

  .swiper-pagination-bullets .swiper-pagination-bullet {
    @apply transition-all duration-200 ease-in-out rounded-md bg-gray-400 opacity-100;
    width: 7px;
    height: 7px;
  }

  .swiper-pagination-bullets .swiper-pagination-bullet {
    @apply mx-2 !important;
  }

  &.swiper-container .swiper-pagination.swiper-pagination-bullets {
    bottom: 25px;
    @screen lg {
      @apply bottom-0;
    }
  }

  .swiper-pagination-bullets .swiper-pagination-bullet-active {
    @apply w-20;
  }
}

.ecomarket .slider {
  @apply bg-white p-12;
}

.ecomarket .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply bg-ecomarket-orange-300;
}

.ecoshop .swiper-pagination-bullets .swiper-pagination-bullet {
  @apply bg-red-ecoshop-400;
}

.ecomarket .slider {
  .swiper-slide__image {
    @screen lg {
      margin-left: 0;
      @apply w-2/5;
    }
  }
  .slide__image {
    @apply transform scale-125;
    width: 100%;
    @screen xs {
      @apply transform scale-100;
    }
  }
  .swiper-slide__content {
    @screen md {
      @apply pt-0;
      margin-left: 40px;
    }
  }
  .swiper-pagination {
    @apply mt-6;
  }
  &.swiper-container .swiper-pagination.swiper-pagination-bullets {
    @apply ml-0;

    @screen md {
      @apply m-0;
    }
    @screen lg {
      @apply m-0  ml-10 bottom-2;
    }
  }
}
.container-swiper-slide.ecoshop {
  position: relative;
}

.ecoshop .slider {
  .swiper-slide__image {
    @screen lg {
      margin-left: 0;
      @apply w-1/2;
    }
  }
  .slide__image {
    @apply transform scale-125;
    clip-path: inset(5% 0 5% 0 round 99999px);
    width: 100%;
    margin-top: -22px;
    @screen xs {
      @apply transform scale-100;
    }
  }
  .swiper-slide__content {
    @screen md {
      margin-left: 40px;
    }
  }
  .swiper-pagination {
    @apply mt-6;
  }

  .swiper-container .swiper-pagination.swiper-pagination-bullets {
    @apply m-6 ml-0;

    @screen md {
      @apply m-0;
    }
  }
}

.ecoshop .swiper-slide-edenred-background {
  position: absolute;
  margin-left: -100px;
  top: 0;
  @screen md {
    margin-left: 50%;
    z-index: -1;
  }
  @screen lg {
    top: -10em;
    margin-left: 351px;
  }
}
</style>
