<template>
  <div class="uppercase flex flex-col">
    <a
      :href="$t('route-customer-orders')"
      class="link"
      :class="`${current === 'orders' ? 'active' : ''}`"
    >
      {{ $t('menu-customer-orders') }}
    </a>
    <a
      :href="$t('route-customer-addresses')"
      class="link"
      :class="`${current === 'addresses' ? 'active' : ''}`"
    >
      {{ $t('menu-customer-addresses') }}
    </a>
    <a
      :href="$t('route-customer-infos')"
      class="link"
      :class="`${current === 'infos' ? 'active' : ''}`"
    >
      {{ $t('menu-customer-infos') }}
    </a>
  </div>
</template>

<script>
export default {
  props: ['current']
}
</script>

<style lang="postcss" scoped>
.link {
  @apply pb-6 pr-6 transition duration-100 ease-in-out;

  &:hover,
  &.active {
    @apply text-ecomarket-blue-300;
  }
}
</style>
