<script>
import Custombutton from '@/components/Button'
import Cookie from 'js-cookie'

export default {
  components: {
    Custombutton
  },
  props: {
    image: {
      type: String
    },
    cookie: {
      type: String
    },
    cookieExpiration: {
      type: Number
    },
    small: {
      type: Boolean,
      default: true
    },
    delay: {
      type: Number
    }
  },
  data() {
    return {
      display: false
    }
  },
  beforeMount() {
    if (this.$props.cookie && Cookie.get(this.$props.cookie)) {
      this.display = false
    } else if (this.$props.delay) {
      setTimeout(() => {
        this.displayPopup()
      }, this.$props.delay)
    } else {
      this.display = true
    }
  },
  methods: {
    close() {
      this.display = false
      if (this.$props.cookie) {
        Cookie.set(this.$props.cookie, true, {
          expires: this.$props.cookieExpiration
        })
      }
    },
    displayPopup() {
      this.display = true
    }
  }
}
</script>

<template>
  <transition name="fade" appear>
    <section class="popup" :class="{ small }" v-if="display">
      <div class="absolute right-0 top-1 text-xl z-10">
        <custombutton
          class="absolute text-2xl md:relative md:text-md"
          variant="transparent dark"
          icon="multiply"
          hideText
          @click="close"
        >
          <template #text>
            {{ $t('btn-close') }}
          </template>
        </custombutton>
      </div>
      <div class="inner">
        <div class="image hidden md:block" v-if="image">
          <img :src="image" alt="" />
        </div>
        <div class="content">
          <slot name="content"></slot>
        </div>
      </div>
    </section>
  </transition>
</template>

<style lang="postcss" scoped>
.popup {
  @apply rounded-md;
  position: fixed;
  z-index: 99;

  background: white;

  @screen md {
    background: transparent;
  }
}

.popup .inner {
  @apply md:flex;
}

.small {
  @apply left-6 bottom-6 mr-6;
}

.image {
  @apply mr-6 rounded-full w-20 h-20 mb-6 md:mb-0;
  overflow: hidden;
}

.content {
  @apply p-6 rounded-md max-w-lg shadow-thumbnail relative;
  background: white;
}
</style>
