import Vue from 'vue'
import Vuex from 'vuex'
import tinycolor from 'tinycolor2'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    isSideBasketActive: false,
    blockScroll: false,
    cart: {},
    tokenInput: '',
    tokenName: '',
    token: '',
    checkoutStep: 1,
    showLoginPopup: false,
    loginPopupType: 'login',
    loginStep: 'choose',
    user: {},
    countries: [],
    addresses: [],
    primaryShippingAddress: {},
    primaryBillingAddress: {},
    customer: null,
    suppliers: null,
    categories: null,
    themes: null,
    worths: null,
    currentColor: null,
    locale: 'fr',
    site: '',
    url: '',
    cssVariables: {
      '--color': '#AAD6B2',
      '--color-dark': '#66A4A3'
    },
    cookieConsent: null,
    edenredSsoLoginUrl: ''
  },
  mutations: {
    openSideBasket(state) {
      state.isSideBasketActive = true
    },
    closeSideBasket(state) {
      state.isSideBasketActive = false
    },
    setScroll(state, value) {
      state.blockScroll = value
    },
    updateCart(state, cart) {
      state.cart = cart
    },
    setTokenInput(state, input) {
      state.tokenInput = input
    },
    setTokenName(state, name) {
      state.tokenName = name
    },
    setToken(state, token) {
      state.token = token
    },
    setCheckoutStep(state, step) {
      state.checkoutStep = step
    },
    showLoginPopup(state, type = 'login') {
      state.showLoginPopup = true
      state.loginPopupType = type
      state.loginStep = 'choose';
    },
    changeLoginStep(state, step = 'choose') {
      state.loginStep = step;
    },
    hideLoginPopup(state) {
      state.showLoginPopup = false
    },
    setUser(state, user) {
      state.user = user
    },
    setCountries(state, countries) {
      state.countries = countries
    },
    setAddresses(state, addresses) {
      state.addresses = addresses
    },
    setPrimaryShippingAddress(state, addresse) {
      state.primaryShippingAddress = addresse
    },
    setPrimaryBillingAddress(state, addresse) {
      state.primaryBillingAddress = addresse
    },
    setCustomer(state, customer) {
      state.customer = customer
    },
    setSuppliers(state, suppliers) {
      state.suppliers = suppliers
    },
    setThemes(state, themes) {
      state.themes = themes
    },
    setCategories(state, categories) {
      state.categories = categories
    },
    setWorths(state, worths) {
      state.worths = worths
    },
    setLocale(state, locale) {
      state.locale = locale
    },
    setCurrentSite(state, site) {
      state.site = site
    },
    setBaseUrl(state, url) {
      state.url = url
    },
    setColor(state, string) {
      const color = tinycolor(`#${string}`)
      state.currentColor = color.toString()
      state.cssVariables = {
        '--color': state.currentColor,
        '--color-dark': color.darken(5).toString()
      }
    },
    setCookieConsent(state, cookieConsent){
      state.cookieConsent = cookieConsent;
    },
    setEdenredSsoLoginUrl(state, url) {
      state.edenredSsoLoginUrl = url
    },
  },
  actions: {},
  modules: {}
})
