<template>
  <header :class="site">
    <slot></slot>
  </header>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      menuOppened: false,
      subOpened: '',
      languages: {},
      mainHeaderMobileButton: null,
      mainHeaderMobileMenu: null,
      mainHeaderMobileMenuTop: null,
      sideBasketButton: null,
      categories: []
    }
  },
  props: {
    logoutUrl: String,
    craftLanguages: [Object, Array]
  },
  computed: mapState(['isSideBasketActive', 'user', 'locale', 'site']),
  watch: {
    isSideBasketActive: function(val) {
      store.commit('setScroll', val)
    },
    menuOppened: function(val) {
      if (val) {
        this.mainHeaderMobileButton.classList.add('opened')
        this.mainHeaderMobileMenu.classList.add('opened')
        this.mainHeaderMobileMenuTop.classList.add('opened')
      } else {
        this.mainHeaderMobileButton.classList.remove('opened')
        this.mainHeaderMobileMenu.classList.remove('opened')
        this.mainHeaderMobileMenuTop.classList.remove('opened')
      }
    }
  },
  mounted() {
    this.mainHeaderMobileButton = this.$el.querySelector(
      '#mainHeaderMobileButton'
    )
    this.mainHeaderMobileMenu = this.$el.querySelector('#mainHeaderMobileMenu')

    this.sideBasketButton = this.$el.querySelector(
      '.main-header-mobile__side-basket-button'
    )

    this.sideBasketButton.addEventListener('click', () => {
      this.activateSideBasket()
    })

    this.mainHeaderMobileMenuTop = this.$el.querySelector(
      '.main-header-mobile-top'
    )

    this.$el
      .querySelectorAll('.main-header-mobile__category')
      .forEach((el, i) => {
        el.querySelector(
          '.main-header-mobile__category__item'
        ).addEventListener('click', e => {
          e.preventDefault()
          this.toggleCategoryMenu(i)
        })
        this.categories.push({
          icon: el.querySelector('.main-header-mobile__level-1__icon'),
          frame: el.querySelector('.main-header-mobile__level-2'),
          opened: false,
          childs: Array.from(
            el.querySelectorAll('.main-header-mobile__level-2__item')
          ).map((el2, i2) => {
            el2
              .querySelector('.main-header-mobile__level-2__item__button')
              .addEventListener('click', e => {
                e.preventDefault()
                this.toggleCategoryMenu(i, i2)
              })
            return {
              icon: el.querySelector('.main-header-mobile__level-2__icon'),
              frame: el2.querySelector('.main-header-mobile__level-3'),
              opened: false
            }
          })
        })
      })

    this.mainHeaderMobileButton.addEventListener('click', () => {
      this.toggleMenu()
    })
    if (typeof this.craftLanguages !== 'object') {
      this.languages = {
        fr: {
          id: 'fr',
          name: 'français',
          nativeName: 'français',
          url: 'https://ecomarket.be/fr/?lang=fr'
        },
        nl: {
          id: 'nl',
          name: 'Nederlands',
          nativeName: 'Nederlands',
          url: 'https://ecomarket.be/nl/?lang=nl'
        }
      }
    } else {
      this.languages = this.craftLanguages
    }
    delete this.languages[this.locale]
  },
  methods: {
    toggleMenu() {
      this.menuOppened = !this.menuOppened
      store.commit('setScroll', this.menuOppened)
    },
    toggleCategoryMenu(i, i2) {
      if (i2 !== undefined) {
        if (this.categories[i].childs[i2].opened) {
          this.categories[i].childs[i2].opened = false
          this.categories[i].childs[i2].icon.classList.add('uil-plus')
          this.categories[i].childs[i2].icon.classList.remove('uil-minus')
          this.categories[i].childs[i2].frame.classList.remove('opened')
        } else {
          this.categories[i].childs[i2].opened = true
          this.categories[i].childs[i2].icon.classList.remove('uil-plus')
          this.categories[i].childs[i2].icon.classList.add('uil-minus')
          this.categories[i].childs[i2].frame.classList.add('opened')
        }
      } else {
        if (this.categories[i].opened) {
          this.categories[i].opened = false
          this.categories[i].icon.classList.add('uil-plus')
          this.categories[i].icon.classList.remove('uil-minus')
          this.categories[i].frame.classList.remove('opened')
        } else {
          this.categories[i].opened = true
          this.categories[i].icon.classList.remove('uil-plus')
          this.categories[i].icon.classList.add('uil-minus')
          this.categories[i].frame.classList.add('opened')
        }
      }
    },
    activateSideBasket() {
      store.commit('openSideBasket')
    },
    login() {
      store.commit('showLoginPopup')
    },
    register() {
      store.commit('showLoginPopup', 'register')
    },
    openSubmenu(slug) {
      if (this.subOpened !== slug) {
        this.subOpened = slug
      } else {
        this.subOpened = ''
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
header {
  position: relative;
  z-index: 20;
  @screen menu {
    @apply hidden;
  }
}

.header-sticky {
  width: 100%;
  position: fixed;

  &:before {
    @apply block absolute top-0 left-0 bottom-0 right-0;
    z-index: -1;
    content: '';
    background-size: 150px;
  }
}

.main-header-mobile-top {
  @apply flex px-6 items-center h-24 relative z-40;

  &.opened {
    @apply bg-white;
  }
}

.menu-button {
  @apply relative w-7 mr-4 box-border h-4 z-40;
  text-indent: -999em;

  &:before,
  &:after {
    @apply bg-grey-700 block absolute left-0 right-0 origin-center transition duration-100 ease-in-out;
    height: 3px;
    content: ' ';
  }

  &:focus {
    @apply outline-none;
  }

  &:before {
    @apply top-0;
  }

  &:after {
    @apply bottom-0;
  }

  &.opened {
    @apply w-6 mr-5;

    &:before,
    &:after {
      @apply transform;
    }

    &:before {
      @apply rotate-45;
      top: calc(50% - 2px);
    }

    &:after {
      @apply -rotate-45 -translate-y-1/2;
      bottom: calc(50% - 1.5px);
    }
  }
}

.main-header-mobile__quick-menu {
  @apply flex flex-auto justify-end text-2xl z-40;

  li {
    @apply inline-block flex items-center;
  }

  span {
    @apply hidden;
  }
}

.main-header-mobile__completemenu {
  @apply opacity-0 pointer-events-none fixed inset-0 box-border overflow-scroll z-30 transition duration-200 ease-in-out text-lg;

  &.opened {
    @apply opacity-100 pointer-events-auto;
  }
}

.main-header-mobile__categories {
  @apply pb-6 bg-white;

  a {
    @apply text-xl;
  }
}

.main-header-mobile__category__item,
.main-header-mobile__level-2__item__button,
.main-header-mobile__level-3__item__button {
  @apply py-3;
}

.main-header-mobile__level-2,
.main-header-mobile__level-3 {
  @apply pl-8;
  height: 0;
  overflow: hidden;

  &.opened {
    height: auto;
  }
}

.main-header-mobile__suppliers {
  @apply px-6 bg-white;

  a {
    @apply py-8 border-t-2 block;
    @apply text-xl;
  }
}

.main-header-mobile__search,
.main-header-mobile__links {
  @apply px-6;
  background-size: 150px;
  position: relative;
}

.main-header-mobile__search {
  i {
    @apply mr-6;
  }
  @apply flex items-center py-5 text-xl;
}

.main-header-mobile__links {
  &:before {
    @apply bg-transparentBackground inset-0 opacity-10 pointer-events-none;
    display: block;
    position: absolute;
    content: ' ';
  }

  @apply py-8;

  div {
    @apply relative;
  }

  a {
    @apply py-4 block uppercase;
  }
}
.ecoshop .header-sticky {
  &:before {
    background: white;
  }
}
.ecoshop .main-header-mobile__search,
.main-header-mobile__links {
  background: white;
}

.ecomarket .header-sticky {
  &:before {
    @apply bg-ecomarket-grey-100;
  }
}

.ecomarket .main-header-mobile__search,
.main-header-mobile__links {
  background: url('../assets/images/background.png') repeat;
}
</style>
