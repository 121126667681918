<template>
  <div class="tabs" :class="site">
    <div class="tabs__nav">
      <button
        v-for="(tab, i) in tabs"
        :key="i"
        class="tabs__nav__item"
        :class="`${activeTab === tab.name ? 'active' : ''}`"
        @click="() => click(tab.name)"
      >
        {{ $t(tab.name) }}
      </button>
    </div>
    <div class="tabs_item">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      tabs: [],
      activeTab: ''
    }
  },
  computed: mapState(['site']),
  mounted() {
    this.tabs = this.$children
    this.activeTab = this.tabs[0].name
  },
  methods: {
    click(name) {
      this.activeTab = name
    }
  }
}
</script>

<style lang="postcss" scoped>
.tabs {
  @apply flex-col rounded-md;
}

.tabs__nav {
  @apply inline-flex flex-wrap;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.tabs__nav__item {
  @apply text-center p-4 px-6 block;
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
  &:focus {
    @apply outline-none;
  }

  &.active {
    @apply rounded-t-md;
  }
}

.ecoshop {
  .tabs__nav {
    background-color: #d5d7dd;
  }
  .tabs__nav__item {
    background: #d5d7dd;

    &.active {
      @apply bg-white;
      border: 1px solid #d5d7dd;
      border-bottom: 1px solid transparent;
      z-index: 1;
    }
  }
  .tabs_item {
    border: 1px solid #d5d7dd;
    margin-top: -1px;
  }
}

.ecomarket {
  .tabs__nav {
    @apply bg-beige-base;
  }
  .tabs__nav__item {
    @apply bg-beige-base;
    &.active {
      @apply bg-white;
    }
  }
}
</style>
