<template>
  <div class="flex">
    <breadcrumbs-item link="/">
      {{ $t('ttl-home') }}
    </breadcrumbs-item>
    <slot />
  </div>
</template>

<script>
import BreadcrumbsItem from '@/components/BreadcrumbsItem'

export default {
  components: {
    BreadcrumbsItem
  }
}
</script>
