import { render, staticRenderFns } from "./CategoryDot.vue?vue&type=template&id=aff3171e&scoped=true&"
import script from "./CategoryDot.vue?vue&type=script&lang=js&"
export * from "./CategoryDot.vue?vue&type=script&lang=js&"
import style0 from "./CategoryDot.vue?vue&type=style&index=0&id=aff3171e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aff3171e",
  null
  
)

export default component.exports