<template>
  <form method="post" @submit.prevent="removeFromCart" ref="form">
    <input type="hidden" name="action" value="commerce/cart/update-cart" />
    <span v-html="tokenInput"></span>
    <input type="hidden" :name="`lineItems[${id}][remove]`" value="1" />
    <input type="hidden" name="shippingMethodHandle" value="1" />
    <custombutton
      icon="trash-alt"
      type="submit"
      variant="no-background no-padding gray-300 no-height"
      class="text-lg"
      iconOnly
    ></custombutton>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import store from '@/store'
import Button from '@/components/Button.vue'
import { addToDataLayer, formatGAPrice } from '@/utils'

export default {
  components: {
    custombutton: Button
  },
  props: {
    id: Number,
    quantity: Number,
    price: String,
    supplierTitle: String,
    categoryTitle: String,
    title: String,
    purchasableId: Number,
  },
  computed: mapState(['tokenInput', 'url']),
  methods: {
    removeFromCart() {
      const data = new FormData(this.$refs.form)
      axios
        .post(`${this.url}/`, data, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
        .then(res => {
          addToDataLayer({
            event: 'remove_from_cart',
            item_id: this.purchasableId,
            item_name: this.title,
            item_brand: this.supplierTitle,
            item_category: this.categoryTitle,
            quantity: this.quantity,
            price: formatGAPrice(this.price)
          })
          store.commit('updateCart', res.data.cart)
        })
        .catch(() => {})
        .finally(() => {})
    }
  }
}
</script>
