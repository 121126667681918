<template><div></div></template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    if (this.data) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push(this.data)
    }
  }
}
</script>
