<template>
  <li :class="site">
    <div class="product-card">
      <a :href="url" class="product-card__links">
        <div class="product-card-container__img">
          <image-lazyload
            :urlLazyload="imageurl"
            alt="Thumbnail's title"
            :classImgLazyload="`w-full h-auto lazyload_img`"
            :absolute-fullwidth="
              site === `ecoshop`
                ? `position: absolute; top: 0; height: 100%; width: auto; left: 50%; transform: translateX(-50%); max-width: none;`
                : ''
            "
          >
          </image-lazyload>
        </div>
        <div class="product-card__header">
          <div class="product-card__title">
            <div>{{ title }}</div>
            <supplier-link class="product-card__supplier" :url="supplierUrl">{{
              supplierTitle
            }}</supplier-link>
          </div>
          <div class="product-card__add">
            <addToCartButton
              :id="id"
              compact
              class="ml-2"
              :price="price"
              displayPrice
              hideIcon
              :hasStock="hasStock"
              :outline="site === 'ecoshop'"
              :age-notice="ageNotice"
              :title="title"
              :supplier-title="supplierTitle"
              :category-title="categoryTitle"
            ></addToCartButton>
          </div>
        </div>
      </a>
    </div>
  </li>
</template>

<script>
import { mapState } from 'vuex'
import AddToCartButton from '@/components/AddToCartButton'
import SupplierLink from '@/components/SupplierLink'
import ImageLazyload from '@/components/ImageLazyload'

export default {
  props: {
    title: String,
    imageurl: String,
    price: String,
    url: String,
    id: [String, Number],
    description: String,
    supplierTitle: String,
    supplierUrl: String,
    categoryTitle: String,
    color: String,
    hasStock: {
      type: Boolean,
      default: false
    },
    ageNotice: {
      type: Boolean,
      default: false
    }
  },
  computed: mapState(['site']),
  components: {
    AddToCartButton,
    SupplierLink,
    ImageLazyload
  }
}
</script>

<style lang="postcss" scoped>
.product-card {
  @apply block relative rounded-md transition duration-300;

  &:before {
    content: '';
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
  }
}

.product-card__links {
  @apply absolute inset-0 flex flex-col h-full;
}
.product-card-container__img {
  @apply h-full flex-shrink justify-center items-center min-h-0 overflow-hidden;
}
.product-card__header {
  @apply h-16 w-full flex justify-between items-end mt-auto;
}

.product-card__title {
  @apply block font-semibold text-sm pl-3 overflow-hidden;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;

  > div {
    @apply w-full overflow-hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.product-card__add {
  @apply static;
}

.ecomarket {
  .product-card {
    @apply bg-white overflow-hidden;
  }
  .product-card-container__img {
    @apply h-full;
  }
}

.ecoshop {
  .product-card {
    @apply h-full;
  }
  .product-card-container__img {
    @apply border border-solid border-grey-200 rounded-xxl relative;
    width: 100%;
  }
  .product-card__supplier {
    color: #7f8695;
    &:hover {
      color: #7f8695;
    }
  }
  .product-card__header {
    @apply mt-0;
  }
}
</style>
