<template>
  <div class="min-h-screen flex flex-col" :style="{ color }">
    <slot></slot>
  </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
import { fetchTokenInput } from '@/api'
import { fetchCustomer } from '@/api'
import { localize } from 'vee-validate'
import * as CookieConsent from "vanilla-cookieconsent";
import { getCookieConsentConfig } from '@/config/cookie-consent'
import { addToDataLayer } from '@/utils'

export default {
  computed: mapState(['blockScroll', 'cssVariables', 'cookieConsent']),
  data() {
    return {
      body: null
    }
  },
  mounted() {
    this.body = document.querySelector('body')
  },
  watch: {
    blockScroll: function(val) {
      if (val) {
        this.body.classList.add('overflow-hidden')
      } else {
        this.body.classList.remove('overflow-hidden')
      }
    },
    cssVariables: function(val) {
      this.setDocumentVariable(val)
    }
  },
  props: [
    'tokenName',
    'token',
    'user',
    'countries',
    'addresses',
    'color',
    'locale',
    'site',
    'url',
    'edenredSsoLoginUrl'
  ],
  created: function() {
    fetchTokenInput()
    fetchCustomer()
    store.commit('setTokenName', this.tokenName)
    store.commit('setToken', this.token)
    store.commit('setUser', this.user)
    store.commit('setCountries', this.countries)
    store.commit('setAddresses', this.addresses)
    store.commit('setCurrentSite', this.site)
    store.commit('setBaseUrl', this.url)
    store.commit('setEdenredSsoLoginUrl', this.edenredSsoLoginUrl)
    if (this.color.length === 6) {
      store.commit('setColor', this.color)
    } else {
      this.setDocumentVariable(this.cssVariables)
    }
    store.commit('setLocale', this.locale)
    this.$i18n.locale = this.locale
    localize(this.locale)
    CookieConsent.run(this.getCookieConsentConfig()).then(() => {
      if(!CookieConsent.validConsent() || (CookieConsent.validConsent() && !CookieConsent.acceptedCategory('analytics'))){
        // if user has not yet made a choice or revision numbers don't match or analytics category is not accepted
        // remove analytics cookies already set before
        CookieConsent.eraseCookies(['_gid', /^_ga/, /^_gcl/], '/', location.hostname);
      }
    })
    store.commit('setCookieConsent', CookieConsent)
  },
  methods: {
    setDocumentVariable(val) {
      document.documentElement.style.setProperty('--color', val['--color'])
      document.documentElement.style.setProperty(
        '--color-dark',
        val['--color-dark']
      )
    },
    mailchimpSubscribeSuccess() {
      this.$refs.popup.close()
    },
    getCookieConsentConfig,
    addToDataLayer
  }
}
</script>

<style lang="postcss" scoped>
.blockScroll {
  @apply overflow-hidden;
}
</style>
