<script>
export default {
  created() {
    const escapeHandler = e => {
      if (e.keyCode === 27 && this.escapableVisible) {
        this.escapeHandler()
      }
    }

    document.addEventListener('keydown', escapeHandler)

    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  }
}
</script>
