<template>
  <footer class="main-footer" :class="site">
    <slot />
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState(['site'])
}
</script>

<style lang="postcss">
.main-footer {
  @apply mt-auto pt-24  gap-x-6 text-center;

  @screen md {
    @apply text-left;
  }
}

.main-footer.ecomarket {
  @apply border-t-2 border-solid border-dashed mt-24 border-gray-600;
}

.main-footer.ecoshop {
  @apply pt-48;
}

.main-footer-inner {
  @apply relative w-full pb-20 inline-flex flex-col items-start px-6;

  @screen xl {
    @apply px-0;
  }
}

.main-footer-inner:before {
  @apply absolute bottom-0 top-0 right-0 left-0 block rounded-tr-lg;
  content: ' ';
  z-index: -1;
  opacity: 0.15;
}

.ecoshop .main-footer-inner:before {
  @apply bg-grey-300;
}
.ecoshop .column {
  a {
    &:hover {
      @apply text-grey-500;
    }
  }
}
.ecoshop .main-footer-logo {
  @apply w-24 mx-auto;

  @screen md {
    @apply ml-0;
  }
}

.ecomarket .main-footer-logo {
  @apply -mt-6;
}
.ecomarket .column {
  a {
    &:hover {
      @apply text-ecomarket-blue-300;
    }
  }
}
.content_social_media {
  margin-top: 24px;
  font-size: 40px;
}
.column {
  .title {
    @apply mb-6;
  }

  li {
    @apply mb-3;
  }
}
</style>
