<template>
  <div>
    <a :href="worth.url" v-for="(worth, index) of worths" :key="index">
      <worth-pill :name="worth.title"></worth-pill>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { fetchWorths } from '@/api'
import WorthPill from '@/components/WorthPill'

export default {
  components: {
    WorthPill
  },
  computed: mapState(['worths']),
  beforeCreate() {
    if (!this.worths) {
      fetchWorths()
    }
  }
}
</script>
