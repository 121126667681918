<template>
  <form method="post" ref="form" class="cart-item-quantity">
    <input type="hidden" name="action" value="commerce/cart/update-cart" />
    <span v-html="tokenInput"></span>
    <input type="hidden" :name="`lineItems[${id}][qty]`" ref="input" value="" />
    <input type="hidden" name="shippingMethodHandle" value="1" />
    <number-input
      v-model="quantity"
      :min="1"
      :max="max"
      inline
      @change="updateCart"
      controls
      size="small"
      class="cart-item-quantity-component"
    ></number-input>
  </form>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

import VueNumberInput from '@chenfengyuan/vue-number-input'
import { addToDataLayer, formatGAPrice, formatGAPriceWithoutCurrency } from '@/utils'

export default {
  components: {
    'number-input': VueNumberInput
  },
  props: {
    id: Number,
    originalQuantity: Number,
    max: {
      type: Number
    },
    price: String,
    supplierTitle: String,
    categoryTitle: String,
    title: String,
    purchasableId: Number,
  },
  data() {
    return {
      quantity: 1,
      mounted: false
    }
  },
  mounted() {
    this.mounted = true
  },
  created() {
    this.quantity = this.originalQuantity
  },
  computed: mapState(['tokenInput']),
  methods: {
    updateCart(newValue) {
      if (!this.mounted) return
      this.$refs.input.value = newValue

      const data = new FormData(this.$refs.form)
      fetch('/', {
        method: 'POST',
        headers: {
          Accept: 'application/json'
        },
        body: data
      })
        .then(response => response.json())
        .then(res => {
          const lineItem = res.cart.lineItems.find((item) => item.id == this.id);
          if(lineItem) {
            const addedQty = lineItem.qty - this.originalQuantity;
            if(addedQty > 0){
              addToDataLayer({
                event: 'add_to_cart',
                value: (Number(formatGAPriceWithoutCurrency(this.price)) * addedQty).toFixed(2),
                currency: 'EUR',
                items: [
                  {
                    item_id: this.purchasableId,
                    item_name: this.title,
                    item_brand: this.supplierTitle,
                    item_category: this.categoryTitle,
                    quantity: addedQty,
                    price: formatGAPriceWithoutCurrency(this.price)
                  }
                ]            
              })
            } else if(addedQty < 0){
              const removedQty = -addedQty;
              addToDataLayer({
                event: 'remove_from_cart',
                item_id: this.purchasableId,
                item_name: this.title,
                item_brand: this.supplierTitle,
                item_category: this.categoryTitle,
                quantity: removedQty,
                price: formatGAPrice(this.price)
              })
            }
          }

          store.commit('updateCart', res.cart)
          if (!res.success) {
            res.errors.forEach(error => {
              this.$toast.error(error[0])
            })
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="postcss">
.cart-item-quantity {
  @apply w-24 flex;

  input.number-input__input:focus {
    @apply border-blue-base;
  }

  button.number-input__button:after,
  button.number-input__button:before {
    &:hover {
      @apply border-blue-base;
    }
  }
}
</style>
